import React, { useEffect, useState } from "react";
import "../assets/css/dashboard-style.css"

import Topnav from "../Dashboard-pages/Separate/Top-nav";
import Sidenav from "../Dashboard-pages/Separate/Side-nav";

import unknownuser from "../assets/images/UnknownUser.webp";
import bnb from "../assets/images/bnb.png";
import Platinum from "../assets/images/ranking1/Platinum.png";
import diamond from "../assets/images/ranking1/diamond.png";
import bronze from "../assets/images/ranking1/bronze.png";
import silver from "../assets/images/ranking1/silver.png";
import slide from "../assets/images/ranking1/slide.png";
import gold from "../assets/images/ranking1/gold.webp";

import Web3 from "web3";
import xcoreABI from "../ABI/xcore.json";
import { Navigate, useLocation } from "react-router-dom";
import { getLevelIncomeUsers } from "./api/authapis";
import moment from 'moment';
import config from "../config/apiConfig";


function Rankincome() {

    const USERID = localStorage.getItem("userID");
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userid = queryParams.get('user');

    const [UserRefData,setUserRefData] = useState([]);
    const [Addr,setAddr] = useState('');
    const [Rank,setRank] = useState('');
    const [BronzeLogo,setBronzeLogo] = useState('');
    const [RankStatus,setRankStatus] = useState('FALSE');
    const [BronzeStatus,setBronzeStatus] = useState('FALSE');

    const getrankdatacontract = async(userID) => {
        if(window.ethereum.chainId == config.chainId) {
            let web3 = new Web3(window.ethereum);
            let xcorecontract = await new web3.eth.Contract(
                xcoreABI,
                config.xcorecontract
            );
            let usergetaddr = await xcorecontract.methods.idToAddress(userID).call();
            let getrankaddr = await xcorecontract.methods.getBronzerank(usergetaddr).call();   
                
            if(getrankaddr.length >= 2) {
                setRank("BRONZE")
                setBronzeLogo(bronze)
                setRankStatus("TRUE");
                setBronzeStatus("TRUE")
                let getsilverrankaddr = await xcorecontract.methods.getsilverrank(usergetaddr).call();
                if(getsilverrankaddr.length >= 2) {
                    setRank("SILVER")
                    setBronzeLogo(silver)
                    let getgoldrankaddr = await xcorecontract.methods.goldrank(usergetaddr).call();
                    if(getgoldrankaddr.length >=2) {
                        setRank("GOLD")
                        setBronzeLogo(gold)
                        let getplatinumRank = await xcorecontract.methods.platinumRank(usergetaddr).call();
                        if(getplatinumRank.length >=2) {
                            setRank("PLATINUM")
                            setBronzeLogo(Platinum)
                            let getdiamondRank = await xcorecontract.methods.diamondRank(usergetaddr).call();
                            if(getdiamondRank.length >= 2) {
                                setRank("DIAMOND")
                                setBronzeLogo(diamond)
                            }
                        }
                    }
                }
            }
            setUserRefData(usergetaddr);
        }
    }

    const getUserRefData = async (userID) => {  
        let refUsers = await getLevelIncomeUsers(userID);
        console.log("refUsers--",refUsers)
        if (refUsers.status) {
          setUserRefData(refUsers.data[0]);
          let datas = refUsers.data[0];
          console.log("",datas.address)
          let Addr = datas.address.substring(0, 5) + '...' + datas.address.substring(datas.address.length - 4) 
          setAddr(Addr)
        }else{
          Navigate(`/login`);
        }
    };

    useEffect(() => {
        console.log("userid--",userid,USERID)
        if(userid > 0) {
            console.log("",userid)
          getUserRefData(userid)
          getrankdatacontract(userid);
        }else {
            getUserRefData(USERID)
            getrankdatacontract(USERID);
        }
    }, [USERID,userid]);

    return (
        <div className="App">
            <Topnav />
            <section className="dashboard-split-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 dashboard-split-left-section d-lg-block d-none">
                            <Sidenav />
                        </div>
                        <div className="col-lg-10 dashboard-split-right-section">
                            <div className="row p-2 g-4 d-flex justify-content-center align-items-center flex-column">
                                <div className="col-lg-6">
                                    <div className="d-flex flex-lg-row flex-column align-items-center">
                                        <div className="me-lg-4">
                                            <div className="d-flex flex-row align-items-end">
                                                <img className="dashboard-user-profile" src={unknownuser}
                                                    alt="User-profile" />
                                                <img className="dashboard-user-coin-logo" src={bnb}
                                                    alt="coin-logo" />
                                            </div>

                                        </div>
                                        <div>
                                            <p className="mb-0 dashboard-text-2">ID {UserRefData.userId}</p>
                                            <p className="mb-1 dashboard-text-3">{Addr}<button
                                                className="ms-2 bg-transparent border-0 text-white"><i
                                                    className="fa-solid fa-copy"></i></button></p>
                                            <span className="mb-0 dashboard-text-4">invited {moment.utc(UserRefData.date).format("YYYY-MM-DD")} by</span><button
                                                className="ms-3 dashboard-button-1">ID {UserRefData.parentId}</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 d-flex  whole-box-content my-3 justify-content-center align-items-center">


                            {RankStatus == "TRUE" ? (                                                    
                                    <div className="dollar-images d-flex flex-column justify-content-center align-items-center ">
                                        <div className="dollar-images-hold0">
                                            <img src={BronzeLogo} />
                                        </div>

                                        <div className="small-icons-images d-flex mt-3">
                                            <img src={Platinum} className="me-3" />
                                            <img src={diamond} className="me-3" />
                                            <img src={gold}  className="me-3"/>
                                            <img src={silver} className="me-3" />
                                            <img src={slide}/>
                                            
                                        </div>
                                        <div className="small-icons-images-heading mt-3">
                                            <p className="fs-1 fw-bold">{Rank}</p>
                                        </div>
                                    </div>
                            ):(
                                <span></span>
                            )
                            
                            }


                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Rankincome;