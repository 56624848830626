import "../assets/css/dashboard-style.css";

import Topnav from "../Dashboard-pages/Separate/Top-nav";
import Sidenav from "../Dashboard-pages/Separate/Side-nav";
import React, { useEffect, useState } from "react";
import {
  addAllLevelUser,
  userRefLevel,
  getUserDataAPI,
  getUserDataAPIUsingId,
  updateUserAmount,
} from "./api/authapis";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import imglogo from "../assets/images/busd.png";
import { AiOutlineClose } from "react-icons/ai";
import { GoDotFill } from "react-icons/go";
import { FiUsers } from "react-icons/fi";
import { HiArrowPath } from "react-icons/hi2";
import samplegif from "../assets/images/sample.gif";
import { totalTransactionAmt } from "./api/commonFunctions";
import { xcoreRequestCycle } from "../xcorecycle/xcore-inner-cycle";
import Web3 from "web3";
import xcoreABI from "../ABI/xcore.json";
import config from "../config/apiConfig";
import busdABI from "../ABI/busd.json";

import { ToastContainer, toast } from "react-toastify";
import { BsCloudLightning } from "react-icons/bs";
import { data } from "jquery";
// import { logSchema } from "web3/lib/commonjs/eth.exports";


function Metakingxcore() {

  const [primaryRefData, setPrimaryRefData] = useState([]);
  const [secondaryRefData, setSecondaryRefData] = useState([]);
  const [responseSendBackend, setResponseSendBackend] = useState(false)
  const [checkLength, setCheckLength] = useState(1)
  const [userShowDetails, setUserShowDetails] = useState([]);
  const [pageNumber, setPageNumber] = useState(5);
  const [xcoreTotalAmt, setXcoreTotalAmt] = useState(0);
  const [User1CyclesLength, setUser1CyclesLength] = useState("0");
  const [usr1partners, setusr1partners] = useState("0");
  const [usr2partners, setusr2partners] = useState("0");
  const [User2CyclesLength, setUser2CyclesLength] = useState("0");

  const [usr3partners, setusr3partners] = useState("0");
  const [User3CyclesLength, setUser3CyclesLength] = useState("0");

  const [usr4partners, setusr4partners] = useState("0");
  const [User4CyclesLength, setUser4CyclesLength] = useState("0");

  const [usr5partners, setusr5partners] = useState("0");
  const [User5CyclesLength, setUser5CyclesLength] = useState("0");

  const [usr6partners, setusr6partners] = useState("0");
  const [User6CyclesLength, setUser6CyclesLength] = useState("0");

  const [usr7partners, setusr7partners] = useState("0");
  const [User7CyclesLength, setUser7CyclesLength] = useState("0");

  const [usr8partners, setusr8partners] = useState("0");
  const [User8CyclesLength, setUser8CyclesLength] = useState("0");

  const [usr9partners, setusr9partners] = useState("0");
  const [User9CyclesLength, setUser9CyclesLength] = useState("0");

  const [usr10partners, setusr10partners] = useState("0");
  const [User10CyclesLength, setUser10CyclesLength] = useState("0");

  const [primaryRef1contractData, setprimaryRef1contractData] = useState([]);
  const [SecondaryRef1contractData, setSecondaryRef1contractData] = useState(
    []
  );
  const [primaryRef2contractData, setprimaryRef2contractData] = useState([]);
  const [SecondaryRef2contractData, setSecondaryRef2contractData] = useState(
    []
  );

  const [level2Status, setLevel2Status] = useState(false);

  const [primaryRef3contractData, setprimaryRef3contractData] = useState([]);
  const [SecondaryRef3contractData, setSecondaryRef3contractData] = useState(
    []
  );
  const [level3Status, setLevel3Status] = useState(false);

  const [primaryRef4contractData, setprimaryRef4contractData] = useState([]);
  const [SecondaryRef4contractData, setSecondaryRef4contractData] = useState(
    []
  );
  const [level4Status, setLevel4Status] = useState(false);

  const [primaryRef5contractData, setprimaryRef5contractData] = useState([]);
  const [SecondaryRef5contractData, setSecondaryRef5contractData] = useState(
    []
  );
  const [level5Status, setLevel5Status] = useState(false);

  const [primaryRef6contractData, setprimaryRef6contractData] = useState([]);
  const [SecondaryRef6contractData, setSecondaryRef6contractData] = useState(
    []
  );
  const [level6Status, setLevel6Status] = useState(false);

  const [primaryRef7contractData, setprimaryRef7contractData] = useState([]);
  const [SecondaryRef7contractData, setSecondaryRef7contractData] = useState(
    []
  );
  const [level7Status, setLevel7Status] = useState(false);

  const [primaryRef8contractData, setprimaryRef8contractData] = useState([]);
  const [SecondaryRef8contractData, setSecondaryRef8contractData] = useState(
    []
  );
  const [level8Status, setLevel8Status] = useState(false);

  const [primaryRef9contractData, setprimaryRef9contractData] = useState([]);
  const [SecondaryRef9contractData, setSecondaryRef9contractData] = useState(
    []
  );
  const [level9Status, setLevel9Status] = useState(false);

  const [primaryRef10contractData, setprimaryRef10contractData] = useState([]);
  const [SecondaryRef10contractData, setSecondaryRef10contractData] = useState(
    []
  );
  const [level10Status, setLevel10Status] = useState(false);

  let UserID = localStorage.getItem("userID");
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userid = queryParams.get("user");
  const USERADDRESS = localStorage.getItem("address");


  const clickHandlerXcore = async (userid,usrlevel, amounts) => {

    let userRefID;
    if (userid == null && UserID != "") {
      userRefID = UserID;
    } else if (userid >= 1) {
      userRefID = userid;
    }
    let userData = await getUserDataAPIUsingId(userRefID);
    // await addAllLevelUser(userData, usrlevel, amounts).then((data) => {
    //   console.log("work", data);
    // }).catch((err) => { console.log("error= ", err) });


    if (userData.status) {
      let addr = userData.data.address;
      let web3 = new Web3(window.ethereum);
      let xcorecontract = await new web3.eth.Contract(
        xcoreABI,
        config.xcorecontract
      );
      let usrlvls = await xcorecontract.methods
        .usercompletelevel(addr, usrlevel)
        .call();

      let lastlevel = usrlevel - 1;
      let isusrlvls = await xcorecontract.methods
        .usercompletelevel(addr, lastlevel)
        .call();

      if (isusrlvls == false && lastlevel != 0) {
        toast.error("Buy previous level");
      } else {
        if (usrlvls == false) {
          try {
            let busdcontract = new web3.eth.Contract(
              busdABI,
              config.busdcontract
            );
            let amount = await web3.utils.toWei(amounts, "ether");
            let result = await busdcontract.methods
              .approve(config.xcorecontract, amount)
              .send({ from: addr });

            let xcorecontract = await new web3.eth.Contract(
              xcoreABI,
              config.xcorecontract
            );
            let data = await xcorecontract.methods
              .buyNewLevelFor(addr, usrlevel)
              .send({ from: addr });

            let payload = {
              userID: UserID,
              amount: amounts,
            };
            let response = await updateUserAmount(payload);
            if (response.status) {
            }
            await addAllLevelUser(userData, usrlevel, amounts).then((data) => {
              console.log("work", data);
            }).catch((err) => { console.log("error= ", err) });
          } catch (error) {
            toast.error("Something went wrong..!");
          }
        } else {
          navigate(`/metakingxcoreinner?user=${userRefID}&level=${usrlevel}`);
        }
      }
    }

  };

  useEffect(() => {
    const setaadd = async () => {
      let web3 = new Web3(config.rpcUrls);
      let xcorecontract = await new web3.eth.Contract(
        xcoreABI,
        config.xcorecontract
      );

      if (userid == null) {
        // setAddressstate(USERADDRESS)

        let usrlvl2 = await xcorecontract.methods
          .usercompletelevel(USERADDRESS, 2)
          .call();

        let usrlvl3 = await xcorecontract.methods
          .usercompletelevel(USERADDRESS, 3)
          .call();
        let usrlvl4 = await xcorecontract.methods
          .usercompletelevel(USERADDRESS, 4)
          .call();
        let usrlvl5 = await xcorecontract.methods
          .usercompletelevel(USERADDRESS, 5)
          .call();
        let usrlvl6 = await xcorecontract.methods
          .usercompletelevel(USERADDRESS, 6)
          .call();
        let usrlvl7 = await xcorecontract.methods
          .usercompletelevel(USERADDRESS, 7)
          .call();
        let usrlvl8 = await xcorecontract.methods
          .usercompletelevel(USERADDRESS, 8)
          .call();
        let usrlvl9 = await xcorecontract.methods
          .usercompletelevel(USERADDRESS, 9)
          .call();
        let usrlvl10 = await xcorecontract.methods
          .usercompletelevel(USERADDRESS, 10)
          .call();

        if (usrlvl2 == true) {
          setLevel2Status(true);
        }
        if (usrlvl3 == true) {
          setLevel3Status(true);
        }
        if (usrlvl4 == true) {
          setLevel4Status(true);
        }
        if (usrlvl5 == true) {
          setLevel5Status(true);
        }
        if (usrlvl6 == true) {
          setLevel6Status(true);
        }
        if (usrlvl7 == true) {
          setLevel7Status(true);
        }
        if (usrlvl8 == true) {
          setLevel8Status(true);
        }
        if (usrlvl9 == true) {
          setLevel9Status(true);
        }
        if (usrlvl10 == true) {
          setLevel10Status(true);
        }

        let xcores1 = await xcoreRequestCycle({
          useradd: USERADDRESS,
          lvls: 1,
          usrlevels: 1,
        });
        setSecondaryRef1contractData(xcores1.usrnewcy2);
        setprimaryRef1contractData(xcores1.usrnewcy1);
        // setusr1partners(parseInt(xcores1.usrpartners));
        console.log("parseInt(xcores1.usrpartners)11111=", parseInt(xcores1));
        setUser1CyclesLength(parseInt(xcores1.usrlvls));
        let xcores2 = await xcoreRequestCycle({
          useradd: USERADDRESS,
          lvls: 1,
          usrlevels: 2,
        });
        // setusr2partners(parseInt(xcores2.usrpartners));
        setUser2CyclesLength(parseInt(xcores2.usrlvls));
        setSecondaryRef2contractData(xcores2.usrnewcy2);
        setprimaryRef2contractData(xcores2.usrnewcy1);

        let xcores3 = await xcoreRequestCycle({
          useradd: USERADDRESS,
          lvls: 1,
          usrlevels: 3,
        });
        // setusr3partners(parseInt(xcores3.usrpartners));
        setUser3CyclesLength(parseInt(xcores3.usrlvls));
        setSecondaryRef3contractData(xcores3.usrnewcy2);
        setprimaryRef3contractData(xcores3.usrnewcy1);

        let xcores4 = await xcoreRequestCycle({
          useradd: USERADDRESS,
          lvls: 1,
          usrlevels: 4,
        });

        // setusr4partners(parseInt(xcores4.usrpartners));
        setUser4CyclesLength(parseInt(xcores4.usrlvls));
        setSecondaryRef4contractData(xcores4.usrnewcy2);
        setprimaryRef4contractData(xcores4.usrnewcy1);

        let xcores5 = await xcoreRequestCycle({
          useradd: USERADDRESS,
          lvls: 1,
          usrlevels: 5,
        });
        // setusr5partners(parseInt(xcores5.usrpartners));
        setUser5CyclesLength(parseInt(xcores5.usrlvls));
        setSecondaryRef5contractData(xcores5.usrnewcy2);
        setprimaryRef5contractData(xcores5.usrnewcy1);

        let xcores6 = await xcoreRequestCycle({
          useradd: USERADDRESS,
          lvls: 1,
          usrlevels: 6,
        });
        // setusr6partners(parseInt(xcores6.usrpartners));
        setUser6CyclesLength(parseInt(xcores6.usrlvls));
        setSecondaryRef6contractData(xcores6.usrnewcy2);
        setprimaryRef6contractData(xcores6.usrnewcy1);

        let xcores7 = await xcoreRequestCycle({
          useradd: USERADDRESS,
          lvls: 1,
          usrlevels: 7,
        });
        // setusr7partners(parseInt(xcores7.usrpartners));
        setUser7CyclesLength(parseInt(xcores7.usrlvls));
        setSecondaryRef7contractData(xcores7.usrnewcy2);
        setprimaryRef7contractData(xcores7.usrnewcy1);

        let xcores8 = await xcoreRequestCycle({
          useradd: USERADDRESS,
          lvls: 1,
          usrlevels: 8,
        });
        // setusr8partners(parseInt(xcores8.usrpartners));
        setUser8CyclesLength(parseInt(xcores8.usrlvls));
        setSecondaryRef8contractData(xcores8.usrnewcy2);
        setprimaryRef8contractData(xcores8.usrnewcy1);

        let xcores9 = await xcoreRequestCycle({
          useradd: USERADDRESS,
          lvls: 1,
          usrlevels: 9,
        });
        // setusr9partners(parseInt(xcores9.usrpartners));
        setUser9CyclesLength(parseInt(xcores9.usrlvls));
        setSecondaryRef9contractData(xcores9.usrnewcy2);
        setprimaryRef9contractData(xcores9.usrnewcy1);

        let xcores10 = await xcoreRequestCycle({
          useradd: USERADDRESS,
          lvls: 1,
          usrlevels: 10,
        });
        // setusr10partners(parseInt(xcores10.usrpartners));
        setUser10CyclesLength(parseInt(xcores10.usrlvls));
        setSecondaryRef10contractData(xcores10.usrnewcy2);
        setprimaryRef10contractData(xcores10.usrnewcy1);
      } else {
        let userData = await getUserDataAPI(userid);
        if (userData.status) {
          let usrlvl2 = await xcorecontract.methods
            .usercompletelevel(userData.data.address, 2)
            .call();
          let usrlvl3 = await xcorecontract.methods
            .usercompletelevel(userData.data.address, 3)
            .call();
          let usrlvl4 = await xcorecontract.methods
            .usercompletelevel(userData.data.address, 4)
            .call();
          let usrlvl5 = await xcorecontract.methods
            .usercompletelevel(userData.data.address, 5)
            .call();
          let usrlvl6 = await xcorecontract.methods
            .usercompletelevel(userData.data.address, 6)
            .call();
          let usrlvl7 = await xcorecontract.methods
            .usercompletelevel(userData.data.address, 7)
            .call();
          let usrlvl8 = await xcorecontract.methods
            .usercompletelevel(userData.data.address, 8)
            .call();
          let usrlvl9 = await xcorecontract.methods
            .usercompletelevel(userData.data.address, 9)
            .call();
          let usrlvl10 = await xcorecontract.methods
            .usercompletelevel(userData.data.address, 10)
            .call();
          if (usrlvl2 == true) {
            setLevel2Status(true);
          }
          if (usrlvl3 == true) {
            setLevel3Status(true);
          }
          if (usrlvl4 == true) {
            setLevel4Status(true);
          }
          if (usrlvl5 == true) {
            setLevel5Status(true);
          }
          if (usrlvl6 == true) {
            setLevel6Status(true);
          }
          if (usrlvl7 == true) {
            setLevel7Status(true);
          }
          if (usrlvl8 == true) {
            setLevel8Status(true);
          }
          if (usrlvl9 == true) {
            setLevel9Status(true);
          }
          if (usrlvl10 == true) {
            setLevel10Status(true);
          }

          // setAddressstate(userData.data.address);
          let xcores1 = await xcoreRequestCycle({
            useradd: userData.data.address,
            lvls: 1,
            usrlevels: 1,
          });

          // setusr1partners(parseInt(xcores1.usrpartners));
          setUser1CyclesLength(parseInt(xcores1.usrlvls));
          setSecondaryRef1contractData(xcores1.usrnewcy2);
          setprimaryRef1contractData(xcores1.usrnewcy1);

          let xcores2 = await xcoreRequestCycle({
            useradd: userData.data.address,
            lvls: 1,
            usrlevels: 2,
          });
          // setusr2partners(parseInt(xcores2.usrpartners));
          setUser2CyclesLength(parseInt(xcores2.usrlvls));
          setSecondaryRef2contractData(xcores2.usrnewcy2);
          setprimaryRef2contractData(xcores2.usrnewcy1);

          let xcores3 = await xcoreRequestCycle({
            useradd: userData.data.address,
            lvls: 1,
            usrlevels: 3,
          });
          console.log("parseInt(xcores3.usrpartners)==",parseInt(xcores3.usrpartners))
          // setusr3partners(parseInt(xcores3.usrpartners));
          setUser3CyclesLength(parseInt(xcores3.usrlvls));
          setSecondaryRef3contractData(xcores3.usrnewcy2);
          setprimaryRef3contractData(xcores3.usrnewcy1);

          let xcores4 = await xcoreRequestCycle({
            useradd: userData.data.address,
            lvls: 1,
            usrlevels: 4,
          });
          // setusr4partners(parseInt(xcores4.usrpartners));
          setUser4CyclesLength(parseInt(xcores4.usrlvls));
          setSecondaryRef4contractData(xcores4.usrnewcy2);
          setprimaryRef4contractData(xcores4.usrnewcy1);

          let xcores5 = await xcoreRequestCycle({
            useradd: userData.data.address,
            lvls: 1,
            usrlevels: 5,
          });
          // setusr5partners(parseInt(xcores5.usrpartners));
          setUser5CyclesLength(parseInt(xcores5.usrlvls));
          setSecondaryRef5contractData(xcores5.usrnewcy2);
          setprimaryRef5contractData(xcores5.usrnewcy1);

          let xcores6 = await xcoreRequestCycle({
            useradd: userData.data.address,
            lvls: 1,
            usrlevels: 6,
          });
          // setusr6partners(parseInt(xcores6.usrpartners));
          setUser6CyclesLength(parseInt(xcores6.usrlvls));
          setSecondaryRef6contractData(xcores6.usrnewcy2);
          setprimaryRef6contractData(xcores6.usrnewcy1);

          let xcores7 = await xcoreRequestCycle({
            useradd: userData.data.address,
            lvls: 1,
            usrlevels: 7,
          });
          // setusr7partners(parseInt(xcores7.usrpartners));
          setUser7CyclesLength(parseInt(xcores7.usrlvls));
          setSecondaryRef7contractData(xcores7.usrnewcy2);
          setprimaryRef7contractData(xcores7.usrnewcy1);

          let xcores8 = await xcoreRequestCycle({
            useradd: userData.data.address,
            lvls: 1,
            usrlevels: 8,
          });
          // setusr8partners(parseInt(xcores8.usrpartners));
          setUser8CyclesLength(parseInt(xcores8.usrlvls));
          setSecondaryRef8contractData(xcores8.usrnewcy2);
          setprimaryRef8contractData(xcores8.usrnewcy1);

          let xcores9 = await xcoreRequestCycle({
            useradd: userData.data.address,
            lvls: 1,
            usrlevels: 9,
          });
          // setusr9partners(parseInt(xcores9.usrpartners));
          setUser9CyclesLength(parseInt(xcores9.usrlvls));
          setSecondaryRef9contractData(xcores9.usrnewcy2);
          setprimaryRef9contractData(xcores9.usrnewcy1);

          let xcores10 = await xcoreRequestCycle({
            useradd: userData.data.address,
            lvls: 1,
            usrlevels: 10,
          });
          // setusr10partners(parseInt(xcores10.usrpartners));
          setUser10CyclesLength(parseInt(xcores10.usrlvls));
          setSecondaryRef10contractData(xcores10.usrnewcy2);
          setprimaryRef10contractData(xcores10.usrnewcy1);
        }
      }
    };
    // if (level2Status === true ) {
      // primaryRef1contractData.map((data) => {
      //   if (data[1] !== undefined) {
      //     console.log("primaryRef1contractData.length++++++", primaryRef1contractData.length)
      //     setusr1partners(primaryRef1contractData.length)
      //   }
      // })
    // }

    setaadd();
  }, [userid]);

const userCountAdd = () =>{
 
    primaryRef1contractData.map((data) => {
      if (data[1] !== undefined) {
        const filteredArray1 = primaryRef1contractData.filter(item => item !== 0);
        const filteredArray2 = SecondaryRef1contractData.filter(item => item !== 0);
        setusr1partners(filteredArray1.length + filteredArray2.length)
      }
    })
    primaryRef2contractData.map((data) => {
      if (data[1] !== undefined) {
        const filteredArray1 = primaryRef2contractData.filter(item => item !== 0);
        const filteredArray2 = SecondaryRef2contractData.filter(item => item !== 0);
        setusr2partners(filteredArray1.length+ filteredArray2.length)
      }
    })
    primaryRef3contractData.map((data) => {
      if (data[1] !== undefined) {
        const filteredArray1 = primaryRef3contractData.filter(item => item !== 0);
        const filteredArray2 = SecondaryRef3contractData.filter(item => item !== 0);
        setusr3partners(filteredArray1.length  + filteredArray2.length)
      }
    })
    primaryRef4contractData.map((data) => {
      if (data[1] !== undefined) {
        const filteredArray1 = primaryRef4contractData.filter(item => item !== 0);
        const filteredArray2 = SecondaryRef4contractData.filter(item => item !== 0);
        setusr4partners(filteredArray1.length  + filteredArray2.length)
      }
    })
    primaryRef5contractData.map((data) => {
      if (data[1] !== undefined) {
        const filteredArray1 = primaryRef5contractData.filter(item => item !== 0);
        const filteredArray2 = SecondaryRef5contractData.filter(item => item !== 0);
        setusr5partners(filteredArray1.length  + filteredArray2.length)
      }
    })
    primaryRef6contractData.map((data) => {
      if (data[1] !== undefined) {
        const filteredArray1 = primaryRef6contractData.filter(item => item !== 0);
        const filteredArray2 = SecondaryRef6contractData.filter(item => item !== 0);
        setusr6partners(filteredArray1.length  + filteredArray2.length)
      }
    })
    primaryRef7contractData.map((data) => {
      if (data[1] !== undefined) {
        const filteredArray1 = primaryRef7contractData.filter(item => item !== 0);
        const filteredArray2 = SecondaryRef7contractData.filter(item => item !== 0);
        setusr7partners(filteredArray1.length  + filteredArray2.length)
      }
    })
    primaryRef8contractData.map((data) => {
      if (data[1] !== undefined) {
        const filteredArray1 = primaryRef8contractData.filter(item => item !== 0);
        const filteredArray2 = SecondaryRef8contractData.filter(item => item !== 0);
        setusr8partners(filteredArray1.length  + filteredArray2.length)
      }
    })
    primaryRef9contractData.map((data) => {
      if (data[1] !== undefined) {
        const filteredArray1 = primaryRef9contractData.filter(item => item !== 0);
        const filteredArray2 = SecondaryRef9contractData.filter(item => item !== 0);
        setusr9partners(filteredArray1.length  + filteredArray2.length)
      }
    })
     primaryRef10contractData.map((data) => {
      if (data[1] !== undefined) {
        const filteredArray1 = primaryRef10contractData.filter(item => item !== 0);
        const filteredArray2 = SecondaryRef10contractData.filter(item => item !== 0);
        setusr10partners(filteredArray1.length  + filteredArray2.length)
      }
    })
  }

  


  const userAllDetails = () => {

    const details1 = [
      [...primaryRef1contractData], [...SecondaryRef1contractData],
      [...primaryRef2contractData], [...SecondaryRef2contractData],
      [...primaryRef3contractData], [...SecondaryRef3contractData],
      [...primaryRef4contractData], [...SecondaryRef4contractData],
      [...primaryRef5contractData], [...SecondaryRef5contractData],
      [...primaryRef6contractData], [...SecondaryRef6contractData],
      [...primaryRef7contractData], [...SecondaryRef7contractData],
      [...primaryRef8contractData], [...SecondaryRef8contractData],
      [...primaryRef9contractData], [...SecondaryRef9contractData],
      [...primaryRef10contractData], [...SecondaryRef10contractData],
    ]

    let userDetailsGet = []
    const details2 = details1.flat(1)
    const details3 = details2.map((data) => data)
    details3.map((element) => {
      if (Array.isArray(element)) {
        const userObj = {
          userId: element[1],
          userLevel: element[3]
        }
        userDetailsGet.push(userObj)
        return [...element];
      } else {
        return [element];
      }
    });
    setCheckLength(userDetailsGet.length)
    if (primaryRef1contractData[0] !== 0) {
      if (userid == null && UserID !== "") {
        userRefLev(UserID, userDetailsGet)
      } else if (userid >= 1) {
        userRefLev(userid, userDetailsGet);
      }

    }
  }

  const userRefLev = async (userId, userDetailsGet) => {
    let userOverAllRef = await userRefLevel(userDetailsGet);
    if (userOverAllRef.status) {
      setUserShowDetails(userOverAllRef.primaryResult)
      const primaryValues = userOverAllRef.primaryResult.slice(0, pageNumber)
      setPrimaryRefData(primaryValues);
      setSecondaryRefData(userOverAllRef.secondaryResult);
      setResponseSendBackend(false)
    } else {
      setPrimaryRefData([]);
      setSecondaryRefData([]);
      setResponseSendBackend(false)
      // toast.error("User not found");
      // navigate(`/login`);
    }
  };

  const seemoreButton = async () => {

    // let userOverAllRef = await userRefLevel(userid || userId,userDetailsGet);
    if (userShowDetails !== "") {

      let primaryValues;
      if (pageNumber === 5) {
        primaryValues = userShowDetails.slice(0, pageNumber + 1)
        setPageNumber(7)
      } else {
        primaryValues = userShowDetails.slice(0, pageNumber)
        setPageNumber(pageNumber + 1)
      }

      setPrimaryRefData(primaryValues);
      // setSecondaryRefData(userOverAllRef.secondaryResult);

    } else {
      // setPrimaryRefData([]);
      // setSecondaryRefData([]);
      toast.error("User not found");
      // navigate(`/login`);
    }
  };


  const totalXcoreTransaction = async () => {
    try {
      let totalAmt = await totalTransactionAmt();
      if (totalAmt.status) {
        setXcoreTotalAmt(totalAmt.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const clickHandler = async (userID) => {
    // console.log("usrlevel",usrlevel);
    // navigate(`/dashboard?user=${userID}`);
    // window.location.reload();
  };
  //  console.log("primaryRef2contractData+++++++++++++++++",userShowDetails.length, checkLength)
  // console.log("level10Status=======", primaryRef10contractData);
  SecondaryRef10contractData.map((data) => {
    if (data >= 0 && responseSendBackend === true &&
      userShowDetails.length !== checkLength) {
        userCountAdd()
        userAllDetails()
      // addusers()
      setResponseSendBackend(false)

    }
  }
  )

  useEffect(() => {
    setResponseSendBackend(true)
    totalXcoreTransaction();
  }, [UserID, userid]);

  return (
<div className="App">
<Topnav />
<ToastContainer />
<section className="dashboard-split-section">
  <div className="container">
    <div className="row">
      <div className="col-lg-2 dashboard-split-left-section d-lg-block d-none">
        <Sidenav />
      </div>
      <div className="col-lg-10 dashboard-split-right-section">
        <div className="row p-2 g-4">
          <div className="col-lg-12">
            <p className="mb-3 dashboard-text-6">
              <span className="mb-0 dashboard-text-4">
                ID {userid || UserID} /
              </span>
              Meta King xCore
            </p>
            <div className="d-flex flex-lg-row flex-column">
              <div>
                <p className="dashboard-text-2 mb-0">Metaking xCore</p>
              </div>
              <div className="ms-lg-auto">
                <p className="dashboard-text-2 mb-0">
                  {xcoreTotalAmt > 0 ? xcoreTotalAmt : ""}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row p-2 g-4">
          <div className="col-lg-12">
            <div className="card dashboard-x3-card-1 position-relative overflow-hidden">
              <div className="dashboard-user-programs-glow-2"></div>
              <div className="d-flex flex-row g-4 flex-wrap gap-3">
                <div className="xcore-card-1">
                  <div className="card h-100 dashboard-x3-card-1-inner-1">
                    <a
                      className=""
                      onClick={() => clickHandlerXcore(userid, 1, 15)}
                    >
                      <div className="d-flex flex-row mb-2">
                        <div>
                          <span className="mb-0 dashboard-text-4">
                            Lvl 1
                          </span>
                        </div>
                        <div className="ms-lg-auto">
                          <span className="dashboard-x3-text-1">
                            <img
                              src={imglogo}
                              className="dashboard-gold-inner-sm-img-1"
                              title="coin image"
                              alt="coin-image"
                            />
                          </span>
                          <span className="dashboard-x3-text-1">15</span>
                        </div>
                      </div>
                      <div className="xcore-total-grid-overrall">
                        <div className="xcore-total-grid-2">
                          <div className="grid-dashboard-xcore-l2-container">
                            {primaryRef1contractData.length > 0 &&
                              primaryRef1contractData.map(
                                (data, index) => {

                                  return (
                                    <>
                                      <div
                                        key={index}
                                        className={`dashboard-user-xcore-all-level-2-programs ${data !== 0
                                          ? "dashboard-user-xcore-all-level-2-programs dashboard-user-xcore-programs-active"
                                          : ""
                                          }`}
                                      >
                                        {data === 0
                                          ? ""
                                          : parseInt(data.refId)}
                                      </div>
                                    </>
                                  );
                                }
                              )}
                          </div>
                        </div>
                        <div className="xcore-total-grid-1">
                          <div className="grid-dashboard-xcore-l1-container">
                            {SecondaryRef1contractData.length >= 0 &&
                              SecondaryRef1contractData.map(
                                (data, index) => {

                                  return (
                                    <>
                                      <div
                                        key={index}
                                        className={`dashboard-user-xcore-all-level-programs ${data !== 0
                                          ? "dashboard-user-xcore-all-level-programs dashboard-user-xcore-programs-active"
                                          : ""
                                          }`}
                                      >
                                        {data === 0
                                          ? ""
                                          : parseInt(data.refId)}
                                      </div>
                                    </>
                                  );
                                }
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="mt-4">
                        <span className="mb-0 dashboard-text-4">
                          <i className="fa-solid fa-user-group"></i>
                        </span>
                        <span className="dashboard-x3-text-1 ms-1">
                          {usr1partners}
                        </span>
                        <span className="mb-0 dashboard-text-4 ms-2">
                          <i className="fa-solid fa-arrows-rotate"></i>
                        </span>
                        <span className="dashboard-x3-text-1 ms-1">
                          {User1CyclesLength}
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="xcore-card-1">
                  <div className="card h-100 dashboard-x3-card-1-inner-1">
                    <a
                      className=""
                      //  onClick={() => clickHandlerXcore(userid, 2, 30)}
                    >
                      <div className="d-flex flex-row mb-2">
                        <div>
                          <span className="mb-0 dashboard-text-4">
                            Lvl 2
                          </span>
                        </div>
                        <div className="ms-lg-auto">
                          <span className="dashboard-x3-text-1">
                            <img
                              src={imglogo}
                              className="dashboard-gold-inner-sm-img-1"
                              title="coin image"
                              alt="coin-image"
                            />
                          </span>
                          <span className="dashboard-x3-text-1">30</span>
                        </div>
                      </div>
                      {level2Status == true ? (
                        <>
                          <a
                            className=""
                            onClick={() => clickHandlerXcore(userid, 2, 30)}
                          >
                            <div className="xcore-total-grid-overrall">

                              <div className="xcore-total-grid-2">
                                <div className="grid-dashboard-xcore-l2-container">
                                  {primaryRef2contractData.length > 0 &&
                                    primaryRef2contractData.map(
                                      (data, index) => {

                                        return (
                                          <>
                                            <div
                                              key={index}
                                              className={`dashboard-user-xcore-all-level-2-programs ${data !== 0
                                                ? "dashboard-user-xcore-all-level-2-programs dashboard-user-xcore-programs-active"
                                                : ""
                                                }`}
                                            >
                                              {data === 0
                                                ? ""
                                                : parseInt(data.refId)}
                                            </div>
                                          </>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                              <div className="xcore-total-grid-1">
                                <div className="grid-dashboard-xcore-l1-container">
                                  {SecondaryRef2contractData.length >= 0 &&
                                    SecondaryRef2contractData.map(

                                      (data, index) => {
                                        return (
                                          <>
                                            <div
                                              key={index}
                                              className={`dashboard-user-xcore-all-level-programs ${data !== 0
                                                ? "dashboard-user-xcore-all-level-programs dashboard-user-xcore-programs-active"
                                                : ""
                                                }`}
                                            >
                                              {data === 0
                                                ? ""
                                                : parseInt(data.refId)}
                                            </div>
                                          </>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="mt-4">
                              <span className="mb-0 dashboard-text-4">
                                <i className="fa-solid fa-user-group"></i>
                              </span>
                              <span className="dashboard-x3-text-1 ms-1">
                                {usr2partners}
                              </span>
                              <span className="mb-0 dashboard-text-4 ms-2">
                                <i className="fa-solid fa-arrows-rotate"></i>
                              </span>
                              <span className="dashboard-x3-text-1 ms-1">
                                {User2CyclesLength}
                              </span>
                            </div>
                          </a>
                        </>
                      ) : (
                        <div className="x-core-buy-btn">
                          <button
                            className="dashboard-preview-btn-2"
                            onClick={() =>
                              clickHandlerXcore(userid, 2, 30)
                            }
                          >
                            Buy
                          </button>
                        </div>
                      )}
                    </a>
                  </div>
                </div>

                <div className="xcore-card-1">
                  <div className="card h-100 dashboard-x3-card-1-inner-1">
                    <a
                      className=""
                      // onClick={() => clickHandlerXcore(userid, 3, 100)}
                    >
                      <div className="d-flex flex-row mb-2">
                        <div>
                          <span className="mb-0 dashboard-text-4">
                            Lvl 3
                          </span>
                        </div>
                        <div className="ms-lg-auto">
                          <span className="dashboard-x3-text-1">
                            <img
                              src={imglogo}
                              className="dashboard-gold-inner-sm-img-1"
                              title="coin image"
                              alt="coin-image"
                            />
                          </span>
                          <span className="dashboard-x3-text-1">100</span>
                        </div>
                      </div>
                      {level3Status == true ? (
                        <>
                         <a
                            className=""
                            onClick={() => clickHandlerXcore(userid, 3, 100)}
                          >
                          <div className="xcore-total-grid-overrall">
                            <div className="xcore-total-grid-2">
                              <div className="grid-dashboard-xcore-l2-container">
                                {primaryRef3contractData.length > 0 &&
                                  primaryRef3contractData.map(
                                    (data, index) => {
                                      return (
                                        <>
                                          <div
                                            key={index}
                                            className={`dashboard-user-xcore-all-level-2-programs ${data !== 0
                                              ? "dashboard-user-xcore-all-level-2-programs dashboard-user-xcore-programs-active"
                                              : ""
                                              }`}
                                          >
                                            {data === 0
                                              ? ""
                                              : parseInt(data.refId)}
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                            <div className="xcore-total-grid-1">
                              <div className="grid-dashboard-xcore-l1-container">
                                {SecondaryRef3contractData.length >= 0 &&
                                  SecondaryRef3contractData.map(
                                    (data, index) => {
                                      return (
                                        <>
                                          <div
                                            key={index}
                                            className={`dashboard-user-xcore-all-level-programs ${data !== 0
                                              ? "dashboard-user-xcore-all-level-programs dashboard-user-xcore-programs-active"
                                              : ""
                                              }`}
                                          >
                                            {data === 0
                                              ? ""
                                              : parseInt(data.refId)}
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="mt-4">
                            <span className="mb-0 dashboard-text-4">
                              <i className="fa-solid fa-user-group"></i>
                            </span>
                            <span className="dashboard-x3-text-1 ms-1">
                              {usr3partners}
                            </span>
                            <span className="mb-0 dashboard-text-4 ms-2">
                              <i className="fa-solid fa-arrows-rotate"></i>
                            </span>
                            <span className="dashboard-x3-text-1 ms-1">
                              {User3CyclesLength}
                            </span>
                          </div>
                          </a>
                        </>
                      ) : (
                        <div className="x-core-buy-btn">
                          <button
                            className="dashboard-preview-btn-2"
                            onClick={() =>
                              clickHandlerXcore(userid, 3, 100)
                            }
                          >
                            Buy
                          </button>
                        </div>
                      )}
                    </a>
                  </div>
                </div>
                <div className="xcore-card-1">
                  <div className="card h-100 dashboard-x3-card-1-inner-1">
                    <div className="d-flex flex-row mb-2">
                      <div>
                        <span className="mb-0 dashboard-text-4">
                          Lvl 4
                        </span>
                      </div>
                      <div className="ms-lg-auto">
                        <span className="dashboard-x3-text-1">
                          <img
                            src={imglogo}
                            className="dashboard-gold-inner-sm-img-1"
                            title="coin image"
                            alt="coin-image"
                          />
                        </span>
                        <span className="dashboard-x3-text-1">200</span>
                      </div>
                    </div>
                    {level4Status == true ? (
                      <>
                        <a
                          className=""
                          onClick={() =>
                            clickHandlerXcore(userid, 4, 200)
                          }
                        >
                          <div className="xcore-total-grid-overrall">
                            <div className="xcore-total-grid-2">
                              <div className="grid-dashboard-xcore-l2-container">
                                {primaryRef4contractData.length > 0 &&
                                  primaryRef4contractData.map(
                                    (data, index) => {
                                      return (
                                        <>
                                          <div
                                            key={index}
                                            className={`dashboard-user-xcore-all-level-2-programs ${data !== 0
                                              ? "dashboard-user-xcore-all-level-2-programs dashboard-user-xcore-programs-active"
                                              : ""
                                              }`}
                                          >
                                            {data === 0
                                              ? ""
                                              : parseInt(data.refId)}
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                            <div className="xcore-total-grid-1">
                              <div className="grid-dashboard-xcore-l1-container">
                                {SecondaryRef4contractData.length >= 0 &&
                                  SecondaryRef4contractData.map(
                                    (data, index) => {
                                      return (
                                        <>
                                          <div
                                            key={index}
                                            className={`dashboard-user-xcore-all-level-programs ${data !== 0
                                              ? "dashboard-user-xcore-all-level-programs dashboard-user-xcore-programs-active"
                                              : ""
                                              }`}
                                          >
                                            {data === 0
                                              ? ""
                                              : parseInt(data.refId)}
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="mt-4">
                            <span className="mb-0 dashboard-text-4">
                              <i className="fa-solid fa-user-group"></i>
                            </span>
                            <span className="dashboard-x3-text-1 ms-1">
                              {usr4partners}
                            </span>
                            <span className="mb-0 dashboard-text-4 ms-2">
                              <i className="fa-solid fa-arrows-rotate"></i>
                            </span>
                            <span className="dashboard-x3-text-1 ms-1">
                              {User4CyclesLength}
                            </span>
                          </div>
                        </a>
                      </>
                    ) : (
                      <div className="x-core-buy-btn">
                        <button
                          className="dashboard-preview-btn-2"
                          onClick={() =>
                            clickHandlerXcore(userid, 4, 200)
                          }
                        >
                          Buy
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="xcore-card-1">
                  <div className="card h-100 dashboard-x3-card-1-inner-1">
                    <a
                      className=""
                      // onClick={() => clickHandlerXcore(userid, 5, 300)}
                    >
                      <div className="d-flex flex-row mb-2">
                        <div>
                          <span className="mb-0 dashboard-text-4">
                            Lvl 5
                          </span>
                        </div>
                        <div className="ms-lg-auto">
                          <span className="dashboard-x3-text-1">
                            <img
                              src={imglogo}
                              className="dashboard-gold-inner-sm-img-1"
                              title="coin image"
                              alt="coin-image"
                            />
                          </span>
                          <span className="dashboard-x3-text-1">300</span>
                        </div>
                      </div>
                      {level5Status == true ? (
                        <>
                         <a
                            className=""
                            onClick={() => clickHandlerXcore(userid, 5, 300)}
                          >
                          <div className="xcore-total-grid-overrall">
                            <div className="xcore-total-grid-2">
                              <div className="grid-dashboard-xcore-l2-container">
                                {primaryRef5contractData.length > 0 &&
                                  primaryRef5contractData.map(
                                    (data, index) => {
                                      return (
                                        <>
                                          <div
                                            key={index}
                                            className={`dashboard-user-xcore-all-level-2-programs ${data !== 0
                                              ? "dashboard-user-xcore-all-level-2-programs dashboard-user-xcore-programs-active"
                                              : ""
                                              }`}
                                          >
                                            {data === 0
                                              ? ""
                                              : parseInt(data.refId)}
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                            <div className="xcore-total-grid-1">
                              <div className="grid-dashboard-xcore-l1-container">
                                {SecondaryRef5contractData.length >= 0 &&
                                  SecondaryRef5contractData.map(
                                    (data, index) => {
                                      return (
                                        <>
                                          <div
                                            key={index}
                                            className={`dashboard-user-xcore-all-level-programs ${data !== 0
                                              ? "dashboard-user-xcore-all-level-programs dashboard-user-xcore-programs-active"
                                              : ""
                                              }`}
                                          >
                                            {data === 0
                                              ? ""
                                              : parseInt(data.refId)}
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="mt-4">
                            <span className="mb-0 dashboard-text-4">
                              <i className="fa-solid fa-user-group"></i>
                            </span>
                            <span className="dashboard-x3-text-1 ms-1">
                              {usr5partners}
                            </span>
                            <span className="mb-0 dashboard-text-4 ms-2">
                              <i className="fa-solid fa-arrows-rotate"></i>
                            </span>
                            <span className="dashboard-x3-text-1 ms-1">
                              {User5CyclesLength}
                            </span>
                          </div>
                          </a>
                        </>
                      ) : (
                        <div className="x-core-buy-btn">
                          <button
                            className="dashboard-preview-btn-2"
                            onClick={() =>
                              clickHandlerXcore(userid, 5, 300)
                            }
                          >
                            Buy
                          </button>
                        </div>
                      )}
                    </a>
                  </div>
                </div>
                <div className="xcore-card-1">
                  <div className="card h-100 dashboard-x3-card-1-inner-1">
                    <a
                      className=""
                      // onClick={() => clickHandlerXcore(userid, 6, 500)}
                    >
                      <div className="d-flex flex-row mb-2">
                        <div>
                          <span className="mb-0 dashboard-text-4">
                            Lvl 6
                          </span>
                        </div>
                        <div className="ms-lg-auto">
                          <span className="dashboard-x3-text-1">
                            <img
                              src={imglogo}
                              className="dashboard-gold-inner-sm-img-1"
                              title="coin image"
                              alt="coin-image"
                            />
                          </span>
                          <span className="dashboard-x3-text-1">500</span>
                        </div>
                      </div>
                      {level6Status == true ? (
                        <>
                         <a
                            className=""
                            onClick={() => clickHandlerXcore(userid, 6, 500)}
                          >
                          <div className="xcore-total-grid-overrall">
                            <div className="xcore-total-grid-2">
                              <div className="grid-dashboard-xcore-l2-container">
                                {primaryRef6contractData.length > 0 &&
                                  primaryRef6contractData.map(
                                    (data, index) => {
                                      return (
                                        <>
                                          <div
                                            key={index}
                                            className={`dashboard-user-xcore-all-level-2-programs ${data !== 0
                                              ? "dashboard-user-xcore-all-level-2-programs dashboard-user-xcore-programs-active"
                                              : ""
                                              }`}
                                          >
                                            {data === 0
                                              ? ""
                                              : parseInt(data.refId)}
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                            <div className="xcore-total-grid-1">
                              <div className="grid-dashboard-xcore-l1-container">
                                {SecondaryRef6contractData.length >= 0 &&
                                  SecondaryRef6contractData.map(
                                    (data, index) => {
                                      return (
                                        <>
                                          <div
                                            key={index}
                                            className={`dashboard-user-xcore-all-level-programs ${data !== 0
                                              ? "dashboard-user-xcore-all-level-programs dashboard-user-xcore-programs-active"
                                              : ""
                                              }`}
                                          >
                                            {data === 0
                                              ? ""
                                              : parseInt(data.refId)}
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="mt-4">
                            <span className="mb-0 dashboard-text-4">
                              <i className="fa-solid fa-user-group"></i>
                            </span>
                            <span className="dashboard-x3-text-1 ms-1">
                              {usr6partners}
                            </span>
                            <span className="mb-0 dashboard-text-4 ms-2">
                              <i className="fa-solid fa-arrows-rotate"></i>
                            </span>
                            <span className="dashboard-x3-text-1 ms-1">
                              {User6CyclesLength}
                            </span>
                          </div>
                          </a>
                        </>
                      ) : (
                        <div className="x-core-buy-btn">
                          <button
                            className="dashboard-preview-btn-2"
                            onClick={() =>
                              clickHandlerXcore(userid, 6, 500)
                            }
                          >
                            Buy
                          </button>
                        </div>
                      )}
                    </a>
                  </div>
                </div>
                <div className="xcore-card-1">
                  <div className="card h-100 dashboard-x3-card-1-inner-1">
                    <a
                      className=""
                      // onClick={() => clickHandlerXcore(userid, 7, 1000)}
                    >
                      <div className="d-flex flex-row mb-2">
                        <div>
                          <span className="mb-0 dashboard-text-4">
                            Lvl 7
                          </span>
                        </div>
                        <div className="ms-lg-auto">
                          <span className="dashboard-x3-text-1">
                            <img
                              src={imglogo}
                              className="dashboard-gold-inner-sm-img-1"
                              title="coin image"
                              alt="coin-image"
                            />
                          </span>
                          <span className="dashboard-x3-text-1">
                            1000
                          </span>
                        </div>
                      </div>
                      {level7Status == true ? (
                        <>
                         <a
                            className=""
                            onClick={() => clickHandlerXcore(userid, 7, 1000)}
                          >
                          <div className="xcore-total-grid-overrall">
                            <div className="xcore-total-grid-2">
                              <div className="grid-dashboard-xcore-l2-container">
                                {primaryRef7contractData.length > 0 &&
                                  primaryRef7contractData.map(
                                    (data, index) => {
                                      return (
                                        <>
                                          <div
                                            key={index}
                                            className={`dashboard-user-xcore-all-level-2-programs ${data !== 0
                                              ? "dashboard-user-xcore-all-level-2-programs dashboard-user-xcore-programs-active"
                                              : ""
                                              }`}
                                          >
                                            {data === 0
                                              ? ""
                                              : parseInt(data.refId)}
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                            <div className="xcore-total-grid-1">
                              <div className="grid-dashboard-xcore-l1-container">
                                {SecondaryRef7contractData.length >= 0 &&
                                  SecondaryRef7contractData.map(
                                    (data, index) => {
                                      return (
                                        <>
                                          <div
                                            key={index}
                                            className={`dashboard-user-xcore-all-level-programs ${data !== 0
                                              ? "dashboard-user-xcore-all-level-programs dashboard-user-xcore-programs-active"
                                              : ""
                                              }`}
                                          >
                                            {data === 0
                                              ? ""
                                              : parseInt(data.refId)}
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="mt-4">
                            <span className="mb-0 dashboard-text-4">
                              <i className="fa-solid fa-user-group"></i>
                            </span>
                            <span className="dashboard-x3-text-1 ms-1">
                              {usr7partners}
                            </span>
                            <span className="mb-0 dashboard-text-4 ms-2">
                              <i className="fa-solid fa-arrows-rotate"></i>
                            </span>
                            <span className="dashboard-x3-text-1 ms-1">
                              {User7CyclesLength}
                            </span>
                          </div>
                          </a>
                        </>
                      ) : (
                        <div className="x-core-buy-btn">
                          <button
                            className="dashboard-preview-btn-2"
                            onClick={() =>
                              clickHandlerXcore(userid, 7, 1000)
                            }
                          >
                            Buy
                          </button>
                        </div>
                      )}
                    </a>
                  </div>
                </div>
                <div className="xcore-card-1">
                  <div className="card h-100 dashboard-x3-card-1-inner-1">
                    <a
                      className=""
                      // onClick={() => clickHandlerXcore(userid, 8, 1500)}
                    >
                      <div className="d-flex flex-row mb-2">
                        <div>
                          <span className="mb-0 dashboard-text-4">
                            Lvl 8
                          </span>
                        </div>
                        <div className="ms-lg-auto">
                          <span className="dashboard-x3-text-1">
                            <img
                              src={imglogo}
                              className="dashboard-gold-inner-sm-img-1"
                              title="coin image"
                              alt="coin-image"
                            />
                          </span>
                          <span className="dashboard-x3-text-1">
                            1500
                          </span>
                        </div>
                      </div>
                      {level8Status == true ? (
                        <>
                         <a
                            className=""
                            onClick={() => clickHandlerXcore(userid, 8, 1500)}
                          >
                          <div className="xcore-total-grid-overrall">
                            <div className="xcore-total-grid-2">
                              <div className="grid-dashboard-xcore-l2-container">
                                {primaryRef8contractData.length > 0 &&
                                  primaryRef8contractData.map(
                                    (data, index) => {
                                      return (
                                        <>
                                          <div
                                            key={index}
                                            className={`dashboard-user-xcore-all-level-2-programs ${data !== 0
                                              ? "dashboard-user-xcore-all-level-2-programs dashboard-user-xcore-programs-active"
                                              : ""
                                              }`}
                                          >
                                            {data === 0
                                              ? ""
                                              : parseInt(data.refId)}
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                            <div className="xcore-total-grid-1">
                              <div className="grid-dashboard-xcore-l1-container">
                                {SecondaryRef8contractData.length >= 0 &&
                                  SecondaryRef8contractData.map(
                                    (data, index) => {
                                      return (
                                        <>
                                          <div
                                            key={index}
                                            className={`dashboard-user-xcore-all-level-programs ${data !== 0
                                              ? "dashboard-user-xcore-all-level-programs dashboard-user-xcore-programs-active"
                                              : ""
                                              }`}
                                          >
                                            {data === 0
                                              ? ""
                                              : parseInt(data.refId)}
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="mt-4">
                            <span className="mb-0 dashboard-text-4">
                              <i className="fa-solid fa-user-group"></i>
                            </span>
                            <span className="dashboard-x3-text-1 ms-1">
                              {usr8partners}
                            </span>
                            <span className="mb-0 dashboard-text-4 ms-2">
                              <i className="fa-solid fa-arrows-rotate"></i>
                            </span>
                            <span className="dashboard-x3-text-1 ms-1">
                              {User8CyclesLength}
                            </span>
                          </div>
                          </a>
                        </>
                      ) : (
                        <div className="x-core-buy-btn">
                          <button
                            className="dashboard-preview-btn-2"
                            onClick={() =>
                              clickHandlerXcore(userid, 8, 1500)
                            }
                          >
                            Buy
                          </button>
                        </div>
                      )}
                    </a>
                  </div>
                </div>
                <div className="xcore-card-1">
                  <div className="card h-100 dashboard-x3-card-1-inner-1">
                    <a
                      className=""
                      // onClick={() => clickHandlerXcore(userid, 9, 2500)}
                    >
                      <div className="d-flex flex-row mb-2">
                        <div>
                          <span className="mb-0 dashboard-text-4">
                            Lvl 9
                          </span>
                        </div>
                        <div className="ms-lg-auto">
                          <span className="dashboard-x3-text-1">
                            <img
                              src={imglogo}
                              className="dashboard-gold-inner-sm-img-1"
                              title="coin image"
                              alt="coin-image"
                            />
                          </span>
                          <span className="dashboard-x3-text-1">
                            2500
                          </span>
                        </div>
                      </div>
                      {level9Status == true ? (
                        <>
                         <a
                            className=""
                            onClick={() => clickHandlerXcore(userid, 9, 2500)}
                          >
                          <div className="xcore-total-grid-overrall">
                            <div className="xcore-total-grid-2">
                              <div className="grid-dashboard-xcore-l2-container">
                                {primaryRef9contractData.length > 0 &&
                                  primaryRef9contractData.map(
                                    (data, index) => {
                                      return (
                                        <>
                                          <div
                                            key={index}
                                            className={`dashboard-user-xcore-all-level-2-programs ${data !== 0
                                              ? "dashboard-user-xcore-all-level-2-programs dashboard-user-xcore-programs-active"
                                              : ""
                                              }`}
                                          >
                                            {data === 0
                                              ? ""
                                              : parseInt(data.refId)}
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                            <div className="xcore-total-grid-1">
                              <div className="grid-dashboard-xcore-l1-container">
                                {SecondaryRef9contractData.length >= 0 &&
                                  SecondaryRef9contractData.map(
                                    (data, index) => {
                                      return (
                                        <>
                                          <div
                                            key={index}
                                            className={`dashboard-user-xcore-all-level-programs ${data !== 0
                                              ? "dashboard-user-xcore-all-level-programs dashboard-user-xcore-programs-active"
                                              : ""
                                              }`}
                                          >
                                            {data === 0
                                              ? ""
                                              : parseInt(data.refId)}
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="mt-4">
                            <span className="mb-0 dashboard-text-4">
                              <i className="fa-solid fa-user-group"></i>
                            </span>
                            <span className="dashboard-x3-text-1 ms-1">
                              {usr9partners}
                            </span>
                            <span className="mb-0 dashboard-text-4 ms-2">
                              <i className="fa-solid fa-arrows-rotate"></i>
                            </span>
                            <span className="dashboard-x3-text-1 ms-1">
                              {User9CyclesLength}
                            </span>
                          </div>
                          </a>
                        </>
                      ) : (
                        <div className="x-core-buy-btn">
                          <button
                            className="dashboard-preview-btn-2"
                            onClick={() =>
                              clickHandlerXcore(userid, 9, 2500)
                            }
                          >
                            Buy
                          </button>
                        </div>
                      )}
                    </a>
                  </div>
                </div>
                <div className="xcore-card-1">
                  <div className="card h-100 dashboard-x3-card-1-inner-1">
                    <a
                      className=""
                      // onClick={() => clickHandlerXcore(userid, 10, 5000)}
                    >
                      <div className="d-flex flex-row mb-2">
                        <div>
                          <span className="mb-0 dashboard-text-4">
                            Lvl 10
                          </span>
                        </div>
                        <div className="ms-lg-auto">
                          <span className="dashboard-x3-text-1">
                            <img
                              src={imglogo}
                              className="dashboard-gold-inner-sm-img-1"
                              title="coin image"
                              alt="coin-image"
                            />
                          </span>
                          <span className="dashboard-x3-text-1">
                            5000
                          </span>
                        </div>
                      </div>
                      {level10Status == true ? (
                        <>
                         <a
                            className=""
                            onClick={() => clickHandlerXcore(userid, 10, 5000)}
                          >
                          <div className="xcore-total-grid-overrall">
                            <div className="xcore-total-grid-2">
                              <div className="grid-dashboard-xcore-l2-container">
                                {primaryRef10contractData.length > 0 &&
                                  primaryRef10contractData.map(
                                    (data, index) => {
                                      return (
                                        <>
                                          <div
                                            key={index}
                                            className={`dashboard-user-xcore-all-level-2-programs ${data !== 0
                                              ? "dashboard-user-xcore-all-level-2-programs dashboard-user-xcore-programs-active"
                                              : ""
                                              }`}
                                          >
                                            {data === 0
                                              ? ""
                                              : parseInt(data.refId)}
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                            <div className="xcore-total-grid-1">
                              <div className="grid-dashboard-xcore-l1-container">
                                {SecondaryRef10contractData.length >= 0 &&
                                  SecondaryRef10contractData.map(
                                    (data, index) => {
                                      return (
                                        <>
                                          <div
                                            key={index}
                                            className={`dashboard-user-xcore-all-level-programs ${data !== 0
                                              ? "dashboard-user-xcore-all-level-programs dashboard-user-xcore-programs-active"
                                              : ""
                                              }`}
                                          >
                                            {data === 0
                                              ? ""
                                              : parseInt(data.refId)}
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="mt-4">
                            <span className="mb-0 dashboard-text-4">
                              <i className="fa-solid fa-user-group"></i>
                            </span>
                            <span className="dashboard-x3-text-1 ms-1">
                              {usr10partners}
                            </span>
                            <span className="mb-0 dashboard-text-4 ms-2">
                              <i className="fa-solid fa-arrows-rotate"></i>
                            </span>
                            <span className="dashboard-x3-text-1 ms-1">
                              {User10CyclesLength}
                            </span>
                          </div>
                          </a>
                        </>
                      ) : (
                        <div className="x-core-buy-btn">
                          <button
                            className="dashboard-preview-btn-2"
                            onClick={() =>
                              clickHandlerXcore(userid, 10, 5000)
                            }
                          >
                            Buy
                          </button>
                        </div>
                      )}
                    </a>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-row mt-5 gap-4 flex-wrap">
                <div>
                  <span>
                    <i className="fa-sharp fa-solid fa-circle"></i>
                  </span>
                  <span className="dashboard-text-4 ms-2">
                    Direct partner
                  </span>
                </div>
                <div>
                  <span>
                    <i
                      className="fa-sharp fa-solid fa-circle"
                      style={{ color: "yellow" }}
                    ></i>
                  </span>
                  <span className="dashboard-text-4 ms-2">
                    Spillover from above
                  </span>
                </div>
                <div>
                  <span>
                    <i
                      className="fa-sharp fa-solid fa-circle"
                      style={{ color: "gold" }}
                    ></i>
                  </span>
                  <span className="dashboard-text-4 ms-2">
                    Spillover from below
                  </span>
                </div>
                <div>
                  <span>
                    <i
                      className="fa-sharp fa-solid fa-circle"
                      style={{ color: "aqua" }}
                    ></i>
                  </span>
                  <span className="dashboard-text-4 ms-2">Gift</span>
                </div>
                <div>
                  <span>
                    <i className="fa-solid fa-user-group"></i>
                  </span>
                  <span className="dashboard-text-4 ms-2">
                    Partners on level
                  </span>
                </div>
                <div>
                  <span>
                    <i className="fa-solid fa-arrows-rotate"></i>
                  </span>
                  <span className="dashboard-text-4 ms-2">
                    Level Cycle
                  </span>
                </div>
                <div>
                  <span>
                    <i className="fa-solid fa-circle-arrow-up"></i>
                  </span>
                  <span className="dashboard-text-4 ms-2">
                    Send to upline
                  </span>
                </div>
                <div>
                  <button
                    className="dashboard-button-2"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModaltwo"
                  >
                    <i className="fa-solid fa-circle-question me-1"></i>{" "}
                    Marketing legend
                  </button>
                </div>
              </div>
            </div>

            <div className="card dashboard-x3-card-2 mt-4">
              <table className="table text-white">
                <thead className="">
                  <tr>
                    <th scope="col">Type</th>
                    <th scope="col">Date</th>
                    <th scope="col">ID</th>
                    <th scope="col">Level</th>
                    <th scope="col">Wallet</th>
                    <th scope="col">USDT</th>
                  </tr>
                </thead>
                <tbody>
                  {primaryRefData.length >= 0 &&
                    primaryRefData.map((refUser) => {

                      if (refUser.userid == 0 || refUser.userId == 0) {

                        return (
                          <>
                            <tr>
                              <td>
                                <i className="fa-solid fa-user dashboard-activity-1"></i>
                              </td>
                              <td>0</td>
                              <td>
                                <button className="dashboard-button-1">
                                  0
                                </button>
                              </td>
                              <td>0</td>
                              <td>...</td>
                              <td>0</td>
                            </tr>
                          </>
                        );
                      }
                      return (
                        <>
                          <tr>
                            <td>
                              <i className="fa-solid fa-user dashboard-activity-1"></i>
                            </td>
                            <td>
                              {moment
                                .utc(refUser.date)
                                .format("YYYY-MM-DD HH:mm:ss")}
                            </td>
                            <td>
                              <button
                                className="dashboard-button-1"
                                onClick={() =>
                                  clickHandler(refUser.userId)
                                }
                              >
                                ID {refUser.userId}
                              </button>
                            </td>
                            <td>{refUser.level}</td>
                            <td>
                              <div
                                className="ms-auto d-flex align-items-center gap-2"
                                style={{ fontsize: "13px" }}
                              >
                                <span>
                                  {refUser.address.substring(0, 5) +
                                    "..." +
                                    refUser.address.substring(
                                      refUser.address.length - 4
                                    )}
                                </span>
                                {/* <button className="border-0 bg-transparent text-white">
                                  <i className="fa-solid fa-copy"></i>
                                </button>
                                <button className="border-0 bg-transparent text-white">
                                  <i className="fa-solid fa-link"></i>
                                </button> */}
                              </div>
                            </td>
                            <td>{refUser.amount / 2} USDT</td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
              <button
                className="btn dashboard-activity-seemore-btn mt-3 py-2"
                type="button"
                onClick={seemoreButton}
              >
                <i className="fa-solid fa-eye"></i> Seemore
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section className="dashboard-modal-section">
  <div
    class="modal fade"
    id="exampleModaltwo"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header pt-sm-1 px-lg-5 d-block">
          <div>
            {" "}
            <h1 class="modal-title " id="exampleModaltwo">
              Marketing legend
            </h1>
          </div>
          <div className="pt-lg-3">
            <div className="card p-lg-5">
              <img src={samplegif} className="card-gif"></img>
            </div>
          </div>
        </div>
        <div class="modal-body mt-auto px-lg-5">
          <div className="text-one pt-2 d-flex">
            <div className="icon-one">
              <GoDotFill className="fs-1 mt-0" />
            </div>
            <span className="ms-2">
              <span className="text-two">Direct partner </span>- direct
              partner who took place in your level
            </span>
          </div>
          <div className="text-one pt-2 d-flex">
            <div className="icon-two">
              <GoDotFill className="fs-1 mt-0" />
            </div>
            <span className="ms-2">
              <span className="text-two">Spillover from above </span>- a
              partner of your upline took place in your level
            </span>
          </div>
          <div className="text-one pt-2 d-flex">
            <div className="icon-three">
              <GoDotFill className="fs-1 mt-0" />
            </div>
            <span className="ms-2">
              <span className="text-two">Spillover from below</span>- a
              partner of your downline took place in your level
            </span>
          </div>
          <div className="text-one pt-2 d-flex">
            <div className="icon-four">
              <GoDotFill className="fs-1 mt-0" />
            </div>
            <span className="ms-2">
              <span className="text-two">Gift </span>- a partner of your
              downline who made upgrade earlier than your downline, so he
              took place in your level instead
            </span>
          </div>
          <div className="text-one pt-2 d-flex">
            <div className="icon-five ms-lg-1">
              <FiUsers className="fs-5 mt-0" />
            </div>
            <span className="ms-2 ms-lg-4">
              <span className="text-two">Partners on level</span>- total
              amount of partners who took places in the level
            </span>
          </div>
          <div className="text-one pt-2 d-flex">
            <div className="text-white ms-lg-1">
              <HiArrowPath className="fs-5 mt-0 text-white" />
            </div>
            <span className="ms-2 ms-lg-4">
              <span className="text-two">Level Cycle </span>- amount of
              times the level got fully filled with partners and was
              automatically restarted
            </span>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn text-white py-2"
            data-bs-dismiss="modal"
          >
            Close tip
          </button>
        </div>
      </div>
    </div>
    <button
      type="button"
      class="btn-close text-white"
      data-bs-dismiss="modal"
      aria-label="Close"
    >
      <AiOutlineClose className="d-lg-block d-none fs-4" />
    </button>
  </div>
</section>
</div>

);
}

export default Metakingxcore;