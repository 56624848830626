import "../assets/css/dashboard-style.css"

import Topnav from "../Dashboard-pages/Separate/Top-nav";
import Sidenav from "../Dashboard-pages/Separate/Side-nav";

import finalgif from "../assets/images/final.gif";
import config from "../config/apiConfig";
import { useEffect, useState, } from "react";
import copy from 'copy-to-clipboard';
import { ToastContainer, toast } from "react-toastify";
import { xcoreRequestCycle } from "../xcorecycle/xcore-inner-cycle";
import Web3 from "web3";
import xcoreABI from "../ABI/xcore.json";
import {    getUserDataAPI  } from "./api/authapis";
import { useLocation } from "react-router-dom";

function Links() {
    const [id, setId] = useState('')
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userid = queryParams.get("userid");

    const [findTeams, setFindTeams] = useState(0)
    const [findPartner, setFindPartner] = useState(0)
    const USERADDRESS = localStorage.getItem("address");
    const [ratio, setRatio] = useState()
    const [userDetailsFunctionAllow, setUserDetailsFunctionAllow] = useState(false);
  
    const [primaryRef1contractData, setprimaryRef1contractData] = useState([]);
    const [SecondaryRef1contractData, setSecondaryRef1contractData] = useState(
      []
    );
    const [primaryRef2contractData, setprimaryRef2contractData] = useState([]);
    const [SecondaryRef2contractData, setSecondaryRef2contractData] = useState(
      []
    );
  
  
    const [primaryRef3contractData, setprimaryRef3contractData] = useState([]);
    const [SecondaryRef3contractData, setSecondaryRef3contractData] = useState(
      []
    );
  
    const [primaryRef4contractData, setprimaryRef4contractData] = useState([]);
    const [SecondaryRef4contractData, setSecondaryRef4contractData] = useState(
      []
    );
  
    const [primaryRef5contractData, setprimaryRef5contractData] = useState([]);
    const [SecondaryRef5contractData, setSecondaryRef5contractData] = useState(
      []
    );
  
    const [primaryRef6contractData, setprimaryRef6contractData] = useState([]);
    const [SecondaryRef6contractData, setSecondaryRef6contractData] = useState(
      []
    );
  
    const [primaryRef7contractData, setprimaryRef7contractData] = useState([]);
    const [SecondaryRef7contractData, setSecondaryRef7contractData] = useState(
      []
    );
  
    const [primaryRef8contractData, setprimaryRef8contractData] = useState([]);
    const [SecondaryRef8contractData, setSecondaryRef8contractData] = useState(
      []
    );
  
    const [primaryRef9contractData, setprimaryRef9contractData] = useState([]);
    const [SecondaryRef9contractData, setSecondaryRef9contractData] = useState(
      []
    );
  
    const [primaryRef10contractData, setprimaryRef10contractData] = useState([]);
    const [SecondaryRef10contractData, setSecondaryRef10contractData] = useState(
      []
    );
    useEffect(() => {
        const setaadd = async () => {
          let web3 = new Web3(config.rpcUrls);
          let xcorecontract = await new web3.eth.Contract(
            xcoreABI,
            config.xcorecontract
          );
          if (userid == null) {
            let xcores1 = await xcoreRequestCycle({
              useradd: USERADDRESS,
              lvls: 1,
              usrlevels: 1,
            });
            setSecondaryRef1contractData(xcores1.usrnewcy2);
            setprimaryRef1contractData(xcores1.usrnewcy1);
    
    
            let xcores2 = await xcoreRequestCycle({
              useradd: USERADDRESS,
              lvls: 1,
              usrlevels: 2,
            });
    
            setSecondaryRef2contractData(xcores2.usrnewcy2);
            setprimaryRef2contractData(xcores2.usrnewcy1);
    
            let xcores3 = await xcoreRequestCycle({
              useradd: USERADDRESS,
              lvls: 1,
              usrlevels: 3,
            });
    
            setSecondaryRef3contractData(xcores3.usrnewcy2);
            setprimaryRef3contractData(xcores3.usrnewcy1);
    
            let xcores4 = await xcoreRequestCycle({
              useradd: USERADDRESS,
              lvls: 1,
              usrlevels: 4,
            });
    
    
            setSecondaryRef4contractData(xcores4.usrnewcy2);
            setprimaryRef4contractData(xcores4.usrnewcy1);
    
            let xcores5 = await xcoreRequestCycle({
              useradd: USERADDRESS,
              lvls: 1,
              usrlevels: 5,
            });
    
            setSecondaryRef5contractData(xcores5.usrnewcy2);
            setprimaryRef5contractData(xcores5.usrnewcy1);
    
            let xcores6 = await xcoreRequestCycle({
              useradd: USERADDRESS,
              lvls: 1,
              usrlevels: 6,
            });
    
            setSecondaryRef6contractData(xcores6.usrnewcy2);
            setprimaryRef6contractData(xcores6.usrnewcy1);
    
            let xcores7 = await xcoreRequestCycle({
              useradd: USERADDRESS,
              lvls: 1,
              usrlevels: 7,
            });
    
            setSecondaryRef7contractData(xcores7.usrnewcy2);
            setprimaryRef7contractData(xcores7.usrnewcy1);
    
            let xcores8 = await xcoreRequestCycle({
              useradd: USERADDRESS,
              lvls: 1,
              usrlevels: 8,
            });
    
            setSecondaryRef8contractData(xcores8.usrnewcy2);
            setprimaryRef8contractData(xcores8.usrnewcy1);
    
            let xcores9 = await xcoreRequestCycle({
              useradd: USERADDRESS,
              lvls: 1,
              usrlevels: 9,
            });
    
            setSecondaryRef9contractData(xcores9.usrnewcy2);
            setprimaryRef9contractData(xcores9.usrnewcy1);
    
            let xcores10 = await xcoreRequestCycle({
              useradd: USERADDRESS,
              lvls: 1,
              usrlevels: 10,
            });
    
            setSecondaryRef10contractData(xcores10.usrnewcy2);
            setprimaryRef10contractData(xcores10.usrnewcy1);
          } else {
            let userData = await getUserDataAPI(userid);
            partnerFind(userData.data.address);
            if (userData.status) {
              let usrlvl2 = await xcorecontract.methods
                .usercompletelevel(userData.data.address, 2)
                .call();
              let usrlvl3 = await xcorecontract.methods
                .usercompletelevel(userData.data.address, 3)
                .call();
              let usrlvl4 = await xcorecontract.methods
                .usercompletelevel(userData.data.address, 4)
                .call();
              let usrlvl5 = await xcorecontract.methods
                .usercompletelevel(userData.data.address, 5)
                .call();
              let usrlvl6 = await xcorecontract.methods
                .usercompletelevel(userData.data.address, 6)
                .call();
              let usrlvl7 = await xcorecontract.methods
                .usercompletelevel(userData.data.address, 7)
                .call();
              let usrlvl8 = await xcorecontract.methods
                .usercompletelevel(userData.data.address, 8)
                .call();
              let usrlvl9 = await xcorecontract.methods
                .usercompletelevel(userData.data.address, 9)
                .call();
              let usrlvl10 = await xcorecontract.methods
                .usercompletelevel(userData.data.address, 10)
                .call();
            
              // setAddressstate(userData.data.address);
              let xcores1 = await xcoreRequestCycle({
                useradd: userData.data.address,
                lvls: 1,
                usrlevels: 1,
              });
    
    
              setSecondaryRef1contractData(xcores1.usrnewcy2);
              setprimaryRef1contractData(xcores1.usrnewcy1);
    
              let xcores2 = await xcoreRequestCycle({
                useradd: userData.data.address,
                lvls: 1,
                usrlevels: 2,
              });
    
              setSecondaryRef2contractData(xcores2.usrnewcy2);
              setprimaryRef2contractData(xcores2.usrnewcy1);
    
              let xcores3 = await xcoreRequestCycle({
                useradd: userData.data.address,
                lvls: 1,
                usrlevels: 3,
              });
    
              setSecondaryRef3contractData(xcores3.usrnewcy2);
              setprimaryRef3contractData(xcores3.usrnewcy1);
    
              let xcores4 = await xcoreRequestCycle({
                useradd: userData.data.address,
                lvls: 1,
                usrlevels: 4,
              });
    
              setSecondaryRef4contractData(xcores4.usrnewcy2);
              setprimaryRef4contractData(xcores4.usrnewcy1);
    
              let xcores5 = await xcoreRequestCycle({
                useradd: userData.data.address,
                lvls: 1,
                usrlevels: 5,
              });
    
              setSecondaryRef5contractData(xcores5.usrnewcy2);
              setprimaryRef5contractData(xcores5.usrnewcy1);
    
              let xcores6 = await xcoreRequestCycle({
                useradd: userData.data.address,
                lvls: 1,
                usrlevels: 6,
              });
              setSecondaryRef6contractData(xcores6.usrnewcy2);
              setprimaryRef6contractData(xcores6.usrnewcy1);
    
              let xcores7 = await xcoreRequestCycle({
                useradd: userData.data.address,
                lvls: 1,
                usrlevels: 7,
              });
              setSecondaryRef7contractData(xcores7.usrnewcy2);
              setprimaryRef7contractData(xcores7.usrnewcy1);
    
              let xcores8 = await xcoreRequestCycle({
                useradd: userData.data.address,
                lvls: 1,
                usrlevels: 8,
              });
    
              setSecondaryRef8contractData(xcores8.usrnewcy2);
              setprimaryRef8contractData(xcores8.usrnewcy1);
    
              let xcores9 = await xcoreRequestCycle({
                useradd: userData.data.address,
                lvls: 1,
                usrlevels: 9,
              });
    
              setSecondaryRef9contractData(xcores9.usrnewcy2);
              setprimaryRef9contractData(xcores9.usrnewcy1);
    
              let xcores10 = await xcoreRequestCycle({
                useradd: userData.data.address,
                lvls: 1,
                usrlevels: 10,
              });
    
              setSecondaryRef10contractData(xcores10.usrnewcy2);
              setprimaryRef10contractData(xcores10.usrnewcy1);
    
            }
          }
        };
    
        setaadd();
      }, [userid]);
      const partnerFind = async (address) => {

        let web3 = new Web3(config.rpcUrls);
        let xcorecontract = await new web3.eth.Contract(
          xcoreABI,
          config.xcorecontract
        );
        const directPartner = await xcorecontract.methods.directreferral(address).call();
        setFindPartner(directPartner.length)
      }
      const details1 = [
        [...primaryRef1contractData], [...SecondaryRef1contractData],
        [...primaryRef2contractData], [...SecondaryRef2contractData],
        [...primaryRef3contractData], [...SecondaryRef3contractData],
        [...primaryRef4contractData], [...SecondaryRef4contractData],
        [...primaryRef5contractData], [...SecondaryRef5contractData],
        [...primaryRef6contractData], [...SecondaryRef6contractData],
        [...primaryRef7contractData], [...SecondaryRef7contractData],
        [...primaryRef8contractData], [...SecondaryRef8contractData],
        [...primaryRef9contractData], [...SecondaryRef9contractData],
        [...primaryRef10contractData], [...SecondaryRef10contractData],
      ]
      const userAllDetails = async (details1) => {
        let userDetailsGetTeam = []
        if (primaryRef1contractData[0] !== "0") {
          const details2 = details1.flat(1)
          // console.log("22222primaryRef2contractData=======", details2);
          const details3 = details2.map((data) => data)
          details3.map((element) => {
            if (Array.isArray(element)) {
              userDetailsGetTeam.push(element[1])    
            } else {
              return [element];
            }
          }
          );
          setFindTeams(userDetailsGetTeam.length)
          setUserDetailsFunctionAllow(false)
        }
        setUserDetailsFunctionAllow(false)
      }
      SecondaryRef10contractData.map((data) => {
        if (userDetailsFunctionAllow === true && data >= 0) {
          // console.log("work",details1);
          userAllDetails(details1)
        }
      })

    const userId = () => {
        const token = localStorage.getItem("userToken")
        if (token) {
          const userid = localStorage.getItem("userID")
          setId(userid)
        } else {
          console.log("Error")
        }
      }

      const copyToClipboard = () => {
        const link = config.FRONTEND_URL + `registration/${id}`
        copy(link);
        toast.success("Copy to clipboard");
      }

      useEffect(() => {
        userId();
        setUserDetailsFunctionAllow(true)
      }, [])

    return (
        <div className="App">
            <Topnav />
            <ToastContainer/>
            <section className="dashboard-split-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 dashboard-split-left-section d-lg-block d-none">
                            <Sidenav />
                        </div>
                        <div className="col-lg-10 dashboard-split-right-section">
                            <div className="row p-2 g-4">
                                <div className="col-lg-6">
                                    <div className="card h-100 links-card-2">
                                        <div className="d-flex flex-row gap-1 mt-1 align-items-center">
                                            <div>
                                                <span className="dashboard-text-4 mb-0 ms-2">New partners by</span>
                                                <select className="link-page-select">
                                                    <option value="volvo">month</option>
                                                    <option value="saab">year</option>
                                                </select>
                                            </div>
                                            <div className="ms-auto">
                                                <button className="ms-3 dashboard-button-1">July</button>
                                            </div>
                                            <div className="">
                                                <button className="ms-3 dashboard-button-1">2023</button>
                                            </div>
                                        </div>
                                        <ul className="chart">
                                            <li>
                                                <p className="text-center mb-0">4</p>
                                                <span style={{ height: "20%" }}></span>
                                            </li>
                                            <li>
                                                <p className="text-center mb-0">2</p>
                                                <span style={{ height: "42%" }}></span>
                                            </li>
                                            <li>
                                                <p className="text-center mb-0">8</p>
                                                <span style={{ height: "42%" }}></span>
                                            </li>
                                            <li>
                                                <p className="text-center mb-0">9</p>
                                                <span style={{ height: "42%" }}></span>
                                            </li>
                                            <li>
                                                <p className="text-center mb-0">4</p>
                                                <span style={{ height: "20%;" }}></span>
                                            </li>
                                            <li>
                                                <p className="text-center mb-0">2</p>
                                                <span style={{ height: "42%" }}></span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card bg-transparent h-100">
                                        <div className="row g-4">
                                            <div className="col-lg-4">
                                                <div className="card dashboard-card-2">
                                                    <p className="dashboard-text-4 mb-0 ms-2">Link clicks</p>
                                                    <p className="mb-2 dashboard-text-2 ms-2">47847</p>
                                                    <div className="d-flex flex-row dashboard-card-2-inner-1 align-items-center">
                                                        <div className="dashboard-text-7">
                                                            <i className="fa-sharp fa-solid fa-arrow-up me-1"></i>0
                                                        </div>
                                                        <div className="ms-auto">
                                                            <img className="dashboard-partners-gif" src={finalgif}
                                                                alt="gif" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="card dashboard-card-2">
                                                    <p className="dashboard-text-4 mb-0 ms-2">Partners</p>
                                                    <p className="mb-2 dashboard-text-2 ms-2">{findPartner > 0?findPartner:0}</p>
                                                    <div className="d-flex flex-row dashboard-card-2-inner-1 align-items-center">
                                                        <div className="dashboard-text-7">
                                                            <i className="fa-sharp fa-solid fa-arrow-up me-1"></i>0
                                                        </div>
                                                        <div className="ms-auto">
                                                            <img className="dashboard-partners-gif" src={finalgif}
                                                                alt="gif" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="card dashboard-card-2">
                                                    <p className="dashboard-text-4 mb-0 ms-2">Team</p>
                                                    <p className="mb-2 dashboard-text-2 ms-2">{findTeams > 0?findTeams:0}</p>
                                                    <div className="d-flex flex-row dashboard-card-2-inner-1 align-items-center">
                                                        <div className="dashboard-text-7">
                                                            <i className="fa-sharp fa-solid fa-arrow-up me-1"></i>0
                                                        </div>
                                                        <div className="ms-auto">
                                                            <img className="dashboard-partners-gif" src={finalgif}
                                                                alt="gif" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-lg-12">
                                                <div className="card dashboard-card-2">
                                                    <div className="row">
                                                        <div className="col-lg-5">
                                                            <p className="dashboard-text-4 mb-0 ms-2">Link clicks</p>
                                                            <p className="mb-2 dashboard-text-2 ms-2">47847</p>
                                                            <div
                                                                className="d-flex flex-row dashboard-card-2-inner-1 align-items-center">
                                                                <div className="dashboard-text-7">
                                                                    <i className="fa-sharp fa-solid fa-arrow-up me-1"></i>0
                                                                </div>
                                                                <div className="ms-auto">
                                                                    <img className="dashboard-partners-gif"
                                                                        src={finalgif} alt="gif" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-7">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row p-2 g-4 mt-4">
                                <div className="col-lg-12">
                                    <div className="card links-card-1">
                                        <p className="dashboard-text-4 mb-2">Personal link <span className="ms-1 "><i
                                            className="fa-solid fa-circle-question" data-bs-toggle="tooltip"
                                            data-bs-placement="top" data-bs-custom-className="custom-tooltip"
                                            data-bs-title="This top tooltip is themed via CSS variables."></i></span>
                                        </p>
                                        <div className="d-flex flex-lg-row flex-column align-items-center">
                                            <div>
                                                <p className="link-text-1 mb-0">{config.FRONTEND_URL + `registration/${id}`}</p>
                                            </div>
                                            <div className="ms-lg-auto">
                                                <button className="dashboard-preview-btn-2 mt-1" onClick={copyToClipboard} >Copy <i
                                                    className="fa-solid fa-copy ms-2"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="card links-card-1" style={{ backgroundcolor: "rgba(255, 236, 64, 0.1)" }}>
                                        <p className="dashboard-text-4 mb-2">Personal link <span
                                            style={{ color: "#ffc600" }}>xCore</span><span className="ms-1 "><i
                                                className="fa-solid fa-circle-question" data-bs-toggle="tooltip"
                                                data-bs-placement="top" data-bs-custom-className="custom-tooltip"
                                                data-bs-title="This top tooltip is themed via CSS variables."></i></span>
                                        </p>
                                        <div className="d-flex flex-lg-row flex-column align-items-center">
                                            <div>
                                                <p className="link-text-1 mb-0">meta-king.io/xqg1z8</p>
                                            </div>
                                            <div className="ms-lg-auto">
                                                <button className="dashboard-preview-btn-2 mt-1">Copy <i
                                                    className="fa-solid fa-copy ms-2"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row p-2 g-4 mt-2">
                                <div className="col-lg-12">
                                    <span className="dashboard-text-2">Custom links</span>
                                    <p className="mb-0 dashboard-text-4 mt-2">Promote different teams by using cutom links, Create
                                        and set up custom links for your team. Include as many participants in your link as you
                                        want and set up how the new registrations will be distributed within the group.
                                    </p>
                                </div>
                            </div>
                            <div className="row p-2 g-4 mt-2">
                                <div className="col-lg-6">
                                    <div className="card links-card-2">
                                        <span className="dashboard-text-9 mb-4">Custom link #1</span>
                                        <div className="dashboard-card-1-inner-3 d-flex flex-lg-row flex-column gap-2 align-items-center mt-2"
                                            style={{ borderradius: "56px" }}>
                                            <div>
                                                <p className="mb-0 dashboard-text-6">Metaking.io/br/c4pk5kx4c</p>
                                            </div>
                                            <div className="ms-lg-auto">
                                                <button className="ms-3 dashboard-button-1">copy</button>
                                            </div>
                                        </div>

                                        <div className="row g-4 mt-2">
                                            <div className="col-lg-4">
                                                <div className="card links-card-2-inner-1">
                                                    <p className="dashboard-text-4 mb-0 ms-2">Link clicks</p>
                                                    <p className="mb-2 dashboard-text-9 ms-2">47847</p>
                                                    <div className="dashboard-text-7 ms-2 mt-3 mb-2">
                                                        <i className="fa-sharp fa-solid fa-arrow-up me-1"></i>0
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="card links-card-2-inner-1">
                                                    <p className="dashboard-text-4 mb-0 ms-2">Partners</p>
                                                    <p className="mb-2 dashboard-text-9 ms-2">{findPartner > 0?findPartner:0}</p>
                                                    <div className="dashboard-text-7 ms-2 mt-3 mb-2">
                                                        <i className="fa-sharp fa-solid fa-arrow-up me-1"></i>0
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="card links-card-2-inner-1">
                                                    <p className="dashboard-text-4 mb-0 ms-2">Team</p>
                                                    <p className="mb-2 dashboard-text-9 ms-2">{findTeams > 0?findTeams:0}</p>
                                                    <div className="dashboard-text-7 ms-2 mt-3 mb-2">
                                                        <i className="fa-sharp fa-solid fa-arrow-up me-1"></i>0
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-lg-row flex-column mt-4">
                                            <div>
                                                <p className="mb-0 dashboard-text-6"><span
                                                    className="mb-0 dashboard-text-4">Owner</span>
                                                    ID 1</p>
                                            </div>
                                            <div className="ms-auto">
                                                <p className="mb-0 dashboard-text-6"><span
                                                    className="mb-0 dashboard-text-4">Participants</span>
                                                    1</p>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </div >
    );
}

export default Links;