import React, { useState, useMemo, useEffect } from 'react';
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import "./Editor.css"

function CreateFaq() {
	const [logs, setLogs] = useState([]);

	const [show, setShow] = useState(false);
	const [list, setList] = useState([]);
	const [listDetails, setListDetails] = useState({
		_id: '',
		type: 'common',
		title: '',
		description: '',
	});

	useEffect(() => {
		find();
	}, []);

	// find all details
	const find = async () => {
		try {
			const response = await axios.get(`${config.BACKEND_URL}faq/faq-get`);
			const value = response.data.list;
			setList(value);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const handleClose = () => {
		setShow(false);
	};

	// create or update
	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			if(listDetails.id){
				const response = await axios.post(`${config.BACKEND_URL}faq/faq-update`, {
					id: listDetails.id,
					type: listDetails.type,
					title: listDetails.title,
					description: listDetails.description
				});
				console.log('updateid==', listDetails.id);
				console.log('update', response);
				setListDetails({
					_id: '',
					type: 'common',
					title: '',
					description: '',
				}); 
				setShow(true); 
			}else{
			console.log('create===', listDetails);
			console.log('create');
			await axios.post(`${config.BACKEND_URL}faq/faq-create`, listDetails);
			handleClose();
			find();
			}
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const handleUpdate = async (id) => {
		try {

		} catch (error) {
			console.error('Error:', error);
		}
	};
	

	const handleChange = (event) => {
		setListDetails((prevProps) => ({ ...prevProps, [event.target.name]: event.target.value }));
	};

	const config = useMemo(
		() => ({
			readOnly: false,
		}),
		[]
	);

	const onEditorChange = (event, editor) => {
		const data = editor.getData();
		setListDetails((prevProps) => ({ ...prevProps, description: data }));
	};

	return (
		<div>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>{listDetails._id ? 'Edit FAQ' : 'Add FAQ'}</Modal.Title>
				</Modal.Header>
				<Form onSubmit={handleSubmit}>
					<Modal.Body>
						<Form.Label>Type</Form.Label>
						<Form.Select
							aria-label="Default select example"
							value={listDetails.type}
							name="type"
							onChange={(e) => handleChange(e)}
						>
							<option></option>
							<option value="common">Common</option>
						</Form.Select>
						<Form.Group className="mb-3" controlId="formBasicPassword">
							<Form.Label>Title</Form.Label>
							<Form.Control
								value={listDetails.title}
								type="text"
								placeholder="Title"
								name="title"
								onChange={(e) => handleChange(e)}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
							<Form.Label>Description</Form.Label>
							<CKEditor
								editor={ClassicEditor}
								config={config}
								data={listDetails.description}
								onChange={onEditorChange}
							/>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Close
						</Button>
						<Button variant="primary" type="submit">
							Submit
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>

			<Button variant="info" style={{ marginLeft: '1200px' }} onClick={() => { setShow(true); setListDetails({}) }}>
				Add FAQ
			</Button>
			<MDBTable bordered striped>
				<MDBTableHead>
					<tr>
						<th scope="col" w-25>#</th>
						<th scope="col" w-25>Type</th>
						<th scope="col" w-25>Title</th>
						<th scope="col" w-25>Description</th>
						<th scope="col" w-25>Action</th>
					</tr>
				</MDBTableHead>
				<MDBTableBody>
					{Array.isArray(list) ? (
						list.map((data, index) => (
							<tr key={index}>
								<td>{index + 1}</td>
								<td>{data.type}</td>
								<td style={{ width: '100px' }}>{data.title}</td>
								<td style={{ width: '500px' }} dangerouslySetInnerHTML={{ __html: data.description }}></td>
								<td>
									<Button onClick={() => handleUpdate(data._id)}>Edit</Button>

								</td>
							</tr>
						))
					) : (
						<td>Loading</td>
					)}
				</MDBTableBody>
			</MDBTable>
		</div>
	);
}

export default CreateFaq;
