import "../../assets/css/dashboard-style.css";

import { AiOutlineClose } from "react-icons/ai";
import { FaGripLines } from "react-icons/fa";
import Metalogo from "../../assets/images/images-dash/meta-king-logo.png";
import Web3 from "web3";
import config from "../../config/apiConfig";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Topnav() {
 
  const navigate = useNavigate()
  const [currentAccount, setCurrentAccount] = useState("");
  const [logoimage, setLogoimage] = useState('')

  const logoimageset = async () => {
    try {
      const response = await axios.get(config.BACKEND_URL + 'admin/getimage'); // Update with your API endpoint
      // console.log('response.data---', response.data);
      if (response.data && response.data.length > 0) {
        setLogoimage(response.data[0].logoimage); // Assuming you want to display the first image
        // setOldImageId(response.data[0]._id)
      }
    } catch (error) {
      console.error('Error fetching old image:', error);
    }
  }
  const ConnectToMetaMask = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: config.chainId }],
      });
      // Check if MetaMask is available
      if (typeof window.ethereum !== "undefined") {
        const web3 = new Web3(window.ethereum);

        // Request account access if needed
        window.ethereum
          .request({ method: "eth_requestAccounts" })
          .then((accounts) => {
            // Set the initial account
            const address = accounts[0];
            if (localStorage.getItem("address") === address) {
              setCurrentAccount(
                address.substring(0, 5) +
                  "..." +
                  address.substring(address.length - 4)
              );
            } else {
              setCurrentAccount(
                address.substring(0, 5) +
                  "..." +
                  address.substring(address.length - 4)
              );
              localStorage.setItem("address", address);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        console.error("Metamask not found");
      }
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [
              {
                chainId: config.chainId,
                chainName: "BNB Smart Chain Testnet",
                rpcUrls: [config.rpcUrls] /* ... */,
              },
            ],
          });
          // Check if MetaMask is available
          if (typeof window.ethereum !== "undefined") {
            const web3 = new Web3(window.ethereum);

            // Request account access if needed
            window.ethereum
              .request({ method: "eth_requestAccounts" })
              .then((accounts) => {
                // Set the initial account
                const address = accounts[0];
                if (localStorage.getItem("address") === address) {
                  setCurrentAccount(
                    address.substring(0, 5) +
                      "..." +
                      address.substring(address.length - 4)
                  );
                } else {
                  setCurrentAccount(
                    address.substring(0, 5) +
                      "..." +
                      address.substring(address.length - 4)
                  );
                  localStorage.setItem("address", address);
                }
              })
              .catch((error) => {
                console.error(error);
              });
          } else {
            console.error("Metamask not found");
          }
        } catch (addError) {
          // handle "add" error
          console.log(addError);
        }
      }
      // handle other "switch" errors
    }
  };

  useEffect(() => {
    // When the component mounts, check if there is an address in localStorage and update the currentAccount state.
    let Address = localStorage.getItem("address");
    if (Address) {
      setCurrentAccount(
        Address.substring(0, 5) + "..." + Address.substring(Address.length - 4)
      );
    }
    logoimageset()
  }, []);
if(window.ethereum){
  window.ethereum.on("accountsChanged", (newAccounts) => {
    const newAddress = newAccounts[0];
    setCurrentAccount(
      newAddress.substring(0, 5) +
        "..." +
        newAddress.substring(newAddress.length - 4)
    );
    localStorage.setItem('address', newAddress)
    localStorage.removeItem('userID');
    localStorage.removeItem('userToken');
    navigate('/');
  });
}

  // window.ethereum.on('disconnect',() => {
  //   console.log("act has been disconnect");
  // })


  const logOut = () => {
    localStorage.clear();
    setCurrentAccount('')
    navigate('/login')
  }

  return (
    <div>
      <section className="dashboard-navbar-section">
        <nav className="navbar navbar-expand-lg fixed-top">
          <div className="container">
            <a className="navbar-brand-dashboard" href="/">
            <img  src={logoimage} alt="logo" />
              {/* <img src={Metalogo} alt="logo" /> */}
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              {/* <!-- <span className="navbar-toggler-icon"></span> --> */}
              <FaGripLines />
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              {/* <ul className="navbar-nav me-auto ms-2">
                <li className="nav-item">
                  <p className="nav-link mb-0 dashboard-text-1">Preview ID</p>
                </li>
                <li className="nav-item">
                  <form className="d-flex" role="search">
                    <input
                      className="form-control me-2 navbar-preview-id-search"
                      type="search"
                      aria-label="Search"
                    />
                    <button
                      className="btn btn-dashboard-preview-search"
                      type="submit"
                    >
                      GO
                    </button>
                  </form>
                </li>
              </ul> */}
              <ul className="navbar-nav ms-auto me-lg-4">
                {/* <li className="nav-item me-lg-3">
                  <button className="nav-link dashboard-connect-wallet-btn">
                    Connect Wallet
                  </button>
                </li> */}
                <li className="nav-item me-lg-3">
                  {currentAccount ? (
                    <button className="nav-link dashboard-connect-wallet-btn" type="submit">
                      {currentAccount}
                    </button>
                  ) : (
                    <button
                      className="nav-link dashboard-connect-wallet-btn"
                      type="submit"
                      onClick={() => ConnectToMetaMask()}
                    >
                      Connect Wallet
                    </button>
                  )}
                </li>
                <li className="nav-item">
                  <button className="nav-link dashboard-close-btn" onClick={logOut}>
                    <AiOutlineClose className="d-lg-block d-none" />
                    <span className="d-lg-none d-block">Exit Preview Node</span>
                  </button>
                </li>
              </ul>

              <ul className="navbar-nav ms-auto d-lg-none d-block mt-3">
                <div className="col-lg-2 dashboard-split-left-section">
                  <ul className="navbar-nav">
                    <li className="sidenavbar-nav-link sidenavbar-active">
                      <a className="" href="/dashboard">
                        <div className="d-flex flex-row align-items-center">
                          <div className="sidenav-icon-1">
                            <i className="bi bi-grid-1x2-fill"></i>
                          </div>
                          <div>
                            <span className="sidenav-text-1">Dashboard</span>
                          </div>
                        </div>
                      </a>
                    </li>

                    <li className="sidenavbar-nav-link-2">
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button accordion-button-2"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="false"
                              aria-controls="collapseOne"
                            >
                              <div className="d-flex flex-row align-items-center">
                                <div className="sidenav-icon-1">
                                  <i className="fa-sharp fa-solid fa-people-group"></i>
                                </div>
                                <div>
                                  <span className="sidenav-text-1">Team</span>
                                </div>
                              </div>
                            </button>
                          </h2>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <ul className="navbar-nav">
                                <li className="sidenavbar-nav-link-2">
                                  <a className="" href="/partners">
                                    <div className="d-flex flex-row align-items-center">
                                      <div className="sidenav-icon-1">
                                        <i className="fa-solid fa-user-group"></i>
                                      </div>
                                      <div>
                                        <span className="sidenav-text-1">
                                          Partners
                                        </span>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                                <li className="sidenavbar-nav-link-2">
                                <a className="" href='/links'>
                                    <div className="d-flex flex-row align-items-center">
                                      <div className="sidenav-icon-1">
                                        <i className="fa-solid fa-link"></i>
                                      </div>
                                      <div>
                                        <span className="sidenav-text-1">
                                          Links
                                        </span>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                                <li className="sidenavbar-nav-link-2">
                                  <a className="" href="/stats">
                                    <div className="d-flex flex-row align-items-center">
                                      <div className="sidenav-icon-1">
                                        <i className="fa-brands fa-nfc-directional"></i>
                                      </div>
                                      <div>
                                        <span className="sidenav-text-1">
                                          Stats
                                        </span>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="sidenavbar-nav-link-2">
                      <div className="accordion" id="accordionExample">
                        <div className="accordion-item bg-transparent">
                          <h2 className="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button collapsed accordion-button-2"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              <div className="d-flex flex-row align-items-center">
                                <div className="sidenav-icon-1">
                                  <i className="fa-solid fa-book-open"></i>
                                </div>
                                <div>
                                  <span className="sidenav-text-1">
                                    Information
                                  </span>
                                </div>
                              </div>
                            </button>
                          </h2>
                          <div
                            id="collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <ul className="navbar-nav">
                                <li className="sidenavbar-nav-link-2">
                                  <a className="" href="/metakinginstructions">
                                    <div className="d-flex flex-row align-items-center">
                                      <div className="sidenav-icon-1">
                                        <i className="fa-solid fa-shield-halved"></i>
                                      </div>
                                      <div>
                                        <span className="sidenav-text-1">
                                          Instruction
                                        </span>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                                <li className="sidenavbar-nav-link-2">
                                  <a className="" href="/metakingcalculator">
                                    <div className="d-flex flex-row align-items-center">
                                      <div className="sidenav-icon-1">
                                        <i className="fa-solid fa-calculator"></i>
                                      </div>
                                      <div>
                                        <span className="sidenav-text-1">
                                          Calculator
                                        </span>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </ul>
            </div>
          </div>
        </nav>
      </section>
    </div>
  );
}

export default Topnav;
