import "../assets/css/dashboard-style.css"

import Topnav from "../Dashboard-pages/Separate/Top-nav";
import Sidenav from "../Dashboard-pages/Separate/Side-nav";
import $ from 'jquery';




function Metakingcalculator() {
    
    var jq = $.noConflict();

jq(document).ready(function () {
    jq(".thirdbtn1").click(function () {
        jq(this).addClass("getting");
        jq(this).prevAll().addClass("getting");
        jq(this).nextAll().removeClass("getting");
    });

    jq(".thirdbtn0").click(function () {
        jq(this).addClass("getting1");
        jq(this).prevAll().addClass("getting1");
        jq(this).nextAll().removeClass("getting1");
    })
});

        
   
    return (
        <div className="App">
            <Topnav />
            <section className="dashboard-split-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 dashboard-split-left-section d-lg-block d-none">
                            <Sidenav />
                        </div>
                        <div className="col-lg-10 dashboard-split-right-section dashboard-calculator-section">

                            {/* <!-- <----------section1-----------> */}
                            <div className="row text-center">
                                <div className="col-lg-12 pt-5 dashbanner1">
                                    <h4>Metaking Participant</h4>
                                    <h3 className="text-white">calculator</h3>
                                    <p className="pt-3 opacity-50 text-white">Calculate your potential result from participating in Metaking by selecting levels to activate<br /> below. The results are calculated for 1 cycle of all selected levels.<br />
                                        All calculations are for informational purposes only, and are not a public offer.</p>
                                </div>
                            </div>
                            {/* <!-- <----------------end-------------->
                                <!-- <---------------section2----------------> */}
                            <div className="row mt-5 bg-dark p-5 mx-auto dashrounding">
                                <div className="col-lg-12">

                                    <div className="row d-flex align-items-center ">
                                        <div className="col-lg-4 col-md-4 col-12 d-flex align-items-center  dashbanner2">
                                            <h2 className="logo px-2 py-3 mt-2">xXx</h2>
                                            <h2 className="ps-3">Metaking</h2>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12 px-3">
                                            <p className="dashbanner20 opacity-50 text-white">Program with enhanced team building and development opportunities.</p>
                                        </div>
                                    </div>

                                    <div className="row mt-5 d-flex align-items-center gy-3">
                                        <div className="col-lg-5 col-md-5 col-12">
                                            <div className="calculatingtab">
                                                <button className="thirdbtn0 me-2 mt-3" id="unique20">1</button>
                                                <button className="thirdbtn0  me-2 mt-3" id="unique21">2</button>
                                                <button className="thirdbtn0  me-2 mt-3" id="unique22">3</button>
                                                <button className="thirdbtn0  me-2 mt-3" id="unique23">4</button>
                                                <button className="thirdbtn0  me-2 mt-3" id="unique24">5</button>
                                                <button className="thirdbtn0  me-2 mt-3" id="unique25">6</button>
                                                <button className="thirdbtn0  me-2 mt-3" id="unique26">7</button>
                                                <button className="thirdbtn0  me-2 mt-3" id="unique27">8</button>
                                                <button className="thirdbtn0  me-2 mt-3" id="unique28">9</button>
                                                <button className="thirdbtn0  me-2 mt-3" id="unique29">10</button>
                                                <button className="thirdbtn0  me-2 mt-3" id="unique30">11</button>
                                                <button className="thirdbtn0  me-2 mt-3" id="unique31">12</button>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12 ">
                                            <div className="dashbannerchild1">
                                                <p className="m-auto opacity-50 text-white">Cost of all selected slots</p>
                                                <h5 className="fw-bold mt-2 dashbannerchild0">5 USDT</h5>
                                            </div>
                                            <div className="dashbannerchild2 pt-3">
                                                <p className="m-auto opacity-50 text-white">Results in 1 cycle</p>
                                                <h3 className="dashbannerchild01 mt-3">0 USDT</h3>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/* <!-- <-----------------end--------------------->
                                <!-- <----------------third--------------------------> */}
                            <div className="row mt-5 bg-dark p-5 mx-auto dashrounding">
                                <div className="col-lg-12">

                                    <div className="row d-flex align-items-center ">
                                        <div className="col-lg-4 col-md-4 col-12 d-flex align-items-center  dashbanner2">
                                            <h2 className="logo1 px-2 py-4 mt-2">xGold</h2>
                                            <h2 className="ps-3">Metaking</h2>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12 px-3">
                                            <p className="dashbanner20 opacity-50 text-white">Exclusive program with ultimate opportunities for team work and development..</p>
                                        </div>
                                    </div>

                                    <div className="row mt-5 d-flex align-items-center gy-3">
                                        <div className="col-lg-6 col-md-6 col-12">
                                            <div className="calculatingtab1">
                                                <button className="thirdbtn1  me-2 mt-3 demo1" id="unique1">1</button>
                                                <button className="thirdbtn1   me-2 get mt-3" id="unique2">2</button>
                                                <button className="thirdbtn1   me-2 get mt-3" id="unique3">3</button>
                                                <button className="thirdbtn1   me-2 mt-3" id="unique4">4</button>
                                                <button className="thirdbtn1   me-2 mt-3" id="unique5">5</button>
                                                <button className="thirdbtn1   me-2 mt-3" id="unique6">6</button>
                                                <button className="thirdbtn1   me-2 mt-3" id="unique7">7</button>
                                                <button className="thirdbtn1   me-2 mt-3" id="unique8">8</button>
                                                <button className="thirdbtn1   me-2 mt-3" id="unique9">9</button>
                                                <button className="thirdbtn1   me-2 mt-3" id="unique10">10</button>
                                                <button className="thirdbtn1   me-2 mt-3" id="unique11">11</button>
                                                <button className="thirdbtn1   me-2 mt-3" id="unique12">12</button>
                                                <button className="thirdbtn1   me-2 mt-3" id="unique13">13</button>
                                                <button className="thirdbtn1   me-2 mt-3" id="unique14">14</button>
                                                <button className="thirdbtn1   me-2 mt-3" id="unique15">15</button>

                                            </div>
                                        </div>
                                        <div className="col-lg-5 col-md-5 col-12 ">
                                            <div className="dashbannerchild1">
                                                <p className="m-auto opacity-50 text-white">Cost of all selected slots</p>
                                                <h5 className="fw-bold mt-2 dashbannerchild0">5 USDT</h5>
                                            </div>
                                            <div className="dashbannerchild2 pt-3">
                                                <p className="m-auto opacity-50 text-white">Results in 1 cycle</p>
                                                <h3 className="dashbannerchild01 mt-3">0 USDT</h3>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/* <!-- <----------------------end--------------------------->
                                <!-- <---------------------fourth---------------------------> */}
                            <div className="row  mx-auto fourthbanner mt-5">
                                <div className="col-lg-6 py-lg-5 py-1">
                                    <div className="row py-lg-3 py-1">
                                        <div className="col-lg-4 fourthbannerchild1 m-auto">
                                            <p>Total cost</p>
                                            <h4>10 USDT</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 py-lg-5 py-1">
                                    <div className="row py-lg-3 py-1">
                                        <div className="col-lg-4 fourthbannerchild2 m-auto">
                                            <p>Total profit</p>
                                            <h4>35 USDT</h4>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            {/* <!-- <---------------------------end--------------------------->
                                <!-- <----------------------fifth--------------------->

                                <!-- <------------------------end---------------------------> */}

                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Metakingcalculator;