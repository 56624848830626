import "../assets/css/dashboard-style.css"

import Topnav from "../Dashboard-pages/Separate/Top-nav";
import Sidenav from "../Dashboard-pages/Separate/Side-nav";

function Withdraw() {
    return (
        <div className="App">
            <Topnav />
            <section className="dashboard-split-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 dashboard-split-left-section d-lg-block d-none">
                            <Sidenav />
                        </div>
                        <div className="col-lg-10 dashboard-split-right-section">
                            <div className="row p-2 g-4">
                                <div className="col-lg-12">
                                    <div className="card dashboard-card-1 py-4 px-lg-4 px-2">
                                        <div className="d-flex flex-row align-items-center">
                                            <div>
                                                <span className="mb-0 dashboard-text-2">Withdraw</span><button
                                                    className="ms-3 dashboard-button-1">ID 175</button>
                                            </div>
                                            <div className="ms-auto">
                                                <button className="ms-3 dashboard-button-1"><i className="fa-solid fa-right-from-bracket me-2"></i>Withdraw</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Withdraw;