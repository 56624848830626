import Web3 from "web3";
import config from "../../config/apiConfig";
import xcoreABI from "../../ABI/xcore.json";

export const totalTransactionAmt = async () => {
  try {
    let web3 = new Web3(window.ethereum);
    let xcorecontract = await new web3.eth.Contract(
      xcoreABI,
      config.xcorecontract
    );
    let coreAndLevTotAmt = await xcorecontract.methods
      .XcoreAndLevelContractTotAmount()
      .call();
    let totalForDisplay = await web3.utils.fromWei(
      parseInt(coreAndLevTotAmt),
      "ether"
    );
    let contractBasedTotAmt = totalForDisplay / 2;
    return {
        status : true,
        data : contractBasedTotAmt
    }
  } catch (error) {
    console.log("totalTransactionAmt error", error);
    return {
      status: false,
      message: "Error on server",
    };
  }
};

export const userCyclesData = async (userAddress) => {
    try {
      let web3 = new Web3(window.ethereum);
      let xcorecontract = await new web3.eth.Contract(
        xcoreABI,
        config.xcorecontract
      );
      let coreAndLevTotAmt = await xcorecontract.methods
        .XcoreAndLevelContractTotAmount()
        .call();
      let totalForDisplay = await web3.utils.fromWei(
        parseInt(coreAndLevTotAmt),
        "ether"
      );
      let contractBasedTotAmt = totalForDisplay / 2;
      return {
          status : true,
          data : contractBasedTotAmt
      }
    } catch (error) {
      console.log("totalTransactionAmt error", error);
      return {
        status: false,
        message: "Error on server",
      };
    }
  };
