let config = {}
let environment = "live"
if (environment === "local") {
    config = { 
        xcorecontract: "0x570A1DdC513d73202e9C89Ea6596be9F076F8365",
        levelplancontract: "0xd35C8352cB22E26ebec649de30Bac44351597b29",
        busdcontract: "0x1EaE2855cf10e7b56B523C89cFBb1F4CE6e3939e",
        BACKEND_URL: "http://65.0.19.16:4000/",
        // BACKEND_URL:"http://localhost:4000/",
        FRONTEND_URL:"http://3.110.133.199/",
        chainId : "0x61",
        rpcUrls : "https://data-seed-prebsc-1-s3.binance.org:8545/",
        metmaskurl: "https://metamask.io/download/",
        xcoreContarctLink: "https://testnet.bscscan.com/address/",
        levelIncomeLink: "https://testnet.bscscan.com/address/"
    }
}else if(environment === "deployment") {
    config = {
        xcorecontract: "0x5C8757523b22535465Dc343CD7ad93597180D3cc",
        levelplancontract: "0x448686112ad6667e912b220396b1232487ddf926",
        busdcontract: "0x55d398326f99059fF775485246999027B3197955",
        BACKEND_URL: "https://api.meta-king.io/",
        FRONTEND_URL:"https://meta-king.io/",
        chainId : "0x38",
        rpcUrls : "https://bsc-dataseed1.bnbchain.org",
        metmaskurl: "https://metamask.io/download/",
        xcoreContarctLink: "https://bscscan.com/address/",
        levelIncomeLink: "https://bscscan.com/address/"
    }
}else{
    config = {
        xcorecontract: "0x86179F3689dBe2E5897a1BdD3c63fAa97E07a8cA", 
        levelplancontract: "0x5C83B99bc04acE46c9BB0994f06ae514b86e3E24", 
        busdcontract: "0x55d398326f99059fF775485246999027B3197955", 
        BACKEND_URL: "https://api.meta-king.io/",
        
        FRONTEND_URL:"https://meta-king.io/", 
        // BACKEND_URL:"http://localhost:4000/",
        // FRONTEND_URL:"http://localhost:3000/",
        chainId : "0x38", 
        rpcUrls : "https://bsc-dataseed1.binance.org/", 
        metmaskurl: "https://metamask.io/download/",
        xcoreContarctLink: "https://bscscan.com/address/", 
        levelIncomeLink: "https://bscscan.com/address/"
    }
}

export default config;