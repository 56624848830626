import "../assets/css/dashboard-style.css"

import Topnav from "../Dashboard-pages/Separate/Top-nav";
import Sidenav from "../Dashboard-pages/Separate/Side-nav";

function Metakingxxxxgold() {
    return (
        <div className="App">
            <Topnav />
            <section className="dashboard-split-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 dashboard-split-left-section d-lg-block d-none">
                            <Sidenav />
                        </div>
                        <div className="col-lg-10 dashboard-split-right-section">
                            <div className="row p-2 g-4">
                                <div className="col-lg-12">
                                    <p className="mb-3 dashboard-text-6"><span className="mb-0 dashboard-text-4">ID 1 /</span>
                                        Meta King xXx/Xgold
                                        (10 out of 10 levels)</p>
                                    <div className="d-flex flex-lg-row flex-column">
                                        <div>
                                            <p className="dashboard-text-2 mb-0">Metaking xXx/Xgold</p>
                                        </div>
                                        <div className="ms-lg-auto">
                                            <p className="dashboard-text-2 mb-0">358 065 USDT</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row p-2 g-4">
                                <div className="col-lg-12">
                                    <div className="card dashboard-x3-card-1 position-relative overflow-hidden">
                                        <div className="dashboard-user-programs-glow-2"></div>
                                        <div className="row row-cols-1 row-cols-lg-5 g-4">
                                            <div className="col">
                                                <div className="card h-100 dashboard-x3-card-1-inner-1">
                                                    <a className="" href="/metakingxxxinner">
                                                        <div className="d-flex flex-lg-row flex-column">
                                                            <div>
                                                                <span className="mb-0 dashboard-text-4">Lvl 1</span>
                                                            </div>
                                                            <div className="ms-lg-auto">
                                                                <span className="dashboard-x3-text-1">🪙 5</span>
                                                            </div>
                                                        </div>
                                                        <div className="grid-dashboard-x3-l1-container mt-3">
                                                            <div className="dashboard-user-x3-l1-programs dashboard-user-x3-programs-active"></div>
                                                            <div className="dashboard-user-x3-l1-programs"></div>
                                                        </div>
                                                        <div className="grid-dashboard-x3-l2-container mt-2">
                                                            <div className="dashboard-user-x3-l2-programs dashboard-user-x3-programs-active"></div>
                                                            <div className="dashboard-user-x3-l2-programs dashboard-user-x3-programs-active"></div>
                                                            <div className="dashboard-user-x3-l2-programs"></div>
                                                            <div className="dashboard-user-x3-l2-programs"></div>
                                                        </div>
                                                        <div className="grid-dashboard-x3-l3-container mt-2">
                                                            <div className="dashboard-user-x3-l3-programs dashboard-user-x3-programs-active"></div>
                                                            <div className="dashboard-user-x3-l3-programs dashboard-user-x3-programs-active"></div>
                                                            <div className="dashboard-user-x3-l3-programs dashboard-user-x3-programs-active"></div>
                                                            <div className="dashboard-user-x3-l3-programs dashboard-user-x3-programs-active"></div>
                                                            <div className="dashboard-user-x3-l3-programs"></div>
                                                            <div className="dashboard-user-x3-l3-programs"></div>
                                                            <div className="dashboard-user-x3-l3-programs"></div>
                                                            <div className="dashboard-user-x3-l3-programs"></div>
                                                        </div>
                                                        <div className="mt-2">
                                                            <span className="mb-0 dashboard-text-4"><i
                                                                className="fa-solid fa-user-group"></i></span><span
                                                                    className="dashboard-x3-text-1 ms-1">20165</span>
                                                            <span className="mb-0 dashboard-text-4 ms-2"><i
                                                                className="fa-solid fa-arrows-rotate"></i></span><span
                                                                    className="dashboard-x3-text-1 ms-1">20</span>

                                                        </div>
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="col">
                                                <div className="card h-100 dashboard-x3-card-1-inner-1">
                                                    <a className="" href="/metakinggoldinner">
                                                        <div className="d-flex flex-lg-row flex-column">
                                                            <div>
                                                                <span className="mb-0 dashboard-text-4">Lvl 1</span>
                                                            </div>
                                                            <div className="ms-lg-auto">
                                                                <span className="dashboard-x3-text-1">🪙 5</span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="grid-dashboard-gold-l1-container mt-2">
                                                                <div
                                                                    className="dashboard-user-gold-l1-programs dashboard-user-gold-programs-active ">
                                                                </div>
                                                                <div className="dashboard-user-gold-l1-programs"></div>
                                                            </div>
                                                            <div className="grid-dashboard-gold-l2-container mt-2">
                                                                <div
                                                                    className="dashboard-user-gold-l2-programs dashboard-user-gold-programs-active ">
                                                                </div>
                                                                <div className="dashboard-user-gold-l2-programs"></div>
                                                                <div className="dashboard-user-gold-l2-programs"></div>
                                                                <div className="dashboard-user-gold-l2-programs"></div>
                                                            </div>
                                                            <div className="grid-dashboard-gold-l3-container mt-2">
                                                                <div className="dashboard-user-gold-l3-programs dashboard-user-gold-programs-active "></div>
                                                                <div className="dashboard-user-gold-l3-programs dashboard-user-gold-programs-active "></div>
                                                                <div className="dashboard-user-gold-l3-programs"></div>
                                                                <div className="dashboard-user-gold-l3-programs"></div>
                                                                <div className="dashboard-user-gold-l3-programs"></div>
                                                                <div className="dashboard-user-gold-l3-programs"></div>
                                                                <div className="dashboard-user-gold-l3-programs"></div>
                                                                <div className="dashboard-user-gold-l3-programs"></div>
                                                            </div>
                                                            <div className="grid-dashboard-gold-l4-container mt-2">
                                                                <div className="dashboard-user-gold-l4-programs dashboard-user-gold-programs-active "></div>
                                                                <div className="dashboard-user-gold-l4-programs dashboard-user-gold-programs-active "></div>
                                                                <div className="dashboard-user-gold-l4-programs dashboard-user-gold-programs-active "></div>
                                                                <div className="dashboard-user-gold-l4-programs dashboard-user-gold-programs-active "></div>
                                                                <div className="dashboard-user-gold-l4-programs"></div>
                                                                <div className="dashboard-user-gold-l4-programs"></div>
                                                                <div className="dashboard-user-gold-l4-programs"></div>
                                                                <div className="dashboard-user-gold-l4-programs"></div>
                                                                <div className="dashboard-user-gold-l4-programs"></div>
                                                                <div className="dashboard-user-gold-l4-programs"></div>
                                                                <div className="dashboard-user-gold-l4-programs"></div>
                                                                <div className="dashboard-user-gold-l4-programs"></div>
                                                                <div className="dashboard-user-gold-l4-programs"></div>
                                                                <div className="dashboard-user-gold-l4-programs"></div>
                                                                <div className="dashboard-user-gold-l4-programs"></div>
                                                                <div className="dashboard-user-gold-l4-programs"></div>
                                                            </div>
                                                        </div>
                                                        <div className="mt-2">
                                                            <span className="mb-0 dashboard-text-4"><i
                                                                className="fa-solid fa-user-group"></i></span><span
                                                                    className="dashboard-x3-text-1 ms-1">20165</span>
                                                            <span className="mb-0 dashboard-text-4 ms-2"><i
                                                                className="fa-solid fa-arrows-rotate"></i></span><span
                                                                    className="dashboard-x3-text-1 ms-1">20</span>

                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="d-flex flex-row mt-5 gap-4 flex-wrap">
                                            <div>
                                                <span><i className="fa-sharp fa-solid fa-circle"></i></span><span
                                                    className="dashboard-text-4 ms-2">Direct partner</span>
                                            </div>
                                            <div>
                                                <span><i className="fa-sharp fa-solid fa-circle"
                                                    style={{ color: "yellow" }}></i></span><span
                                                        className="dashboard-text-4 ms-2">Spillover from above</span>
                                            </div>
                                            <div>
                                                <span><i className="fa-sharp fa-solid fa-circle"
                                                    style={{ color: "gold" }}></i></span><span
                                                        className="dashboard-text-4 ms-2">Spillover from below</span>
                                            </div>
                                            <div>
                                                <span><i className="fa-sharp fa-solid fa-circle"
                                                    style={{ color: "aqua" }}></i></span><span
                                                        className="dashboard-text-4 ms-2">Gift</span>
                                            </div>
                                            <div>
                                                <span><i className="fa-solid fa-user-group"></i></span><span
                                                    className="dashboard-text-4 ms-2">Partners on level</span>
                                            </div>
                                            <div>
                                                <span><i className="fa-solid fa-arrows-rotate"></i></span><span
                                                    className="dashboard-text-4 ms-2">Level Cycle</span>
                                            </div>
                                            <div>
                                                <span><i className="fa-solid fa-circle-arrow-up"></i></span><span
                                                    className="dashboard-text-4 ms-2">Send to upline</span>
                                            </div>
                                            <div>
                                                <button className="dashboard-button-2"><i
                                                    className="fa-solid fa-circle-question me-1"></i> Marketing legend
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card dashboard-x3-card-2 mt-4">
                                        <table className="table text-white">
                                            <thead className="">
                                                <tr>
                                                    <th scope="col">Type</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">ID</th>
                                                    <th scope="col">Level</th>
                                                    <th scope="col">Wallet</th>
                                                    <th scope="col">USDT</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><i className="fa-solid fa-user dashboard-activity-1"></i></td>
                                                    <td>10.07.2023 05:03</td>
                                                    <td><button className="dashboard-button-1">ID 133275</button></td>
                                                    <td>1</td>
                                                    <td>
                                                        <div className="ms-auto d-flex align-items-center gap-2"
                                                            style={{ fontsize: "13px" }}>
                                                            <span>0x5ac...B97</span>
                                                            <button className="border-0 bg-transparent text-white"><i
                                                                className="fa-solid fa-copy"></i></button>
                                                            <button className="border-0 bg-transparent text-white"><i
                                                                className="fa-solid fa-link"></i></button>
                                                        </div>
                                                    </td>
                                                    <td>5 USDT</td>
                                                </tr>

                                                <tr>
                                                    <td><i className="fa-solid fa-user dashboard-activity-1"></i></td>
                                                    <td>10.07.2023 05:03</td>
                                                    <td><button className="dashboard-button-1">ID 133275</button></td>
                                                    <td>1</td>
                                                    <td>
                                                        <div className="ms-auto d-flex align-items-center gap-2"
                                                            style={{ fontsize: "13px" }}>
                                                            <span>0x5ac...B97</span>
                                                            <button className="border-0 bg-transparent text-white"><i
                                                                className="fa-solid fa-copy"></i></button>
                                                            <button className="border-0 bg-transparent text-white"><i
                                                                className="fa-solid fa-link"></i></button>
                                                        </div>
                                                    </td>
                                                    <td>5 USDT</td>
                                                </tr>


                                                <tr>
                                                    <td><i className="fa-solid fa-user dashboard-activity-1"></i></td>
                                                    <td>10.07.2023 05:03</td>
                                                    <td><button className="dashboard-button-1">ID 133275</button></td>
                                                    <td>1</td>
                                                    <td>
                                                        <div className="ms-auto d-flex align-items-center gap-2"
                                                            style={{ fontsize: "13px" }}>
                                                            <span>0x5ac...B97</span>
                                                            <button className="border-0 bg-transparent text-white"><i
                                                                className="fa-solid fa-copy"></i></button>
                                                            <button className="border-0 bg-transparent text-white"><i
                                                                className="fa-solid fa-link"></i></button>
                                                        </div>
                                                    </td>
                                                    <td>5 USDT</td>
                                                </tr>


                                                <tr>
                                                    <td><i className="fa-solid fa-user dashboard-activity-1"></i></td>
                                                    <td>10.07.2023 05:03</td>
                                                    <td><button className="dashboard-button-1">ID 133275</button></td>
                                                    <td>1</td>
                                                    <td>
                                                        <div className="ms-auto d-flex align-items-center gap-2"
                                                            style={{ fontsize: "13px" }}>
                                                            <span>0x5ac...B97</span>
                                                            <button className="border-0 bg-transparent text-white"><i
                                                                className="fa-solid fa-copy"></i></button>
                                                            <button className="border-0 bg-transparent text-white"><i
                                                                className="fa-solid fa-link"></i></button>
                                                        </div>
                                                    </td>
                                                    <td>5 USDT</td>
                                                </tr>


                                                <tr>
                                                    <td><i className="fa-solid fa-user dashboard-activity-1"></i></td>
                                                    <td>10.07.2023 05:03</td>
                                                    <td><button className="dashboard-button-1">ID 133275</button></td>
                                                    <td>1</td>
                                                    <td>
                                                        <div className="ms-auto d-flex align-items-center gap-2"
                                                            style={{ fontsize: "13px" }}>
                                                            <span>0x5ac...B97</span>
                                                            <button className="border-0 bg-transparent text-white"><i
                                                                className="fa-solid fa-copy"></i></button>
                                                            <button className="border-0 bg-transparent text-white"><i
                                                                className="fa-solid fa-link"></i></button>
                                                        </div>
                                                    </td>
                                                    <td>5 USDT</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <button className="btn dashboard-activity-seemore-btn mt-5 py-2" type="button"><i
                                            className="fa-solid fa-eye"></i> Seemore</button>
                                    </div>


                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Metakingxxxxgold;