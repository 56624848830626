import { useLocation } from "react-router-dom";
import Dashboard from "./Dashboard";
import ProtectedRoute from "../auth/ProductedRoute";




const DashboardRenderer = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userid = queryParams.get('user');
    if (userid) {
        // Render unprotected Dashboard if user query parameter is present
        return < Dashboard />;
    } else {
        // Render protected Dashboard if no user query parameter is present
        return <ProtectedRoute><Dashboard/></ProtectedRoute>
        // return <Dashboard/>
    }
}

export default DashboardRenderer;