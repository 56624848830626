import xcoreABI from "../ABI/xcore.json";
import config from "../config/apiConfig";
import Web3 from "web3";


export const xcoreRequestCycle = async (params) => {
    
    let USERADDRESS = params.useradd;
    let usrlvl = params.usrlevels;
    let web3 = new Web3(config.rpcUrls);
    let xcorecontract = await new web3.eth.Contract(xcoreABI, config.xcorecontract);
    let userearn = await xcorecontract.methods.returnusrearn(USERADDRESS,usrlvl).call();
    let lvlprice = await xcorecontract.methods.levelPrice(usrlvl).call();
        let lvlpricewei = await web3.utils.fromWei(lvlprice,"ether");
       
    let usrtotalreven = await web3.utils.fromWei(parseInt(userearn[0]),"ether");
    const userlevelsl = await xcorecontract.methods.getcycles(USERADDRESS,usrlvl).call();
    const userlevel2 = await xcorecontract.methods.usersX6Matrix(USERADDRESS,usrlvl,0).call();
        
    let usrlen = 3 - userlevel2[1].length;

    var new1cycle = [...userlevel2[1]];
    for (let i = 0; i < usrlen; i++) {
     new1cycle.push(0)
    }

    let usrlen2 = 9 - userlevel2[2].length;
    var new2cycle = [...userlevel2[2]];
    for (let i = 0; i < usrlen2; i++) {
        new2cycle.push(0)
    }
  
    return ({usrlvls: userlevelsl,
        usrnewcy1:new1cycle,
        usrnewcy2:new2cycle,
        usrreven:usrtotalreven,
        usrpartners:userearn[1],
        lvlpriceweis:lvlpricewei});
}