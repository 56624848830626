import loginlogo from "./assets/images/loginlogo-img.png";

import { LiaShareSquare } from "react-icons/lia";
import { BiErrorCircle, BiLogOut } from "react-icons/bi";
import { AiFillMessage } from "react-icons/ai";
import { BsPlayFill } from "react-icons/bs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "./config/apiConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Web3 from "web3";
import busdABI from "./ABI/busd.json";
import xcoreABI from "./ABI/xcore.json";
import axios from "axios";
import $ from "jquery";
import { makeApiRequest } from "./config/axiosService";
import {
  getParentID,
  registerUser,
  getUserDataAPI,
} from "./Dashboard-pages/api/authapis";

// $(document).ready(function () {
//   $(".lastarrowicons").click(function () {
//     $(".cargimages").hide();
//     $(".lastarrowicons").hide();
//     $(".cardiframe").show();
//   });
//   $(".cargimages").click(function () {
//     $(".lastarrowicons").hide();
//   });
// });

function Registration() {
  const navigate = useNavigate();

  const formdata = {
    userAddress: "",
    parentAddress: "",
  };

  const [parentAddress, setParentAddress] = useState("");
  const [userAddress, setUserAddress] = useState(null);
  const [Formdata, setFormdata] = useState(formdata);
  // const [ connectWalletAddress, setConnectWalletAddress ] = useState(null);
  const [currentAccount, setCurrentAccount] = useState("");
  const [loaderSetup, setLoaderSetup] = useState(true);
  const [logoimage, setLogoimage] = useState('')
  const handleChange = (event) => {
    setParentAddress(event.target.value);
    // console.log('value is:', event.target.value);
  };


  const logoimageset = async () => {
    try {
      const response = await axios.get(config.BACKEND_URL + 'admin/getimage'); // Update with your API endpoint
      // console.log('response.data---', response.data);
      if (response.data && response.data.length > 0) {
        setLogoimage(response.data[0].logoimage); // Assuming you want to display the first image
        // setOldImageId(response.data[0]._id)
      }
    } catch (error) {
      console.error('Error fetching old image:', error);
    }
  }
  useEffect(() => {
    logoimageset()
  }, [])
  
  const userRegister = async () => {
    try {
      if(window.ethereum){
        const account = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        let useraddress = account[0];
  
        let walletConnect = localStorage.getItem("address");
        if (walletConnect != null) {
          if (parentAddress == "") {
            toast.error("Please enter parent address");
          } else {
            let parentData = await getParentID(parentAddress);
            console.log("parent data",parentData);
            if (parentData.status) {
              // console.log("useraddress", useraddress);
              let userData = await getUserDataAPI(useraddress);
  
              if (userData.data == null) {
                setLoaderSetup(false);
                let web3 = new Web3(window.ethereum);
                let busdcontract = new web3.eth.Contract(
                  busdABI,
                  config.busdcontract
                );
                let amount = await web3.utils.toWei("15", "ether");
                let result = await busdcontract.methods.approve(config.xcorecontract, amount).send({ from: useraddress });
                let xcorecontract = await new web3.eth.Contract(
                  xcoreABI,
                  config.xcorecontract
                );
                let data = await xcorecontract.methods.registrationFor(useraddress, parentData.result.address)
                  .send({ from: useraddress });
  
                let userID = await xcorecontract.methods
                  .users(useraddress)
                  .call();
  
                console.log("contractcall----userID--------", userID);
                let userDbAddress = parseInt(userID.id);
                let userRegAmt = await web3.utils.fromWei(
                  parseInt(userID.amount),
                  "ether"
                );
                // console.log("userRegAmt--",userRegAmt)
  
                // let USERID = await xcorecontract.methods.userref
  
                const newObj = {
                  userAddress: useraddress,
                  parentAddress: parentData.result.address,
                  parentId: parentData.result.userId,
                  userId: userDbAddress,
                  amount: userRegAmt,
                };
                let userRegi = await registerUser(newObj);
                //  console.log("userRegi", userRegi);
                if (userRegi.status) {
                  toast.success(`${userRegi.message}`);
                  localStorage.setItem("address", `${userRegi.data.address}`);
                  localStorage.setItem("userID", `${userRegi.data.userId}`);
                  window.location.href = "/login";
                }
              } else {
                toast.error("You've already register please click login");
              }
            } else {
              toast.error("Parent Address doesn't exists");
            }
          }
        } else {
          toast.error("Please connect your wallet");
        }
      }else{
        window.open(config.metmaskurl, '_blank');
      }
    } catch (err) {
      setLoaderSetup(true);
      console.log(err, "error");
      toast.error("Something went wrong");
    }
  };

  // const connectWallet = async () => {
  //   const account = await window.ethereum.request({ method: 'eth_requestAccounts' });
  //     var useraddress = account[0];
  //     // setConnectWalletAddress(useraddress);
  //  console.log("useraddress",useraddress)
  // }

  const connectWallet = async () => {
    try {
      if(window.ethereum){
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: config.chainId }],
        });
        // Check if MetaMask is available
        if (typeof window.ethereum !== "undefined") {
          const web3 = new Web3(window.ethereum);
  
          // Request account access if needed
          window.ethereum
            .request({ method: "eth_requestAccounts" })
            .then((accounts) => {
              // Set the initial account
              const address = accounts[0];
              if (localStorage.getItem("address") === address) {
                setCurrentAccount(
                  address.substring(0, 5) +
                    "..." +
                    address.substring(address.length - 4)
                );
              } else {
                setCurrentAccount(
                  address.substring(0, 5) +
                    "..." +
                    address.substring(address.length - 4)
                );
                localStorage.setItem("address", address);
              }
            })
            .catch((error) => {
              console.error(error);
              toast.error("Something went wrong");
            });
        } else {
          toast.error("Metamask not found");
          // console.error('Metamask not found');
        }
      }else{
        window.open(config.metmaskurl, '_blank');
      }
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: config.chainId,
                chainName: "BNB Smart Chain Testnet",
                rpcUrls: config.rpcUrls /* ... */,
              },
            ],
          });
          // Check if MetaMask is available
          if (typeof window.ethereum !== "undefined") {
            const web3 = new Web3(window.ethereum);

            // Request account access if needed
            window.ethereum
              .request({ method: "eth_requestAccounts" })
              .then((accounts) => {
                // Set the initial account
                const address = accounts[0];
                if (localStorage.getItem("address") === address) {
                  setCurrentAccount(
                    address.substring(0, 5) +
                      "..." +
                      address.substring(address.length - 4)
                  );
                } else {
                  setCurrentAccount(
                    address.substring(0, 5) +
                      "..." +
                      address.substring(address.length - 4)
                  );
                  localStorage.setItem("address", address);
                }
              })
              .catch((error) => {
                console.error(error);
                toast.error("Something went wrong");
              });
          } else {
            console.error("Metamask not found");
            toast.error("Metamask not found");
          }
        } catch (addError) {
          // handle "add" error
          console.log(addError);
          toast.error("Metamask not found");
        }
      }
      // handle other "switch" errors
    }
  };
  useEffect(() => {
    const params = window.location.href.split("/")[4];
    console.log(params);
    setParentAddress(params || "");
    // When the component mounts, check if there is an address in localStorage and update the currentAccount state.
    let Address = localStorage.getItem("address");
    if (Address) {
      setCurrentAccount(
        Address.substring(0, 5) + "..." + Address.substring(Address.length - 4)
      );
    }
  }, []);

  const LogOutPage = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <div class="meta-king-signup-section">
      <ToastContainer />

      <section id="secondnav">
        <nav class="navbar navbar-expand-lg ">
          <div class="container">
            <a class="navbar-brand" href="/">
              {/* <img src={loginlogo} alt="logo" /> */}
              <img  src={logoimage} alt="logo" />
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse " id="navbarSupportedContent">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0"></ul>
              {/* <form class="d-flex " role="search"> */}
              {currentAccount ? (
                <button class="btn33 text-white fs-6">{currentAccount}</button>
              ) : (
                <button
                  class="btn33 text-white fs-6"
                  onClick={() => connectWallet()}
                >
                  Connect Wallet
                </button>
              )}
              <button class="btn44 ms-3" onClick={() => LogOutPage()}>
                <LiaShareSquare className="opacity-50 text-white text-center fs-5" />
              </button>
              {/* </form> */}
            </div>
          </div>
        </nav>
      </section>
      <section id="registeration" class="gy-5">
        <div class="container">
          <div class="row my-5">
            <div class="col-lg-6 col-md-6  col-12">
              <div class="row">
                <div class="col-lg-12">
                  <div class="registerationchild0">
                    <h3 class="fs-1 text-white registerationchild01">
                      Registration
                      <br />
                      in Meta King USDT
                    </h3>
                  </div>
                </div>
              </div>
              <div class="row py-4">
                <div class="col-lg-12">
                  <div class="banner10">
                    <p class="opacity-50 text-white">Your upline</p>
                    <input
                      type="text"
                      class="bannerinput text-white p-3"
                      onChange={handleChange}
                      value={parentAddress}
                      onKeyPress={(event) => {
                        const keyCode = event.which || event.keyCode;
                        const keyValue = String.fromCharCode(keyCode);

                        // Allow only numeric values (0-9) and the backspace key (8)
                        if (!/^[0-9]+$/.test(keyValue)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div class="row py-3">
                <div class="col-lg-12">
                  {loaderSetup ? (
                    <button
                      class="banner11bttn1 mt-2 px-5 py-3 text-white"
                      onClick={userRegister}
                    >
                      check again
                    </button>
                  ) : (
                    <button class="banner11bttn1 mt-2 px-5 py-3 text-white">
                      <div
                        class="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div class="col-lg-6  col-md-6 col-12">
              <div class="row">
                <div class="col-lg-9  d-flex registerationradius justify-content-end flex-column bg-dark py-2 px-4">
                  <div class="row py-2">
                    <div class="col-lg-12 d-flex align-items-center">
                      <p class="fs-4 fw-bold text-white">
                        <BiErrorCircle className="text-white me-3 fw-4" />
                      </p>
                      <p class="fs-4 fw-bold text-white">Information</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      {currentAccount ? (
                        <p class="text-white fs-6">Wallet connected.</p>
                      ) : (
                        <p class="text-white fs-6">Wallet not connected.</p>
                      )}
                      <p class="text-white opacity-50 fs-6">
                        Access the website via cryptowallet dapp browser
                        (”Discover” button in Tokenpoket) or with Metamask
                        extension installed.
                      </p>
                    </div>
                  </div>

                  <div class="row m-auto">
                    <div class="col-lg-12 ">
                      <div class="card border-0 mt-4 bg-dark ">
                      
                        <div class="card-body d-flex ">
                          <h5 class="card-title me-3 mt-1">
                            <AiFillMessage className="opacity-50 text-white" />
                          </h5>
                          <p class="opacityt-50 text-white mt-1">
                            Need help with registration? Talk to experts in the
                            support chat
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Registration;
