import "../assets/css/dashboard-style.css";

import Topnav from "../Dashboard-pages/Separate/Top-nav";
import Sidenav from "../Dashboard-pages/Separate/Side-nav";
import React, { useEffect, useState } from "react";
import { userRefLevel, getUserDataAPI } from "./api/authapis";
import moment from "moment";
import imglogo from "../assets/images/busd.png";
import { ToastContainer, toast } from "react-toastify";
import Web3 from "web3";
import xcoreABI from "../ABI/xcore.json";
import config from "../config/apiConfig";
import { totalTransactionAmt } from './api/commonFunctions'

import { useLocation, useNavigate } from "react-router-dom";
import { xcoreRequestCycle } from "../xcorecycle/xcore-inner-cycle";


function Metakingxcoreinner() {

  // const [primaryRefData, setPrimaryRefData] = useState([]);
  // const [secondaryRefData, setSecondaryRefData] = useState([]);
  const [authUserData, setAuthUserData] = useState(null);
  const [xcoreTotalAmt, setXcoreTotalAmt] = useState(null);
  const [SecondaryRefcontractData, setSecondaryRefcontractData] = useState([]);
  const [primaryRefcontractData, setprimaryRefcontractData] = useState([]);
  const [UserCyclesLength, setUserCyclesLength] = useState(0);
  const [Addressstate,setAddressstate] = useState(null);
  const [usrpartners,setusrpartners] = useState("0");
  const [usrrevenues,setusrrevenues] = useState("0");
  const [contractlvlprice,setcontractlvlprice] = useState('0');
  const [jsondatauser,setjsondatauser] = useState([]);
  const [nextcycles,setnextcycles] = useState(1);
  const [pageNumber, setPageNumber] = useState(5);
  const USERID = localStorage.getItem("userID");
  const USERADDRESS = localStorage.getItem("address");
console.log("usrpartners---------",usrpartners);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userid = queryParams.get('user');
  const userlevelquery = queryParams.get('level');
// console.log("jsondatauser=======",jsondatauser);

  useEffect(() => {
    const setaadd = async() => {
      if(userid == null) {
        setAddressstate(USERADDRESS)
        let xcores = await xcoreRequestCycle({useradd: USERADDRESS,lvls:1,usrlevels:userlevelquery});
          setUserCyclesLength(parseInt(xcores.usrlvls)+1)
          setprimaryRefcontractData(xcores.usrnewcy1)
          setSecondaryRefcontractData(xcores.usrnewcy2)
          // setusrpartners(parseInt(xcores.usrpartners))
          setusrrevenues(xcores.usrreven)
          setcontractlvlprice(xcores.lvlpriceweis)
      }
      else {
          let userData = await getUserDataAPI(userid)
          if (userData.status) {
            setAddressstate(userData.data.address);
            let xcores = await xcoreRequestCycle({useradd: userData.data.address,lvls:1,usrlevels:userlevelquery});
            setUserCyclesLength(parseInt(xcores.usrlvls)+1)
              setprimaryRefcontractData(xcores.usrnewcy1)
              setSecondaryRefcontractData(xcores.usrnewcy2)
              // setusrpartners(parseInt(xcores.usrpartners))
              setusrrevenues(xcores.usrreven)
              setcontractlvlprice(xcores.lvlpriceweis)
        }
      }
    }
    setaadd();
    jsontable();
  },[userid])


  const clickHandler = (userId) => {
    if (userId) {
      navigate(`/dashboard?user=${userId}`)
      window.location.reload();
    } else {
      navigate('/dashboard')
    }
  }

  const clickHandlerId = (parentId) => {
    if (parentId) {
      navigate(`/dashboard?user=${parentId}`)
      window.location.reload();
    } else {
      navigate('/dashboard')
    }
  }

  // const userRefLev = async (userId) => {
  //   let userOverAllRef = await userRefLevel(userid || userId);
  //   // if (userOverAllRef.status) {
  //   //   setPrimaryRefData(userOverAllRef.primaryResult);
  //   //   setSecondaryRefData(userOverAllRef.secondaryResult);
  //   // } else {
  //   //   setPrimaryRefData([]);
  //   //   setSecondaryRefData([]);
  //   //   toast.error("User not found");
  //   //   navigate(`/login`);
  //   // }
  // };

  const userData = async () => {
    try {
      let userData = await getUserDataAPI(userid || USERADDRESS);
      if (userData.status) {
        setAuthUserData(userData.data);
      }
    } catch (error) {
      console.log("error", error);
      toast.error("Something went wrong..!");
    }
  };

  const jsontable = async() => {
      let web3 = new Web3(config.rpcUrls);
      let xcorecontract = await new web3.eth.Contract(xcoreABI, config.xcorecontract);
        if(userid == null) { 
          const userlevel2 = await xcorecontract.methods.usersX6Matrix(USERADDRESS, userlevelquery,0).call();
          const mergeduserArray = userlevel2[1].concat(userlevel2[2]);
          var mergepush = [];
          for(var i=0;i < mergeduserArray.length;i++) {
            let refid = parseInt(mergeduserArray[i].refId);
            let level = parseInt(mergeduserArray[i].level);
            const momentObj = moment.unix(Number(mergeduserArray[i].timestamp));
            const formattedDate = momentObj.format('YYYY-MM-DD HH:mm:ss');
            let fromweis = await web3.utils.fromWei(parseInt(mergeduserArray[i].amount),'ether')
            var obj = {
              refaddress:mergeduserArray[i].refAddress,
              id: refid,
              level: level,
              amount: fromweis,
              datetime:formattedDate
            }
            mergepush.push(obj);
          }
          setjsondatauser(mergepush)

        }
        else {
          let userData = await getUserDataAPI(userid)
          const userlevel2 = await xcorecontract.methods.usersX6Matrix(userData.data.address, userlevelquery,0).call();
         
          const mergeduserArray = userlevel2[1].concat(userlevel2[2]);
          var mergepush = [];
          for(var i=0;i < mergeduserArray.length;i++) {
            let refid = parseInt(mergeduserArray[i].refId);
            let level = parseInt(mergeduserArray[i].level);
            const momentObj = moment.unix(Number(mergeduserArray[i].timestamp));
            const formattedDate = momentObj.format('YYYY-MM-DD HH:mm:ss');
            let fromweis = await web3.utils.fromWei(parseInt(mergeduserArray[i].amount),'ether')
            var obj = {
              refaddress:mergeduserArray[i].refAddress,
              id: refid,
              level: level,
              amount: fromweis,
              datetime:formattedDate
            }
            mergepush.push(obj);
          }
          const filteredDetails = mergepush.slice(0, pageNumber);
          setjsondatauser(filteredDetails)
          console.log("filteredDetails====",filteredDetails);
          let array = []
          array.push(filteredDetails)
          setusrpartners(filteredDetails.length)
          console.log(array.length);
        }
    // }
  }

  const seeMoreUsers =async()=>{
    try {      
      let web3 = new Web3(config.rpcUrls);
      let xcorecontract = await new web3.eth.Contract(xcoreABI, config.xcorecontract);
      let userData = await getUserDataAPI(userid)
      const userlevel2 = await xcorecontract.methods.usersX6Matrix(userData.data.address, userlevelquery,0).call();
      
      const mergeduserArray = userlevel2[1].concat(userlevel2[2]);
      var mergepush = [];
     
      for(var i=0;i < mergeduserArray.length;i++) {
        let refid = parseInt(mergeduserArray[i].refId);
        let level = parseInt(mergeduserArray[i].level);
        const momentObj = moment.unix(Number(mergeduserArray[i].timestamp));
        const formattedDate = momentObj.format('YYYY-MM-DD HH:mm:ss');
        let fromweis = await web3.utils.fromWei(parseInt(mergeduserArray[i].amount),'ether')
        var obj = {
          refaddress:mergeduserArray[i].refAddress,
          id: refid,
          level: level,
          amount: fromweis,
          datetime:formattedDate
        }
        mergepush.push(obj);
        let filteredDetails;
        if (pageNumber === 5) {
       filteredDetails = mergepush.slice(0, pageNumber+1);
       setPageNumber(7);
        }else{
          filteredDetails = mergepush.slice(0, pageNumber);
          setPageNumber(pageNumber + 1);
         
        }
        setjsondatauser(filteredDetails)
       
      }
    
   
    } catch (error) {
      console.log(error, "error");
      // toast.error("Something went wrong");
    }
  }

  const changeCyclesdiagramnext = async () => {
    if(window.ethereum){
      if(nextcycles < UserCyclesLength) {
        let web3 = new Web3(config.rpcUrls);
        let xcorecontract = await new web3.eth.Contract(xcoreABI, config.xcorecontract);
        const userlevel2 = await xcorecontract.methods.usersX6Matrix(Addressstate, userlevelquery,nextcycles).call();
        let usrlen = 3 - userlevel2[1].length;
        var new1cycle = [...userlevel2[1]];
        for (let i = 0; i < usrlen; i++) {
          new1cycle.push(0)
        }
  
        setprimaryRefcontractData(new1cycle);
    
        let usrlen2 = 9 - userlevel2[2].length;
        var new2cycle = [...userlevel2[2]];
        for (let i = 0; i < usrlen2; i++) {
          new2cycle.push(0)
        }

        setSecondaryRefcontractData(new2cycle);
        setnextcycles(nextcycles+1);
      }
    }
    
  }


  const changeCyclesdiagrampre = async () => {
    if(window.ethereum){
      let web3 = new Web3(config.rpcUrls);
      let xcorecontract = await new web3.eth.Contract(xcoreABI, config.xcorecontract);
      const userlevel2 = await xcorecontract.methods.usersX6Matrix(Addressstate, userlevelquery, 0).call();
      let usrlen = 3 - userlevel2[1].length;
      var new1cycle = [...userlevel2[1]];
      for (let i = 0; i < usrlen; i++) {
        new1cycle.push(0)
      }
    
      setprimaryRefcontractData(new1cycle);
  
      let usrlen2 = 9 - userlevel2[2].length;
      var new2cycle = [...userlevel2[2]];
      for (let i = 0; i < usrlen2; i++) {
        new2cycle.push(0)
      }
      setSecondaryRefcontractData(new2cycle);
      setnextcycles(0+1)
    }
    
  }

  const totalXcoreTransaction = async () => {
    try {
      let totalAmt = await totalTransactionAmt();
      if (totalAmt.status) {
        setXcoreTotalAmt(totalAmt.data)
      }
    }
    catch (error) {
      console.log("error", error);
      // toast.error("Something went wrong..!");
    }
  }

  useEffect(() => {
    
    // if(userid == null && USERID != ''){
    //   userRefLev(USERID);
    // }else if(userid >= 1){
    //   userRefLev(userid);
    // }
    userData();
    totalXcoreTransaction();
  }, [USERID,userid]);

  return (
    <div className="App">
      <Topnav />
      <ToastContainer />
      <section className="dashboard-split-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 dashboard-split-left-section d-lg-block d-none">
              <Sidenav />
            </div>
            <div className="col-lg-10 dashboard-split-right-section">
              <div className="row p-2 g-4">
                <div className="col-lg-12">
                  <p className="mb-3 dashboard-text-6">
                    <span className="mb-0 dashboard-text-4">
                      ID {userid || USERID} / Meta King xcore
                    </span>{" "}
                    / level {userlevelquery}
                  </p>
                  <div className="d-flex flex-lg-row flex-column">
                    <div>
                      <p className="dashboard-text-2 mb-0">Level {userlevelquery}</p>
                    </div>
                  </div>
                </div>
              </div>
              {authUserData != null && authUserData.parentId != 0 ? (
                <div className="card dashboard-gold-inner-card-1 upline-details w-50 m-auto mb-3">
                  <button className="dashboard-button-1" onClick={() => clickHandlerId(authUserData.parentId)}>ID {authUserData.parentId}</button>
                </div>
              ) : (
                ""
              )}

              <div className="row p-2 g-4">
                <div className="col-lg-2 col-6 order-lg-1 order-1">
                  <div className="card dashboard-gold-inner-card-1 h-100">
                    <i className="fa-solid fa-chevron-left"></i>
                  </div>
                </div>
                <div className="col-lg-8 order-lg-2 order-3">
                  <div className="card dashboard-gold-inner-card-2">
                    <div className="d-flex flex-row justify-content-between">
                      <p
                        className="dashboard-text-2 mb-0"
                        style={{ opacity: "0.6" }}
                      >
                        Lvl {userlevelquery}
                      </p>
                        <p className="dashboard-text-2 mb-0">ID {userid || USERID}</p>
                      <p className="dashboard-text-2 mb-0">
                        <img
                          className="dashboard-gold-inner-img-1"
                          src={imglogo}
                          alt="logo"
                        />{" "}
                        <span style={{ opacity: "0.8" }}>{contractlvlprice}</span>
                      </p>
                    </div>
                    <div className="xcore-inner-total-grid-overrall">
                      <div className="xcore-inner-total-grid-2">
                        <div className="grid-dashboard-xcore-inner-inner-l2-container">
                          {primaryRefcontractData.length > 0 &&
                            primaryRefcontractData.map((data, index) => {
                              return (
                                <>
                                  <div div onClick={() => clickHandler(data.refId)} key={index} className={`dashboard-user-xcore-inner-all-level-2-programs ${data !== 0 ? 'dashboard-user-xcore-inner-programs-active' : ''}`}>
                                    {data === 0 ? '0' : parseInt(data.refId)}
                                  </div>
                                </>
                              );
                            })}
                        </div>
                      </div>

                      <div className="xcore-inner-total-grid-1">
                        <div className="grid-dashboard-xcore-inner-l1-container">
                          {SecondaryRefcontractData.length >= 0 &&
                            SecondaryRefcontractData.map((data, index) => {
                              return (
                                <>
                                  <div onClick={() => clickHandler(data.refId)} key={index} className={`dashboard-user-xcore-inner-all-level-programs ${data !== 0 ?
                                      'dashboard-user-xcore-inner-programs-active' : ''}`}>
                                    {data === 0 ? '0' : parseInt(data.refId)}
                                  </div>
                                </>
                              );
                            })}
                        </div>
                      </div>
                    </div>

                    <div className="d-flex flex-lg-row mt-5 gap-4">
                      <div>
                        <p className="mb-1">Partners</p>
                        <p className="mb-0">
                          <i className="fa-solid fa-user-group me-2"></i>{usrpartners}
                        </p>
                      </div>
                      <div>
                        <p className="mb-1">Cycles</p>
                        <p className="mb-0">
                          <i className="fa-solid fa-arrows-rotate me-2"></i>{UserCyclesLength}
                        </p>
                      </div>
                      <div className="ms-lg-auto">
                        <p className="mb-1">Total level revenue</p>
                        <p className="mb-0">
                          <i className="fa-solid fa-wallet me-2"></i>{usrrevenues}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-6 order-lg-3 order-2">
                  <div className="card dashboard-gold-inner-card-1 h-100">
                    <i className="fa-solid fa-chevron-right"></i>
                  </div>
                </div>
              </div>
              <div className="row p-2 g-4 justify-content-center">
                <div className="col-lg-8">
                  <div className="card dashboard-gold-inner-card-3">
                    <div className="d-flex flex-row justify-content-center gap-4">
                      <div className="">
                        <a onClick={changeCyclesdiagrampre}>
                          <p className="mb-0 dashboard-text-4">
                            1 <i className="fa-solid fa-chevron-down ms-1"></i>
                          </p>
                        </a>
                      </div>
                      <div className="">
                        <p className="mb-0 dashboard-text-4">Cycle: {UserCyclesLength}</p>
                      </div>
                      <div className="">
                        <a onClick={changeCyclesdiagramnext}>
                          <p className="mb-0 dashboard-text-4">
                            2 <i className="fa-solid fa-chevron-up ms-1"></i>
                          </p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row p-2 g-4">
                <div className="col-lg-12">
                  <div className="card dashboard-x3-card-2 mt-4">
                    <table className="table text-white">
                      <thead className="">
                        <tr>
                          <th scope="col">Type</th>
                          <th scope="col">Date</th>
                          <th scope="col">ID</th>
                          <th scope="col">Level</th>
                          <th scope="col">Wallet</th>
                          <th scope="col">USDT</th>
                        </tr>
                      </thead>
                      <tbody>
                        {jsondatauser.length >= 0 &&
                          jsondatauser.map((data,index) => {
                            // if (refUser.userid == 0 || refUser.userId == 0) {
                            //   return (
                            //     <>
                            //       <tr>
                            //         <td>
                            //           <i className="fa-solid fa-user dashboard-activity-1"></i>
                            //         </td>
                            //         <td>0</td>
                            //         <td>0</td>
                            //         <td>0</td>
                            //         <td>...</td>
                            //         <td>0</td>
                            //       </tr>
                            //     </>
                            //   );
                            // }
                            return (
                              <>
                                <tr>
                                  <td>
                                    <i className="fa-solid fa-user dashboard-activity-1"></i>
                                  </td>
                                  <td>
                                    {data.datetime}
                                  </td>
                                  <td>
                                    <button className="dashboard-button-1" onClick={() => clickHandler(data.refId)}>
                                      ID {data.id}
                                    </button>
                                  </td>
                                  <td>{data.level}</td>
                                  <td>
                                    <div
                                      className="ms-auto d-flex align-items-center gap-2"
                                      style={{ fontsize: "13px" }}
                                    >
                                      <span>
                                        {data.refaddress.substring(0, 5) +
                                          "..." +
                                          data.refaddress.substring(
                                            data.refaddress.length - 4
                                          )}
                                      </span>
                                    </div>
                                  </td>
                                  <td>{data.amount} USDT</td>
                                </tr>
                              </>
                            );
                          })}
                      </tbody>
                    </table>
                    <button
                      className="btn dashboard-activity-seemore-btn mt-2py-2"
                      type="button"
                      onClick={seeMoreUsers}
                    >
                      <i className="fa-solid fa-eye"></i> Seemore
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Metakingxcoreinner;