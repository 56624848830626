import "../assets/css/dashboard-style.css"

import Topnav from "../Dashboard-pages/Separate/Top-nav";
import Sidenav from "../Dashboard-pages/Separate/Side-nav";

function Stats() {
    return (
        <div className="App">
            <Topnav />
            <section className="dashboard-split-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 dashboard-split-left-section d-lg-block d-none">
                            <Sidenav />
                        </div>
                        <div className="col-lg-10 dashboard-split-right-section">
                            <div className="row p-2 g-4">
                                <div className="col-lg-12">
                                    <div className="d-flex flex-row align-items-center">
                                        <div>
                                            <span className="mb-0 dashboard-text-2">Stats</span><button
                                                className="ms-3 dashboard-button-1">ID 175</button>
                                        </div>
                                        {/* <!-- <div className="ms-auto">
                                    <button className="ms-3 dashboard-button-1"><i
                                            className="fa-solid fa-filter me-2"></i>Filters</button>
                                </div> --> */}
                                    </div>

                                </div>
                            </div>
                            <div className="row p-2 g-4">
                                <div className="col-lg-12">
                                    <div className="dashboard-overall-partners-table">


                                        <table id="datatable-example" className="datatable-2-example" >
                                            <thead className="">
                                                <tr>
                                                    <th scope="col">Type</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">ID</th>
                                                    <th scope="col">Program</th>
                                                    <th scope="col">Level</th>
                                                    <th scope="col">Wallet</th>
                                                    <th scope="col">USDT / BNB profit</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><i className="fa-solid fa-user dashboard-activity-1"></i></td>
                                                    <td>10.07.2023 05:03</td>
                                                    <td><button className="dashboard-button-1">ID 133275</button></td>
                                                    <td>xGold</td>
                                                    <td>2</td>
                                                    <td>
                                                        <div className="ms-auto d-flex align-items-center gap-2"
                                                            style={{ fontsize: "13px" }}>
                                                            <span>0x5ac...B97</span>
                                                            <button className="border-0 bg-transparent text-white"><i
                                                                className="fa-solid fa-copy"></i></button>
                                                            <button className="border-0 bg-transparent text-white"><i
                                                                className="fa-solid fa-link"></i></button>
                                                        </div>
                                                    </td>
                                                    <td>20 USDT</td>

                                                </tr>


                                            </tbody>


                                        </table>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Stats;