import "../assets/css/dashboard-style.css";

// import Metalogo from "../assets/images/images-dash/meta-king-logo.png"

import Topnav from "../Dashboard-pages/Separate/Top-nav";
import Sidenav from "../Dashboard-pages/Separate/Side-nav";

import unknownuser from "../assets/images/UnknownUser.webp";
import bnbcoin from "../assets/images/bnb.png";
import finalgif from "../assets/images/final.gif";

import { FaCopy } from "react-icons/fa";
import { AiFillQuestionCircle } from "react-icons/ai";
import { FaArrowUp } from "react-icons/fa";
import { PiArrowSquareOutBold } from "react-icons/pi";
import { ImArrowRight2 } from "react-icons/im";
import {
  finduserAmount1,
  getRecentUsers,
  getTotalAmtAndAcc,
  getUserDataAPI,
  getUserDataAPIUsingId,

} from "./api/authapis";
import { useEffect, useState } from "react";
import config from "../config/apiConfig";
import moment from "moment";
import copy from "copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { LiaSnowflake } from "react-icons/lia";
import { BiSolidErrorCircle } from "react-icons/bi";
import Web3 from "web3";
import xcoreABI from "../ABI/xcore.json";
import DashboardRenderer from "./DashboardRenderer";
import { xcoreRequestCycle } from "../xcorecycle/xcore-inner-cycle";
import { data } from "jquery";

function App() {
  const [userData, setUserData] = useState("");
  const [addr, setAddr] = useState("");
  const [addrcpy, setAddrcpy] = useState("");
  const [address, setAddress] = useState("");
  const [id, setId] = useState("");
  const [xcorecontract, setXcoreContract] = useState("");
  const [levelPlanContract, setLevelPlanContract] = useState("");
  const [xCore, setXcore] = useState("");
  const [levelIncome, setLevelIncome] = useState("");
  const [totalTransactionAmt, setTotalTransactionAmt] = useState(0);
  const [allLevelIncomes, setAllLevelIncomes] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userid = queryParams.get("user");
  const loginUserId = localStorage.getItem("userID");
  const [recentUsers, setRecentUsers] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAccount, setTotalAccount] = useState(0);
  const [profitsdetails, setprofitsdetails] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [userDetailsFunctionAllow, setUserDetailsFunctionAllow] = useState(false);
  const [level2Status, setLevel2Status] = useState(false);
  const [level3Status, setLevel3Status] = useState(false);
  const [level4Status, setLevel4Status] = useState(false);
  const [level5Status, setLevel5Status] = useState(false);
  const [level6Status, setLevel6Status] = useState(false);
  const [level7Status, setLevel7Status] = useState(false);
  const [level8Status, setLevel8Status] = useState(false);
  const [level9Status, setLevel9Status] = useState(false);
  const [level10Status, setLevel10Status] = useState(false);
  const [registerAmountUser, setRegisterAmountUser] = useState('');
  const [findTeams, setFindTeams] = useState(0)
  const [findPartner, setFindPartner] = useState(0)
  const USERADDRESS = localStorage.getItem("address");
  const [ratio, setRatio] = useState()


  const [primaryRef1contractData, setprimaryRef1contractData] = useState([]);
  const [SecondaryRef1contractData, setSecondaryRef1contractData] = useState(
    []
  );
  const [primaryRef2contractData, setprimaryRef2contractData] = useState([]);
  const [SecondaryRef2contractData, setSecondaryRef2contractData] = useState(
    []
  );


  const [primaryRef3contractData, setprimaryRef3contractData] = useState([]);
  const [SecondaryRef3contractData, setSecondaryRef3contractData] = useState(
    []
  );

  const [primaryRef4contractData, setprimaryRef4contractData] = useState([]);
  const [SecondaryRef4contractData, setSecondaryRef4contractData] = useState(
    []
  );

  const [primaryRef5contractData, setprimaryRef5contractData] = useState([]);
  const [SecondaryRef5contractData, setSecondaryRef5contractData] = useState(
    []
  );

  const [primaryRef6contractData, setprimaryRef6contractData] = useState([]);
  const [SecondaryRef6contractData, setSecondaryRef6contractData] = useState(
    []
  );

  const [primaryRef7contractData, setprimaryRef7contractData] = useState([]);
  const [SecondaryRef7contractData, setSecondaryRef7contractData] = useState(
    []
  );

  const [primaryRef8contractData, setprimaryRef8contractData] = useState([]);
  const [SecondaryRef8contractData, setSecondaryRef8contractData] = useState(
    []
  );

  const [primaryRef9contractData, setprimaryRef9contractData] = useState([]);
  const [SecondaryRef9contractData, setSecondaryRef9contractData] = useState(
    []
  );

  const [primaryRef10contractData, setprimaryRef10contractData] = useState([]);
  const [SecondaryRef10contractData, setSecondaryRef10contractData] = useState(
    []
  );

  const userId = () => {
    const token = localStorage.getItem("userToken");
    if (token) {
      let userid = localStorage.getItem("userID");
      setId(userid);
    } else {
      console.log("Error");
    }
  };

  const userAddress = () => {
    const token = localStorage.getItem("userToken");
    const address = localStorage.getItem("address");
    setAddress(address);
    if (token) {
      const Address =
        address.substring(0, 5) + "..." + address.substring(address.length - 4);
      setAddr(Address);
    } else {
      console.log("Error");
    }
  };

  const getXcoreAndLevelPlanTotalAmt = async () => {
    try {
      let web3 = new Web3(config.rpcUrls);
      let xcorecontract = await new web3.eth.Contract(
        xcoreABI,
        config.xcorecontract
      );
      // console.log('============================',xcorecontract)
      // console.log("2@@@@@@@@@@@@@@@@@@@@@@@@@",window.ethereum);
      let coreAndLevTotAmt = await xcorecontract.methods
        .XcoreAndLevelContractTotAmount()
        .call();
      let totalForDisplay = await web3.utils.fromWei(
        coreAndLevTotAmt,
        "ether"
      );
      let contractBasedTotAmt = totalForDisplay / 2;
      let totalProfits = totalForDisplay;
      // setprofitsdetails(totalProfits);
      // setTotalTransactionAmt(contractBasedTotAmt);
    } catch (error) {
      console.log("getXcoreAndLevelPlanTotalAmt error", error);
    }
  };

  const getUserData = async () => {
    let userAddress = localStorage.getItem("address");
    if (userAddress) {
      let userData = await getUserDataAPI(userAddress.toLowerCase());
      if (userData.status) {
        setUserData(userData.data);
      }
    }
  };

  // console.log(userid, "userid");
  const getUserDataUsingId = async (id) => {
    let userData = await getUserDataAPIUsingId(id);
    // console.log(userData, "==================user");
    if (userData.status) {
      setUserData(userData.data);
      setId(userData.data.userId);
      let addr = userData.data.address;
      setAddrcpy(addr);
      let Addr = addr.substring(0, 5) + "..." + addr.substring(addr.length - 4);
      setAddr(Addr);
    } else {
      // toast.error("User not found");
      // navigate(`/login`);
    }
  };

  const clickHandler = (parentId) => {
    navigate(`/dashboard?user=${parentId}`);
    window.location.reload();
  };

  const clickHandlerXcore = (userid) => {
    if (userid) {
      navigate(`/metakingxcore?user=${userid}`);
      window.location.reload();
    } else {
      navigate("/metakingxcore");
    }
  };
  const clickHandlerlevIncome = (userid) => {
    if (userid) {
      navigate(`/levelincome?user=${userid}`);
      window.location.reload();
    } else {
      navigate("/levelincome");
    }
  }

  const RankSubmit = async (userid) => {
    if (userid) {
      navigate(`/rankincome?user=${userid}`);
    }
    else {
      navigate('/rankincome')
    }

  }

  const copyAddress = () => {
    copy(addrcpy);
    toast.success("Copy to clipboard");
    // toast.success(`Copy to clipboard "${address}"`);
  };
  const copyLink = () => {
    let refLink = config.FRONTEND_URL + `registration/${id}`;
    copy(refLink);
    toast.success("Copy to clipboard");
  };

  const contract = () => {
    let xCoreContract = config.xcorecontract;
    setXcoreContract(xCoreContract);
    let xcore =
      xCoreContract.substring(0, 5) +
      "..." +
      xCoreContract.substring(xCoreContract.length - 4);
    setXcore(xcore);
    let levelContract = config.levelplancontract;
    setLevelPlanContract(levelContract);
    let levelincome =
      levelContract.substring(0, 5) +
      "..." +
      levelContract.substring(levelContract.length - 4);
    setLevelIncome(levelincome);
  };

  const copyXcore = () => {
    copy(xcorecontract);
    toast.success("Copy to clipboard");
    // toast.success(`Copy to clipboard "${xcorecontract}"`);
  };

  const copyLevel = () => {
    copy(levelPlanContract);
    toast.success("Copy to clipboard");
    // toast.success(`Copy to clipboard "${levelPlanContract}"`);
  };
  const seeMoreUsers = async () => {
    let pageNum = pageNumber;
    setPageNumber(pageNum + 1);
    try {
      const response = await getRecentUsers(pageNumber);
      if (response.status) {
        setRecentUsers(response.data);
      }
    } catch (error) {
      console.log(error, "error");
      // toast.error("Something went wrong");
    }
  };

  const getRecentUser = async () => {
    try {
      const response = await getRecentUsers(1);
      if (response.status) {
        setRecentUsers(response.data);
      }
    } catch (error) {
      console.log(error, "error");
      // toast.error("Something went wrong");
    }
  };

  const getTotAccAndAmt = async () => {
    try {
      const response = await getTotalAmtAndAcc();
      if (response.status) {
        // console.log(response.data[0].total,"response totl data");
        setTotalAmount(response.data[0].total);
        setTotalAccount(response.data[0].count);
      }

    } catch (error) {
      console.log(error, "error");
      // // toast.error("Something went wrong");
    }
  };

  const formatTimeDifference = (timestamp) => {
    const currentTime = new Date();
    const timeDifference = Math.floor(
      (currentTime - new Date(timestamp)) / 1000
    );

    if (timeDifference < 60) {
      return `${timeDifference} seconds ago`;
    } else if (timeDifference < 3600) {
      return `${Math.floor(timeDifference / 60)} minutes ago`;
    } else if (timeDifference < 86400) {
      return `${Math.floor(timeDifference / 3600)} hours ago`;
    } else {
      return `${Math.floor(timeDifference / 86400)} days ago`;
    }
  };

  useEffect(() => {
    const setaadd = async () => {
      let web3 = new Web3(config.rpcUrls);
      let xcorecontract = await new web3.eth.Contract(
        xcoreABI,
        config.xcorecontract
      );

      if (userid == null) {



        let xcores1 = await xcoreRequestCycle({
          useradd: USERADDRESS,
          lvls: 1,
          usrlevels: 1,
        });
        setSecondaryRef1contractData(xcores1.usrnewcy2);
        setprimaryRef1contractData(xcores1.usrnewcy1);


        let xcores2 = await xcoreRequestCycle({
          useradd: USERADDRESS,
          lvls: 1,
          usrlevels: 2,
        });

        setSecondaryRef2contractData(xcores2.usrnewcy2);
        setprimaryRef2contractData(xcores2.usrnewcy1);

        let xcores3 = await xcoreRequestCycle({
          useradd: USERADDRESS,
          lvls: 1,
          usrlevels: 3,
        });

        setSecondaryRef3contractData(xcores3.usrnewcy2);
        setprimaryRef3contractData(xcores3.usrnewcy1);

        let xcores4 = await xcoreRequestCycle({
          useradd: USERADDRESS,
          lvls: 1,
          usrlevels: 4,
        });


        setSecondaryRef4contractData(xcores4.usrnewcy2);
        setprimaryRef4contractData(xcores4.usrnewcy1);

        let xcores5 = await xcoreRequestCycle({
          useradd: USERADDRESS,
          lvls: 1,
          usrlevels: 5,
        });

        setSecondaryRef5contractData(xcores5.usrnewcy2);
        setprimaryRef5contractData(xcores5.usrnewcy1);

        let xcores6 = await xcoreRequestCycle({
          useradd: USERADDRESS,
          lvls: 1,
          usrlevels: 6,
        });

        setSecondaryRef6contractData(xcores6.usrnewcy2);
        setprimaryRef6contractData(xcores6.usrnewcy1);

        let xcores7 = await xcoreRequestCycle({
          useradd: USERADDRESS,
          lvls: 1,
          usrlevels: 7,
        });

        setSecondaryRef7contractData(xcores7.usrnewcy2);
        setprimaryRef7contractData(xcores7.usrnewcy1);

        let xcores8 = await xcoreRequestCycle({
          useradd: USERADDRESS,
          lvls: 1,
          usrlevels: 8,
        });

        setSecondaryRef8contractData(xcores8.usrnewcy2);
        setprimaryRef8contractData(xcores8.usrnewcy1);

        let xcores9 = await xcoreRequestCycle({
          useradd: USERADDRESS,
          lvls: 1,
          usrlevels: 9,
        });

        setSecondaryRef9contractData(xcores9.usrnewcy2);
        setprimaryRef9contractData(xcores9.usrnewcy1);

        let xcores10 = await xcoreRequestCycle({
          useradd: USERADDRESS,
          lvls: 1,
          usrlevels: 10,
        });

        setSecondaryRef10contractData(xcores10.usrnewcy2);
        setprimaryRef10contractData(xcores10.usrnewcy1);
      } else {
        let userData = await getUserDataAPI(userid);
        partnerFind(userData.data.address);
        if (userData.status) {
          let usrlvl2 = await xcorecontract.methods
            .usercompletelevel(userData.data.address, 2)
            .call();
          let usrlvl3 = await xcorecontract.methods
            .usercompletelevel(userData.data.address, 3)
            .call();
          let usrlvl4 = await xcorecontract.methods
            .usercompletelevel(userData.data.address, 4)
            .call();
          let usrlvl5 = await xcorecontract.methods
            .usercompletelevel(userData.data.address, 5)
            .call();
          let usrlvl6 = await xcorecontract.methods
            .usercompletelevel(userData.data.address, 6)
            .call();
          let usrlvl7 = await xcorecontract.methods
            .usercompletelevel(userData.data.address, 7)
            .call();
          let usrlvl8 = await xcorecontract.methods
            .usercompletelevel(userData.data.address, 8)
            .call();
          let usrlvl9 = await xcorecontract.methods
            .usercompletelevel(userData.data.address, 9)
            .call();
          let usrlvl10 = await xcorecontract.methods
            .usercompletelevel(userData.data.address, 10)
            .call();
          if (usrlvl2 === true) {
            setLevel2Status(true);
          }
          if (usrlvl3 === true) {
            setLevel3Status(true);
          }
          if (usrlvl4 === true) {
            setLevel4Status(true);
          }
          if (usrlvl5 === true) {
            setLevel5Status(true);
          }
          if (usrlvl6 === true) {
            setLevel6Status(true);
          }
          if (usrlvl7 === true) {
            setLevel7Status(true);
          }
          if (usrlvl8 === true) {
            setLevel8Status(true);
          }
          if (usrlvl9 === true) {
            setLevel9Status(true);
          }
          if (usrlvl10 === true) {
            setLevel10Status(true);
          }

          // setAddressstate(userData.data.address);
          let xcores1 = await xcoreRequestCycle({
            useradd: userData.data.address,
            lvls: 1,
            usrlevels: 1,
          });


          setSecondaryRef1contractData(xcores1.usrnewcy2);
          setprimaryRef1contractData(xcores1.usrnewcy1);

          let xcores2 = await xcoreRequestCycle({
            useradd: userData.data.address,
            lvls: 1,
            usrlevels: 2,
          });

          setSecondaryRef2contractData(xcores2.usrnewcy2);
          setprimaryRef2contractData(xcores2.usrnewcy1);

          let xcores3 = await xcoreRequestCycle({
            useradd: userData.data.address,
            lvls: 1,
            usrlevels: 3,
          });

          setSecondaryRef3contractData(xcores3.usrnewcy2);
          setprimaryRef3contractData(xcores3.usrnewcy1);

          let xcores4 = await xcoreRequestCycle({
            useradd: userData.data.address,
            lvls: 1,
            usrlevels: 4,
          });

          setSecondaryRef4contractData(xcores4.usrnewcy2);
          setprimaryRef4contractData(xcores4.usrnewcy1);

          let xcores5 = await xcoreRequestCycle({
            useradd: userData.data.address,
            lvls: 1,
            usrlevels: 5,
          });

          setSecondaryRef5contractData(xcores5.usrnewcy2);
          setprimaryRef5contractData(xcores5.usrnewcy1);

          let xcores6 = await xcoreRequestCycle({
            useradd: userData.data.address,
            lvls: 1,
            usrlevels: 6,
          });
          setSecondaryRef6contractData(xcores6.usrnewcy2);
          setprimaryRef6contractData(xcores6.usrnewcy1);

          let xcores7 = await xcoreRequestCycle({
            useradd: userData.data.address,
            lvls: 1,
            usrlevels: 7,
          });
          setSecondaryRef7contractData(xcores7.usrnewcy2);
          setprimaryRef7contractData(xcores7.usrnewcy1);

          let xcores8 = await xcoreRequestCycle({
            useradd: userData.data.address,
            lvls: 1,
            usrlevels: 8,
          });

          setSecondaryRef8contractData(xcores8.usrnewcy2);
          setprimaryRef8contractData(xcores8.usrnewcy1);

          let xcores9 = await xcoreRequestCycle({
            useradd: userData.data.address,
            lvls: 1,
            usrlevels: 9,
          });

          setSecondaryRef9contractData(xcores9.usrnewcy2);
          setprimaryRef9contractData(xcores9.usrnewcy1);

          let xcores10 = await xcoreRequestCycle({
            useradd: userData.data.address,
            lvls: 1,
            usrlevels: 10,
          });

          setSecondaryRef10contractData(xcores10.usrnewcy2);
          setprimaryRef10contractData(xcores10.usrnewcy1);

        }
      }
    };

    setaadd();
  }, [userid]);

  const userDetails = async (userid) => {
    try {
      const finduserAmount = await finduserAmount1(userid)
      const userRegisterAmount = await finduserAmount.data[0].amount
      setRegisterAmountUser(userRegisterAmount)
    } catch (error) {
      console.log("error=", error);
    }

  }

  const details1 = [
    [...primaryRef1contractData], [...SecondaryRef1contractData],
    [...primaryRef2contractData], [...SecondaryRef2contractData],
    [...primaryRef3contractData], [...SecondaryRef3contractData],
    [...primaryRef4contractData], [...SecondaryRef4contractData],
    [...primaryRef5contractData], [...SecondaryRef5contractData],
    [...primaryRef6contractData], [...SecondaryRef6contractData],
    [...primaryRef7contractData], [...SecondaryRef7contractData],
    [...primaryRef8contractData], [...SecondaryRef8contractData],
    [...primaryRef9contractData], [...SecondaryRef9contractData],
    [...primaryRef10contractData], [...SecondaryRef10contractData],
  ]
  const partnerFind = async (address) => {

    let web3 = new Web3(config.rpcUrls);
    let xcorecontract = await new web3.eth.Contract(
      xcoreABI,
      config.xcorecontract
    );
    const directPartner = await xcorecontract.methods.directreferral(address).call();
    setFindPartner(directPartner.length)
  }

  const userAllDetails = async (details1) => {
    let userDetailsGetTeam = []
    let userDetailsGetRatio = []
    if (primaryRef1contractData[0] !== "0") {
      let web3 = new Web3(config.rpcUrls);
      const details2 = details1.flat(1)
      // console.log("22222primaryRef2contractData=======", details2);
      const details3 = details2.map((data) => data)
      details3.map((element) => {
        if (Array.isArray(element)) {
          userDetailsGetTeam.push(element[1])
          let ratioDetails = {
            userId: element[1],
            userLevel: element[3],
            amount: element[4]
          }
          userDetailsGetRatio.push(ratioDetails)

        } else {
          return [element];
        }
      }
      );
      const primaryRef1contractData1 = primaryRef1contractData.map((data) => data)
      let xcoreValue1;
      const secValue = SecondaryRef1contractData.map((data) => {
        if (data.amount) {

          const webValue = web3.utils.fromWei(data.amount, "ether")
          return webValue
        } else {
          console.log("no");
          return 0; // Handle the case where data.amount is not valid
        }
      });
      console.log("ok===", primaryRef1contractData1)
      const xcoreValue = primaryRef1contractData1.map((data) => {
        if (data.amount) {
          const webValue = web3.utils.fromWei(data.amount, "ether")
          return webValue + secValue
        } else {
          console.log("no");
          return 0; // Handle the case where data.amount is not valid
        }
      });
      console.log("xcoreValue======*===================", xcoreValue);
      if (Object.keys(xcoreValue).length >= 2) {
        xcoreValue1 = xcoreValue.reduce((acc, currentValue) => acc + parseFloat(currentValue), 0)
      } else {
        xcoreValue1 = xcoreValue
      }
      console.log("@@@@@==", typeof xcoreValue1, "=", xcoreValue1, "--", typeof xcoreValue, "===", xcoreValue);
      const amount = userDetailsGetRatio.map((data) => web3.utils.fromWei(data.amount, "ether"))
      const sum = amount.reduce((acc, currentValue) => acc + parseFloat(currentValue), 0) + registerAmountUser;
      const sum1 = amount.reduce((acc, currentValue) => acc + parseFloat(currentValue), 0);
      const ratio1 = (sum / registerAmountUser) * 100;
      const ratioIntValue = Math.floor(ratio1)
      // const xcore = parseFloat(xcoreValue)
      setRatio(ratioIntValue)
      setFindTeams(userDetailsGetTeam.length)
      setTotalTransactionAmt(Number(xcoreValue1.toFixed(10)))
      setAllLevelIncomes(sum1)
      setprofitsdetails(sum1 + Number(xcoreValue1.toFixed(10)))
      setUserDetailsFunctionAllow(false)
    }
    setUserDetailsFunctionAllow(false)
  }

  // const check = primaryRef10contractData.map((data) => data[1])
  // const hasUndefinedValue = check.some(item => item === undefined );
  // const isEmptyArray = check.length === 0;
  // if (level10Status === true && userDetailsFunctionAllow === true && hasUndefinedValue === true || check.length > 0 ) {
  //   console.log("work");
  //   userAllDetails(details1)
  // }
  SecondaryRef10contractData.map((data) => {
    if (userDetailsFunctionAllow === true && data >= 0) {
      // console.log("work",details1);
      userAllDetails(details1)
    }
  })

  useEffect(() => {
    if (userid == null && loginUserId !== "") {
      getUserDataUsingId(loginUserId);
      // xcoreActiveLevel(loginUserId)
    } else if (userid >= 1) {
      getUserDataUsingId(userid);
      // xcoreActiveLevel(userid)
    }
    userId();
    userAddress();
    contract();
    getXcoreAndLevelPlanTotalAmt();
    getRecentUser();
    getTotAccAndAmt();
    getUserData();
    if (registerAmountUser === '') {
      userDetails(userid)
    }
    setUserDetailsFunctionAllow(true)
  }, []);




  return (
    <div className="App">
      {/* <Topnav  */}
      <Topnav/>
      <ToastContainer />

      <section className="dashboard-split-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 dashboard-split-left-section d-lg-block d-none">
              <Sidenav  userid={userid}/>
            </div>
            <div className="col-lg-10 dashboard-split-right-section">
              <div className="row p-2 g-4">
                <div className="col-lg-6">
                  <div className="d-flex flex-lg-row flex-column align-items-center">
                    <div className="me-lg-4">
                      <div className="d-flex flex-row align-items-end">
                        <img
                          className="dashboard-user-profile"
                          src={unknownuser}
                          alt="User-profile"
                        />
                        <img
                          className="dashboard-user-coin-logo"
                          src={bnbcoin}
                          alt="coin-logo"
                        />
                      </div>
                    </div>
                    <div>
                      <p className="mb-0 dashboard-text-2">ID {id}</p>
                      <p className="mb-1 dashboard-text-3">
                        {addr}
                        <button
                          className="ms-2 bg-transparent border-0 text-white"
                          onClick={copyAddress}
                        >
                          <FaCopy />
                        </button>
                      </p>
                      {userData.parentId == 0 ? (
                        <span className="mb-0 dashboard-text-4">
                          {moment.utc(userData.date).format("YYYY-MM-DD")}
                        </span>
                      ) : (
                        <span className="mb-0 dashboard-text-4">
                          invited{" "}
                          {moment.utc(userData.date).format("YYYY-MM-DD")} by
                        </span>
                      )}
                      {userData.parentId >= 1 ? (
                        <button
                          className="ms-3 dashboard-button-1"
                          onClick={() => clickHandler(userData.parentId)}
                        >
                          ID {userData.parentId}
                        </button>
                      ) : (
                        <span></span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card dashboard-card-1">
                    <div className="dashboard-card-1-inner-1">
                      <p className="dashboard-text-4 mb-2">
                        Personal link{" "}
                        <span className="ms-1 ">
                          <AiFillQuestionCircle
                            className=""
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-custom-className="custom-tooltip"
                            data-bs-title="This top tooltip is themed via CSS variables."
                          />
                        </span>
                      </p>

                      {config.FRONTEND_URL + `registration/${id}`}
                      <button
                        className="ms-2 bg-transparent border-0 text-white"
                        onClick={copyLink}
                      >
                        <FaCopy />
                      </button>

                      {/* <button className="bg-transparent text-white border-0" style={{ width: "fit-content" }}><FaCopy /></button> */}
                    </div>
                    {/* <div className="dashboard-card-1-inner-2 d-flex flex-lg-row flex-column gap-2">
                      <div>
                        <p className="dashboard-text-4 mb-0">
                          Not a member yet? Sign up with this upline
                        </p>
                      </div>
                      <div className="ms-lg-auto">
                        <button className="dashboard-button-2">Sign up</button>
                      </div>
                    </div> */}
                    <div className="dashboard-card-1-inner-3 d-flex flex-lg-row flex-column gap-2">
                      <div>
                        <p className="mb-0 dashboard-text-6">
                          My xQore invite link
                        </p>
                      </div>
                      <div className="ms-lg-auto">
                        <a
                          href={config.FRONTEND_URL + `registration/${id}`}
                          target="_blank"
                          className="dashboard-text-5 mb-0"
                        >
                          <button className="ms-3 dashboard-button-1">
                            View
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-2 g-4">
                <div className="col-lg-6">
                  <div className="row g-4">
                    <div className="col-lg-4">
                      <div className="card dashboard-card-2">
                        <p className="dashboard-text-4 mb-0 ms-2">Partners</p>
                        <p className="mb-2 dashboard-text-2 ms-2">{findPartner}</p>
                        <div className="d-flex flex-row dashboard-card-2-inner-1 align-items-center">
                          <div className="dashboard-text-7">
                            <FaArrowUp className="me-1" />{findPartner}
                          </div>
                          <div className="ms-auto">
                            <img
                              className="dashboard-partners-gif"
                              src={finalgif}
                              alt="gif"

                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="card dashboard-card-2">
                        <p className="dashboard-text-4 mb-0 ms-2">Team</p>
                        <p className="mb-2 dashboard-text-2 ms-2">{findTeams}</p>
                        <div className="d-flex flex-row dashboard-card-2-inner-1 align-items-center">
                          <div className="dashboard-text-7">
                            <FaArrowUp className="me-1" />{findTeams}
                          </div>
                          <div className="ms-auto">
                            <img
                              className="dashboard-partners-gif"
                              src={finalgif}
                              alt="gif"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="card dashboard-card-2">
                        <p className="dashboard-text-4 mb-0 ms-2">Ratio</p>
                        <p className="mb-2 dashboard-text-2 ms-2">{ratio}%</p>
                        <div className="d-flex flex-row dashboard-card-2-inner-1 align-items-center">
                          <div className="dashboard-text-7">
                            <FaArrowUp className="me-1" />{ratio}%
                          </div>
                          <div className="ms-auto">
                            <img
                              className="dashboard-partners-gif"
                              src={finalgif}
                              alt="gif"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card dashboard-card-2">
                        <p className="dashboard-text-4 mb-0 ms-2 mb-3">
                          Profits
                        </p>

                        <div className="d-flex flex-row align-items-center mx-2 mb-1">
                          <div className="dashboard-text-7">
                            <p className="mb-0 dashboard-text-8">
                              {profitsdetails} USDT
                            </p>
                          </div>
                          <div className="ms-auto">
                            <p className="dashboard-text-7 mb-0">
                              <FaArrowUp className="me-1" />0
                            </p>
                          </div>
                        </div>
                        {/* <div className="d-flex flex-row align-items-center mx-2 mb-1">
                          <div className="dashboard-text-7">
                            <p className="mb-0 dashboard-text-8">0 USDT</p>
                          </div>
                          <div className="ms-auto">
                            <p className="dashboard-text-7 mb-0">
                              <FaArrowUp className="me-1" />0
                            </p>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-2 g-4 mt-1">
                <div className="d-flex flex-row align-items-center gap-3">
                  <span className="dashboard-text-2">Meta King Programs</span>
                  <button className="dashboard-button-2">
                    <AiFillQuestionCircle className="me-1" /> Info
                  </button>
                </div>

                <div className="row row-cols-1 row-cols-md-2 g-4">
                  <div className="col">
                    <div className="card dashboard-card-3 position-relative overflow-hidden h-100">
                      <div className="dashboard-user-programs-glow-1"></div>
                      <div className="card-body dashboard-card-3-inner-1">
                        <p className="text-end mb-0 opacity-50 dashboard-text-10">
                          <ImArrowRight2 />
                        </p>
                        <div className="d-flex flex-row">
                          <div>
                            <span className="dashboard-text-9">
                              {/* xXx / xGold */}
                              xCore
                            </span>
                          </div>
                          <div className="ms-auto">
                           
                            <span className="dashboard-text-9">
                              {totalTransactionAmt == 0
                                ? "0"
                                : totalTransactionAmt + " USDT"}
                            </span>
                            {/* <span className="dashboard-text-9">
                              3300
                            </span> */}
                          </div>
                        </div>

                        <div className="row align-items-end mt-4 mb-3 g-4">
                          <div className="col-lg-6 ">
                            <div className="grid-dashboard-container">
                              <div className="dashboard-user-programs dashboard-user-programs-active"></div>
                              {
                                level2Status == true ? (
                                  <div className="dashboard-user-programs dashboard-user-programs-active"></div>
                                ) : (
                                  <div className="dashboard-user-programs"></div>
                                )
                              }
                              {
                                level3Status == true ? (
                                  <div className="dashboard-user-programs dashboard-user-programs-active"></div>
                                ) : (
                                  <div className="dashboard-user-programs"></div>
                                )
                              }
                              {
                                level4Status == true ? (
                                  <div className="dashboard-user-programs dashboard-user-programs-active"></div>
                                ) : (
                                  <div className="dashboard-user-programs"></div>
                                )
                              }
                              {
                                level5Status == true ? (
                                  <div className="dashboard-user-programs dashboard-user-programs-active"></div>
                                ) : (
                                  <div className="dashboard-user-programs"></div>
                                )
                              }
                              {
                                level6Status == true ? (
                                  <div className="dashboard-user-programs dashboard-user-programs-active"></div>
                                ) : (
                                  <div className="dashboard-user-programs"></div>
                                )
                              }
                              {
                                level7Status == true ? (
                                  <div className="dashboard-user-programs dashboard-user-programs-active"></div>
                                ) : (
                                  <div className="dashboard-user-programs"></div>
                                )
                              }
                              {
                                level8Status == true ? (
                                  <div className="dashboard-user-programs dashboard-user-programs-active"></div>
                                ) : (
                                  <div className="dashboard-user-programs"></div>
                                )
                              }
                              {
                                level9Status == true ? (
                                  <div className="dashboard-user-programs dashboard-user-programs-active"></div>
                                ) : (
                                  <div className="dashboard-user-programs"></div>
                                )
                              }
                              {
                                level10Status == true ? (
                                  <div className="dashboard-user-programs dashboard-user-programs-active"></div>
                                ) : (
                                  <div className="dashboard-user-programs"></div>
                                )
                              }
                            </div>
                          </div>
                          <div className="col-lg-6 text-end">
                            {/* <a className="" href="/metakingxcore"> */}
                            <button
                              className="dashboard-preview-btn-2"
                              onClick={() => clickHandlerXcore(userid)}
                            >
                              Preview <ImArrowRight2 className="ms-1" />
                            </button>
                            {/* </a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col">
                    <div className="card dashboard-card-3 position-relative overflow-hidden h-100">
                      <div className="dashboard-user-programs-glow-1"></div>
                      <div className="card-body dashboard-card-3-inner-1 h-100">
                        <p className="text-end mb-0 opacity-50 dashboard-text-10">
                          <ImArrowRight2 />
                        </p>
                        <div className="d-flex flex-row mt-2">
                          <div>
                            <span className="dashboard-text-9">
                              Level Income
                            </span>
                          </div>
                          <div className="ms-auto">
                            <span className="dashboard-text-9">
                              {allLevelIncomes == 0
                                ? "0"
                                : allLevelIncomes + " USDT"}
                            </span>
                          </div>
                        </div>

                        <div className="row align-items-end mt-4 mb-3">
                          <div className="col-lg-12 mt-2">
                            <button
                              className="dashboard-preview-btn-2"
                              onClick={() => clickHandlerlevIncome(userid)}
                            >
                              Preview <ImArrowRight2 className="ms-1" />
                            </button>

                            {/* <a className="" href="/levelincome">
                              <button className="dashboard-preview-btn-2 mt-1">
                                Preview
                                <ImArrowRight2 className="ms-1" />
                              </button>
                            </a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col">
                    <div className="card dashboard-card-3 position-relative overflow-hidden h-100">
                      <div className="dashboard-user-programs-glow-1"></div>
                      <div className="card-body dashboard-card-3-inner-1">
                        <p className="text-end mb-0 opacity-50 dashboard-text-10">
                          <ImArrowRight2 />
                        </p>
                        <div className="d-flex flex-row">
                          <div>
                            <span className="dashboard-text-9">
                              xXx / xGold
                            </span>
                          </div>
                          <div className="ms-auto">
                            <span className="dashboard-text-7">
                              COMING SOON
                            </span>
                          </div>
                        </div>

                        <div className="row align-items-end mt-4 mb-3">
                          <div className="col-lg-12 mt-2">
                            <a className="" href="/rankincome">
                              <button className="dashboard-preview-btn-2 mt-1">
                                Preview <ImArrowRight2 className="ms-1" />
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col">
                    <div className="card dashboard-card-3 position-relative overflow-hidden h-100">
                      <div className="dashboard-user-programs-glow-1"></div>
                      <div className="card-body dashboard-card-3-inner-1 h-100">
                        <p className="text-end mb-0 opacity-50 dashboard-text-10">
                          <ImArrowRight2 className="ms-1" />
                        </p>
                        <div className="d-flex flex-row mt-2">
                          <div>
                            <span className="dashboard-text-9">
                              Rank Income
                            </span>
                          </div>
                          <div className="ms-auto">
                            <span className="dashboard-text-9">0 USDT</span>
                          </div>
                        </div>

                        <div className="row align-items-end mt-4 mb-3">
                          <div className="col-lg-12 mt-2">
                            {/* <a className="" href="/rankincome"> */}
                            <button className="dashboard-preview-btn-2 mt-1" onClick={() => RankSubmit(userid)}>
                              Preview <ImArrowRight2 className="ms-1" />
                            </button>
                            {/* </a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col">
                    {/* <div className="card dashboard-card-3 position-relative overflow-hidden h-100">
                       <div className="dashboard-user-programs-glow-1"></div>
                      <div className="card-body dashboard-card-3-inner-1 h-100">
                        <p className="text-end mb-0 opacity-50 dashboard-text-10">
                          <ImArrowRight2 />
                        </p>
                        <div className="d-flex flex-row mt-2">
                          <div>
                            <span className="dashboard-text-9">Withdraw</span>
                          </div>
                          <div className="ms-auto">
                            <span className="dashboard-text-7">
                              COMING SOON
                            </span>
                          </div>
                        </div>

                        <div className="row align-items-end mt-4 mb-3">
                          <div className="col-lg-12 mt-2">
                            <a className="" href="/withdraw">
                              <button className="dashboard-preview-btn-2 mt-1">
                                Preview
                                <ImArrowRight2 className="ms-1" />
                              </button>
                            </a>
                          </div>
                        </div>
                      </div> 
                    </div>*/}
                  </div>
                </div>
              </div>

              <div className="row p-2 g-4 mt-1">
                <div className="d-flex flex-row align-items-center gap-3">
                  <span className="dashboard-text-2">
                    Platform recent activity
                  </span>
                  <span className="ms-1 ">
                    <AiFillQuestionCircle
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-custom-className="custom-tooltip"
                      data-bs-title="This top tooltip is themed via CSS variables."
                    />
                  </span>
                </div>
                <div className="row g-4">
                  <div className="col-lg-8 see-more-users-box">
                    {recentUsers.length > 0 ? (
                      <div className="card dashboard-card-4">
                        {recentUsers.length >= 0 &&
                          recentUsers.map((user) => {
                            return (
                              <>
                                <div className="d-flex flex-lg-row flex-column align-items-center dashboard-card-4-inner-1 gap-3">
                                  <div className="d-flex flex-row align-items-center gap-2">
                                    <div>
                                      <i className="fa-solid fa-user-plus dashboard-activity-1"></i>
                                    </div>
                                    <div>
                                      <a
                                        href={`/dashboard?user=${user.userId}`}
                                        className="dashboard-button-1"
                                      >
                                        ID {user.userId}
                                      </a>
                                    </div>
                                    <div>
                                      <p className="mb-0">
                                        + 5 USDT
                                        <span className="dashboard-text-4">
                                          {" "}
                                          in
                                        </span>
                                        <span style={{ color: "#f0b90b" }}>
                                          {" "}
                                          x4
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="ms-lg-auto">
                                    <a
                                      target="blank"
                                      href={
                                        config.xcoreContarctLink +
                                        `${user.address}`
                                      }
                                    >
                                      <span className="dashboard-text-4">
                                        <PiArrowSquareOutBold className="me-2" />{" "}
                                        {formatTimeDifference(user.date)}
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        <button
                          className="btn dashboard-activity-seemore-btn mt-5"
                          type="button"
                          onClick={seeMoreUsers}
                        >
                          <i className="fa-solid fa-eye"></i> Seemore
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="col-lg-4">
                    <div className="card dashboard-card-4">
                      <p className="dashboard-text-4 mb-2">
                        Members total{" "}
                        <span className="ms-1 ">
                          <AiFillQuestionCircle
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-custom-className="custom-tooltip"
                            data-bs-title="This top tooltip is themed via CSS variables."
                          />
                        </span>
                      </p>
                      <p className="mb-1">
                        <span className="dashboard-text-9">{totalAccount}</span>
                      </p>
                      <p className="mb-2" style={{ color: "#2BEA4A" }}>
                        <FaArrowUp className="" /> 2 451
                      </p>
                    </div>

                    <div className="card dashboard-card-4 mt-4">
                      <p className="dashboard-text-4 mb-2">
                        Members received{" "}
                        <span className="ms-1 ">
                          <AiFillQuestionCircle
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-custom-className="custom-tooltip"
                            data-bs-title="This top tooltip is themed via CSS variables."
                          />
                        </span>
                      </p>
                      <p className="mb-1">
                        <span className="dashboard-text-9">
                          {totalAmount + " USDT"}
                        </span>
                      </p>
                      <p className="mb-2" style={{ color: "#2BEA4A" }}>
                        + 51 357.7 USDT
                      </p>
                      {/* <div
                        style={{ borderTop: "1px solid #333333" }}
                        className="pt-4"
                      >
                        <p className="mb-1">
                          <span className="dashboard-text-9">
                            11 779.626 BNB
                          </span>
                        </p>
                        <p className="mb-2" style={{ color: "#2BEA4A" }}>
                          + 34.634 BNB
                        </p>
                      </div> */}
                    </div>

                    <div className="card dashboard-card-4 mt-4">
                      <p className="dashboard-text-4 mb-2">
                        Meta King USDT Contracts
                      </p>
                      <p className="mb-1">
                        <span className="dashboard-text-9">
                          141 037 752.38 USDT
                        </span>
                      </p>
                      <p className="mb-2" style={{ color: "#2BEA4A" }}>
                        + 51 357.7 USDT
                      </p>

                      <div
                        style={{ borderTop: "1px solid #333333" }}
                        className="d-flex flex-row py-2 align-items-center"
                      >
                        <div>
                          <p className="dashboard-text-4 mb-0">xCore</p>
                        </div>
                        <div
                          className="ms-auto d-flex align-items-center gap-2"
                          style={{ fontSize: "13px" }}
                        >
                          <span>{xCore}</span>
                          <button
                            className="border-0 bg-transparent text-white"
                            onClick={copyXcore}
                          >
                            <FaCopy />
                          </button>
                          <a
                            href={config.xcoreContarctLink + xcorecontract}
                            target="_blank"
                            className="dashboard-text-5 mb-0"
                          >
                            <button className="border-0 bg-transparent text-white">
                              <i className="fa-solid fa-link"></i>
                            </button>
                          </a>
                        </div>
                      </div>
                      <div
                        style={{ borderTop: "1px solid #333333" }}
                        className="d-flex flex-row py-2 align-items-center"
                      >
                        <div>
                          <p className="dashboard-text-4 mb-0">Level Income</p>
                        </div>
                        <div
                          className="ms-auto d-flex align-items-center gap-2"
                          style={{ fontSize: "13px" }}
                        >
                          <span>{levelIncome}</span>
                          <button
                            className="border-0 bg-transparent text-white"
                            onClick={copyLevel}
                          >
                            <FaCopy />
                          </button>
                          <a
                            href={config.levelIncomeLink + levelPlanContract}
                            target="_blank"
                            className="dashboard-text-5 mb-0"
                          >
                            <button className="border-0 bg-transparent text-white">
                              <i className="fa-solid fa-link"></i>
                            </button>
                          </a>
                        </div>
                      </div>
                      {/* <div
                        style={{ borderTop: "1px solid #333333" }}
                        className="d-flex flex-row py-2 align-items-center"
                      >
                        <div>
                          <p className="dashboard-text-4 mb-0">x3/x4 </p>
                        </div>
                        <div
                          className="ms-auto d-flex align-items-center gap-2"
                          style={{ fontSize: "13px" }}
                        >
                          <span>0x5ac...B97</span>
                          <button className="border-0 bg-transparent text-white">
                            <FaCopy />
                          </button>
                          <button className="border-0 bg-transparent text-white">
                            <i className="fa-solid fa-link"></i>
                          </button>
                        </div>
                      </div>
                      <div
                        style={{
                          borderTop: "1px solid #333333",
                          borderBottom: "1px solid #333333;",
                        }}
                        className="d-flex flex-row py-2 align-items-center"
                      >
                        <div>
                          <p className="dashboard-text-4 mb-0">x3/x4 </p>
                        </div>
                        <div
                          className="ms-auto d-flex align-items-center gap-2"
                          style={{ fontSize: "13px" }}
                        >
                          <span>0x5ac...B97</span>
                          <button className="border-0 bg-transparent text-white">
                            <FaCopy />
                          </button>
                          <button className="border-0 bg-transparent text-white">
                            <i className="fa-solid fa-link"></i>
                          </button>
                        </div>
                      </div> */}

                      {/* <p className="dashboard-text-4 mb-2 mt-5">
                        Transactions made{" "}
                      </p>
                      <p className="mb-1">
                        <span className="dashboard-text-9">5 148 145</span>
                      </p>
                      <p className="mb-2" style={{ color: "#2BEA4A" }}>
                        + 5 424
                      </p>
                      <div
                        style={{ borderTop: "1px solid #333333;" }}
                        className="pt-2"
                      >
                        <p className="dashboard-text-4 mb-2">Turnover, BUSD </p>
                        <p className="mb-1">
                          <span className="dashboard-text-9">
                            282 075 504.76
                          </span>
                        </p>
                        <p className="mb-2" style={{ color: "#2BEA4A" }}>
                          + 102 715.4
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="dashboard-modal-section">
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
              <div class="modal-header pt-sm-5 ps-lg-5">
                <h1 class="modal-title" id="exampleModalLabel">
                  Programs marketing info
                </h1>
              </div>
              <div class="modal-body mt-auto px-lg-5">
                <div className="text-one">
                  Overview of the current programs status and progress. Click on
                  any of the programs for detailed view
                </div>
                <div className="text-one pt-2 d-flex">
                  <div className="rounded-one"></div>
                  <span className="ms-2">Activated level in the program.</span>
                </div>
                <div className="text-one pt-2 d-flex">
                  <div className="rounded-two"></div>
                  <span className="ms-2">
                    Not activated level in the the program.
                  </span>
                </div>
                <div className="text-one pt-2 d-flex">
                  <div>
                    <LiaSnowflake className="rounded-three" />
                  </div>
                  <span className="ms-2">
                    Level freeze is when the level made 1st cycle, but the next
                    level was not activated, new partners continue to get into
                    the spots but profits are missed. Upgrade to the next level
                    to unfreeze.
                  </span>
                </div>
                <div className="text-one pt-2 d-flex">
                  <div>
                    <BiSolidErrorCircle className="rounded-four" />
                  </div>
                  <span className="ms-2">
                    Level with missed profits or missed partners. Partners
                    overtook on this level and profits were missed, because the
                    level is not activated. Upgrade required to catch up with
                    partners.
                  </span>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn text-white py-2"
                  data-bs-dismiss="modal"
                >
                  Close tip
                </button>
              </div>
            </div>
          </div>
          <button
            type="button"
            class="btn-close text-white"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <AiOutlineClose className="d-lg-block d-none fs-4" />
          </button>
        </div>
      </section>
    </div>
  );
}

export default App;