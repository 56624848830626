import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
} from "react-router-dom";

import Home from "./Home";
import Dashboard from "./Dashboard-pages/Dashboard";
import Levelincome from "./Dashboard-pages/Level-income";
import Links from "./Dashboard-pages/Links";
import Metakingcalculator from "./Dashboard-pages/Metaking-calculator";
import Metakinggoldinner from "./Dashboard-pages/Metaking-gold-inner";
import Metakinginstructions from "./Dashboard-pages/Metaking-instructions";
import Metakingxcoreinner from "./Dashboard-pages/Metaking-xcore-inner";
import Metakingxcore from "./Dashboard-pages/Metaking-xcore";
import Metakingxxxinner from "./Dashboard-pages/Metaking-xxx-inner";
import Metakingxxxxgold from "./Dashboard-pages/Metaking-xxx-xgold";
// import Partners from "./Dashboard-pages/Partners";
import Rankincome from "./Dashboard-pages/Rank-income";
import Stats from "./Dashboard-pages/Stats";
import Withdraw from "./Dashboard-pages/Withdraw";
import Login from "./Login";
import Registration from "./Registration";
import $ from 'jquery'
// import ProtectedRoute from "./auth/ProductedRoute";
import DashboardRenderer from "./Dashboard-pages/DashboardRenderer";
import Editor from "./Dashboard-pages/Editor";

function App() {

  // $(document).ready(function () {
  //   $("body").tooltip({ selector: '[data-bs-toggle=tooltip]' });
  // });
  return (
    <div className="App">
      <Router>


        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/faq" element={< Editor />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/registration" element={< Registration/>} />
          <Route exact path="/registration/:parentAddress" element={< Registration/>} />
          <Route exact path="/dashboard" element={  <DashboardRenderer />} />
          {/* <Route exact path="/dashboard" element={<ProtectedRoute> <Dashboard /> </ProtectedRoute>} /> */}
          <Route exact path="/levelincome" element={<Levelincome />} />
          <Route exact path="/links" element={<Links />} />
          <Route exact path="/metakingcalculator" element={<Metakingcalculator />} />
          <Route exact path="/metakinggoldinner" element={<Metakinggoldinner />} />
          <Route exact path="/metakinginstructions" element={<Metakinginstructions />} />
          <Route exact path="/metakingxcoreinner" element={<Metakingxcoreinner />} />
          <Route exact path="/metakingxcore" element={<Metakingxcore />} />
          <Route exact path="/metakingxxxinner" element={<Metakingxxxinner />} />
          <Route exact path="/metakingxxxxgold" element={<Metakingxxxxgold />} />
          {/* <Route exact path="/partners" element={<Partners />} /> */}
          <Route exact path="/rankincome" element={<Rankincome />} />
          <Route exact path="/stats" element={<Stats />} />
          <Route exact path="/withdraw" element={<Withdraw />} />

        </Routes>
      </Router >
    </div>
  );
}

export default App;
