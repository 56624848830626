import "./assets/css/style.css";
import logo from "./assets/images/logo-img.png";
import tiggerHome from "./assets/images/Home-tiger.png";
import people from "./assets/images/people.png";
import planet from "./assets/images/planet.png";
import chat from "./assets/images/chat.png";
import book from "./assets/images/book.png";
import iconImage from "./assets/images/icon-image.png";
import cabinetPreview from "./assets/images/cabinet_preview.png";
import bannerIcon from "./assets/images/banner10-icon.png";
import loginlogo from "./assets/images/loginlogo-img.png";
import earth from "./assets/videos/earth.mp4";
import config from "./config/apiConfig";
import axios from "axios"

import { AiOutlineArrowRight } from "react-icons/ai";
import { IoWalletSharp } from "react-icons/io5";
import { RiShareBoxFill } from "react-icons/ri";
import { AiFillEye } from "react-icons/ai";
import { FaPaperPlane } from "react-icons/fa6";
import { FaArrowsRotate } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  getRecentUsers,
  getTotalAmtAndAcc,
} from "./Dashboard-pages/api/authapis";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";

function App() {
  const navigate = useNavigate();
  const [recentUsers, setRecentUsers] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAccount, setTotalAccount] = useState(0);
  const [pageNumber, setPageNumber] = useState(2);
  const [faqList, setFaqList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [bannerContent, setBannerContent] = useState([]);

  const [logoimage, setLogoimage] = useState('')
  const [links, setLinks] = useState([])

  const logoimageset = async () => {
    try {
      const response = await axios.get(config.BACKEND_URL + 'admin/getimage'); // Update with your API endpoint
      // console.log('response.data---', response.data);
      if (response.data && response.data.length > 0) {
        setLogoimage(response.data[0].logoimage); // Assuming you want to display the first image
        // setOldImageId(response.data[0]._id)
      }
    } catch (error) {
      console.error('Error fetching old image:', error);
    }
  }

  const setlinks = async () => {
    try {
      const response = await axios.get(config.BACKEND_URL + 'admin/getlink'); // Update with your API endpoint
      // console.log('response.data---', response.data);
      if (response.data && response.data.length > 0) {
        setLinks(response.data); // Assuming you want to display the first image
        // setOldImageId(response.data[0]._id)
      }
    } catch (error) {
      console.error('Error fetching old image:', error);
    }
  }

  useEffect(() => {
    fetchData();
    fetchBannerData();
    getRecentUser();
    getTotAccAndAmt();
    logoimageset()
    setlinks()
  }, []);

  function fetchData() {
    axios.get(`${config.BACKEND_URL}faq/faq-get`)
      .then((response) => {
        setFaqList(response.data.list);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching FAQ data:', error);
        setLoading(false);
      });
  }

  function fetchBannerData() {
    axios.get(`${config.BACKEND_URL}admin/getBannerContent`)
      .then((response) => {
        setBannerContent(response.data.data);
        console.log(response.data.data);
      })
      .catch((error) => {
        console.log("Error fetching banner content", error);
      })
  }

  const Login = async () => {
    if (window.ethereum) {
      navigate("/login");
    } else {
      Swal.fire({
        title: "Metamask needed",
        text: "Click the OK button to visit the link:",
        icon: "info",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          window.open(config.metmaskurl, "_blank");
        }
      });
    }
  };
  const seeMoreUsers = async () => {
    let pageNum = pageNumber;
    setPageNumber(pageNum + 1);
    try {
      const response = await getRecentUsers(pageNumber);
      if (response.status) {
        console.log("response.data", response.data);
        setRecentUsers(response.data);
      }
    } catch (error) {
      console.log(error, "error");
      // toast.error("Something went wrong");
    }
  };

  const getRecentUser = async () => {
    try {
      const response = await getRecentUsers(1);
      // console.log("response===home",response);
      if (response.status) {
        setRecentUsers(response.data);
      }
    } catch (error) {
      console.log(error, "error");
      // toast.error("Something went wrong");
    }
  };

  const getTotAccAndAmt = async () => {
    try {
      const response = await getTotalAmtAndAcc();
      // console.log("response===home",response);
      if (response.status) {
        setTotalAmount(response.data[0].total);
        setTotalAccount(response.data[0].count);
      }
    } catch (error) {
      console.log(error, "error");
      // toast.error("Something went wrong");
      // toast.error("Something went wrong");
    }
  };

  const formatTimeDifference = (timestamp) => {
    const currentTime = new Date();
    const timeDifference = Math.floor(
      (currentTime - new Date(timestamp)) / 1000
    );

    if (timeDifference < 60) {
      return `${timeDifference} seconds ago`;
    } else if (timeDifference < 3600) {
      return `${Math.floor(timeDifference / 60)} minutes ago`;
    } else if (timeDifference < 86400) {
      return `${Math.floor(timeDifference / 3600)} hours ago`;
    } else {
      return `${Math.floor(timeDifference / 86400)} days ago`;
    }
  };

  return (

    <div className="topbanner-home-section">

      <ToastContainer />
      <section className=" top-background">
        <div className="container-fluid p-lg-0 top-banner-section">
          <div className="row ">
            <div className="col px-lg-5">
              <nav className="navbar navbar-expand-lg text-white fixed-top top-banner-section">
                <div className="container-fluid">
                  <a className="navbar-brand" href="#">
                    <img className="banner-img1" src={logoimage} alt="logo" />
                    {/* <img className="banner-img1" src={loginlogo} alt="logo" /> */}
                  </a>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon banner-icon1"></span>
                  </button>
                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    {/* <div className="col">
                      <ul className="navbar-nav me-auto mb-2 mb-lg-0 ms-lg-5">
                        <li className="nav-item header-texthover">
                          <a
                            className="nav-link  active banner-text-1 header-fontsize1"
                            aria-current="page"
                            href="#"
                          >
                            Community
                          </a>
                        </li>
                        <li className="nav-item header-texthover">
                          <a
                            className="nav-link banner-text-1 header-fontsize1 "
                            href="#"
                          >
                            Documentation
                          </a>
                        </li>
                      </ul>
                    </div> */}

                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0 ">
                      <li className="nav-item me-5 button-hover rounded-pill">
                        {/* <Link className="" to="/login"> */}
                        {!localStorage.getItem("userToken") && (
                          <button
                            className="btn text-white fw-bold"
                            onClick={Login}
                          >
                            Login
                          </button>
                        )}
                        {/* </Link> */}
                      </li>
                      <li className="nav-item button-hover rounded-pill ">
                        <Link className="" to="/registration">
                          {!localStorage.getItem("userToken") && (
                            <button className="btn text-white  fw-bold ">
                              Registration
                            </button>
                          )}
                        </Link>
                      </li>
                      <li className="nav-item button-hover rounded-pill ">
                        <Link className="" to="/dashboard">
                          {localStorage.getItem("userToken") && (
                            <button className="btn text-white  fw-bold ">
                              Dashboard
                            </button>
                          )}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>

      </section>

      <section className="banner-section-two">
        <video id="video-background" autoPlay={true} loop muted poster>
          <source src={earth} type="video/mp4 " className="video_contain " />
        </video>
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6 col-12">
              <img className="banner-img2" src={logoimage} alt="logo" />
              {/* <img src={loginlogo} className="banner-img2" /> */}
            </div>
            <div className="col-lg-6 col-12">
              <p className="text-white text-tigger banner-section2-text1">
                {/* Introducing the{" "}
                <span className="gradient-color fw-semibold">Meta King</span>{" "}
                project, the world's first initiative to combine Matrix and
                Unilevel Generation structures! */}
                {bannerContent && bannerContent[0] && bannerContent[0].content}
              </p>

              {!localStorage.getItem("userToken") && (
                <Link className="" to="/login">
                  <button className="signin-hide-desk btn  py-lg-1 px-lg-5 rounded-4 banner-btn-1 text-white mb-2">
                    Sign In
                    <AiOutlineArrowRight className="text-white ms-3" />
                  </button>
                </Link>
              )}
              {!localStorage.getItem("userToken") && (
                <Link className="" to="/registration">
                  <button className="btn  py-lg-2 px-lg-5 rounded-4 banner-btn-1 text-white">
                    Registration
                    <AiOutlineArrowRight className="text-white ms-3" />
                  </button>
                </Link>
              )}
            </div>
          </div>
          <div className="row align-items-center justify-content-between">
            <div className="col col-lg-6 col-12 p-0">
              <p className="text-white fs-4 lh-base banner-section2-text2 ms-5">
                {/* A decentralized networking platform based on smart contracts,
                together with NFT technology, which{" "}
                <span className="gradient-color fw-semibold">
                  brings people together
                </span>{" "}
                from all over the world and
                <span className="gradient-color fw-semibold">
                  opens up endless possibilities{" "}
                </span>{" "}
                new economic financial systems{" "} */}
                {bannerContent && bannerContent[1] && bannerContent[1].content}
              </p>
            </div>
            <div className="col col-lg-5 col-sm-hidden p-0">
              <img src={planet} className="banner-img3" />
            </div>
          </div>
          <div className="container">
            <div className="row banner-section-2 banner-card-1">
              <div className="col col-lg-4 col-12 ">
                <div className="card">
                  <div
                    className="card-body"
                    style={{ backgroundImage: `url(${people})` }}
                  >
                    <h5 className="card-title lh-lg pt-3 ps-3">Community</h5>
                    <p className="card-text ps-3 opacity-50">
                      A platform for the exchange of experience, where each user
                      can publish their material
                    </p>
                  </div>
                </div>
              </div>

              <div className="col col-lg-4 col-12 banner-section2card-space">
                <div className="card">
                  <div
                    className="card-body"
                    style={{ backgroundImage: `url(${book})` }}
                  >
                    <h5 className="card-title lh-lg pt-3 ps-3">
                      Documentation
                    </h5>
                    <p className="card-text ps-3 opacity-50">
                      Participant learning platform
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col-lg-4 col-12 banner-section2card-space">
                <div className="card">
                  <div
                    className="card-body"
                    style={{ backgroundImage: `url(${chat})` }}
                  >
                    <h5 className="card-title lh-lg pt-3 ps-3">Live chat</h5>
                    <p className="card-text ps-3 opacity-50">
                      Platform where you can ask a question to experienced
                      participants
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {
        recentUsers.length > 0 ? (
          <section className="banner-section-3-top">
            <div className="container-fluid banner-section-3 banner-section-3-top ">
              <h1 className="text-white  banner-text-3 fw-bold fs-1 ">
                Platform Recent Activity
              </h1>
              <p className="text-white banner-section3-text4 fs-6 py-3">
                Real-time global events of the Meta King Platform
              </p>

              <div className="container banner-section3-card">
                <div
                  className="row col-lg-10 mx-auto rounded-5 see-more-users-box"
                  style={{ backgroundColor: "rgba(36,37,38)" }}
                >
                  {recentUsers.length >= 0 &&
                    recentUsers.map((user) => {
                      return (
                        <>
                          <div className="col-12">
                            <div className="d-flex flex-row align-items-center banner-border-bottom-3 p-lg-3 p-1">
                              <span>
                                <img
                                  src={iconImage}
                                  className="banner-img-4 me-3"
                                ></img>
                                <IoWalletSharp className="banner-icon-3 fs-2 rounded-circle me-3 p-2" />
                                {/* <i className="fa-solid fa-wallet banner-icon-3 fs-6 rounded-circle me-3 p-2"></i> */}
                                <a
                                  type="button"
                                  className="banner-btn-3 me-3 banner-section3-text5"
                                  href={`/dashboard?user=${user.userId}`}
                                >
                                  ID {user.userId}
                                </a>
                                <span className="text-white">
                                  <span className="opacity-50 banner-section3-text5">
                                    +
                                  </span>
                                  <span className="banner-section3-text5">
                                    0.0165 USDT{" "}
                                  </span>
                                  <span className="opacity-50 banner-section3-text5 px-3 px-lg-0">
                                    in xQore
                                  </span>
                                </span>
                              </span>
                              <a
                                className="ms-auto text-white opacity-50 banner-section3-text5"
                                target="blank"
                                href={config.xcoreContarctLink + `${user.address}`}
                              >
                                <RiShareBoxFill className="fs-5" />
                                <span> {formatTimeDifference(user.date)}</span>
                              </a>
                            </div>
                          </div>
                        </>
                      );
                    })}

                  {/* <div className="col-12">
                <div className="d-flex flex-row align-items-center banner-border-bottom-3 banner-section3-text5 p-lg-3 p-1 ">
                  <span>
                    <img src={iconImage} className="banner-img-4 me-3"></img>
                    <IoWalletSharp className="banner-icon-3 fs-2 rounded-circle me-3 p-2" />
                    <button type="button" className="banner-btn-3 me-3">
                      ID 399273
                    </button>
                    <span className="text-white">
                      <span className="opacity-50">+</span> 0.0165 USDT
                      <span className="opacity-50"> in xQore</span>
                    </span>
                  </span>
                  <div className="ms-auto text-white opacity-50">
                    <RiShareBoxFill className="fs-5" />
                    <span> 1 minute</span>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="d-flex flex-row align-items-center banner-border-bottom-3 p-lg-3 p-1 banner-section3-text5">
                  <span>
                    <img src={iconImage} className="banner-img-4 me-3"></img>
                    <IoWalletSharp className="banner-icon-3 fs-2 rounded-circle me-3 p-2" />
                    <button type="button" className="banner-btn-3 me-lg-3">
                      ID 1259311
                    </button>
                    <span className="text-white">
                      <span className="opacity-50">+</span> 0.023 USDT
                      <span className="opacity-50 px-3 px-lg-0"> in xQore</span>
                    </span>
                  </span>
                  <div className="ms-auto text-white opacity-50">
                    <RiShareBoxFill className="fs-5" />
                    <span> 1 minute</span>
                  </div>
                </div>
              </div>

              <div className="col-12 ">
                <div className="d-flex flex-row align-items-center banner-border-bottom-3 p-lg-3 p-1 banner-section3-text5">
                  <span>
                    <img src={iconImage} className="banner-img-4 me-3"></img>
                    <IoWalletSharp className="banner-icon-3 fs-2 rounded-circle me-3 p-2" />
                    <button type="button" className="banner-btn-3 me-lg-3">
                      ID 1259393
                    </button>
                    <span className="text-white">
                      <span className="opacity-50">+</span> 0.023 USDT
                      <span className="opacity-50 px-3 px-lg-0"> in xQore</span>
                    </span>
                  </span>
                  <div className="ms-auto text-white opacity-50">
                    <RiShareBoxFill className="fs-5" />
                    <span> 1 minute</span>
                  </div>
                </div>
              </div>

              <div className="col-12 ">
                <div className="d-flex flex-row align-items-center banner-border-bottom-3 p-lg-3 p-1 banner-section3-text5">
                  <span>
                    <img src={iconImage} className="banner-img-4 me-3"></img>
                    <IoWalletSharp className="banner-icon-3 fs-2 rounded-circle me-3 p-2" />
                    <button type="button" className="banner-btn-3 me-lg-3">
                      ID 1014333
                    </button>
                    <span className="text-white">
                      <span className="opacity-50">+</span> 0.009 USDT
                      <span className="opacity-50 px-3 px-lg-0"> in xQore</span>
                    </span>
                  </span>
                  <div className="ms-auto text-white opacity-50">
                    <RiShareBoxFill className="fs-5" />
                    <span> 1 minute</span>
                  </div>
                </div>
              </div>

              <div className="col-12 ">
                <div className="d-flex flex-row align-items-center banner-border-bottom-3 p-lg-3 p-1 banner-section3-text5">
                  <span>
                    <img src={iconImage} className="banner-img-4 me-3"></img>
                    <IoWalletSharp className="banner-icon-3 fs-2 rounded-circle me-3 p-2" />
                    <button type="button" className="banner-btn-3 me-lg-3">
                      ID 444746
                    </button>
                    <span className="text-white">
                      <span className="opacity-50">+</span> 0.009 USDT
                      <span className="opacity-50 px-3 px-lg-0"> in xQore</span>
                    </span>
                  </span>
                  <div className="ms-auto text-white opacity-50">
                    <RiShareBoxFill className="fs-5" />
                    <span> 1 minute</span>
                  </div>
                </div>
              </div>

              <div className="col-12 ">
                <div className="d-flex flex-row align-items-center banner-border-bottom-3 p-lg-3 p-1 banner-section3-text5">
                  <span>
                    <img src={iconImage} className="banner-img-4 me-3"></img>
                    <IoWalletSharp className="banner-icon-3 fs-2 rounded-circle me-3 p-2" />
                    <button type="button" className="banner-btn-3 me-lg-3">
                      ID 875961
                    </button>
                    <span className="text-white">
                      <span className="opacity-50">+</span> 0.0165 USDT
                      <span className="opacity-50 px-3 px-lg-0"> in xQore</span>
                    </span>
                  </span>
                  <div className="ms-auto text-white opacity-50">
                    <RiShareBoxFill className="fs-5" />
                    <span> 1 minute</span>
                  </div>
                </div>
              </div>

              <div className="col-12 ">
                <div className="d-flex flex-row align-items-center banner-border-bottom-3 p-lg-3 p-1 banner-section3-text5">
                  <span>
                    <img src={iconImage} className="banner-img-4 me-3"></img>
                    <IoWalletSharp className="banner-icon-3 fs-2 rounded-circle me-3 p-2" />
                    <button type="button" className="banner-btn-3 me-3">
                      ID 5946
                    </button>
                    <span className="text-white">
                      <span className="opacity-50">+</span> 0.1725 USDT
                      <span className="opacity-50 px-3 px-lg-0"> in xQore</span>
                    </span>
                  </span>
                  <div className="ms-auto text-white opacity-50">
                    <RiShareBoxFill className="fs-5" />
                    <span> 1 minute</span>
                  </div>
                </div>
              </div>

              <div className="col-12  ">
                <div className="d-flex flex-row align-items-center banner-border-bottom-3 p-lg-3 p-1 banner-section3-text5">
                  <span>
                    <img src={iconImage} className="banner-img-4 me-3"></img>
                    <IoWalletSharp className="banner-icon-3 fs-2 rounded-circle me-3 p-2" />
                    <button type="button" className="banner-btn-3 me-3">
                      ID 739158
                    </button>
                    <span className="text-white">
                      <span className="opacity-50">+</span> 0.0165 USDT
                      <span className="opacity-50 px-3 px-lg-0"> in xQore</span>
                    </span>
                  </span>
                  <div className="ms-auto text-white opacity-50">
                    <RiShareBoxFill className="fs-5" />
                    <span> 1 minute</span>
                  </div>
                </div>
              </div> */}
                  {recentUsers.length > 0 ? (
                    <div className="col-12 text-center py-3 ">
                      <button
                        type="button"
                        className="btn btn-primary banner-btn-4 py-2"
                        onClick={seeMoreUsers}
                      >
                        <AiFillEye className="fs-5" /> See more
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="container banner-section-4">
              <div className="row">
                <div className="col">
                  <h1 className="text-white banner-text-3 fw-bold banner-section4-text1">
                    Partner Results
                  </h1>
                  <div className="text-white  banner-section4-text2 py-3 px-lg-5">
                    <div className="banner-setion4-font">
                      All data is stored in the blockchain in the public domain and
                      can be verified!
                    </div>
                    <div className="fs-6">
                      {" "}
                      <span className="opacity-50">Contract Address USDT:</span>
                      0x5acc84a3e
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <button className="banner-section4-btn text-dark  px-lg-3 py-2">
                    USDT
                  </button>
                </div>
              </div>

              <div className="row py-5">
                <div className="col-lg-6 col-12 py-lg-0 py-4 text-white text-center">
                  <div className="d-flex align-items-center mx-auto justify-content-center">
                    <span className="banner-section4-num1">
                      {totalAccount > 0 ? totalAccount : 0}
                    </span>
                    {/* <span
                  className="fs-4 fw-bold ms-2"
                  style={{ color: "rgba(44,255,78)", verticalalign: "middle" }}
                >
                  {" "}
                  +1 901
                </span> */}
                  </div>
                  <div className="text-center flex-row fw-bold">Accounts count</div>
                </div>
                <div className="col-lg-6 col-12 py-lg-0 py-4 text-white text-center">
                  <div className="d-flex align-items-center mx-auto justify-content-center">
                    <span className="banner-section4-num1 ">
                      {totalAmount > 0 ? totalAmount : 0}
                    </span>
                    {/* <span
                  className="fs-4 fw-bold ms-2"
                  style={{ color: "rgba(44,255,78)", verticalalign: "middle" }}
                >
                  +59 545
                </span> */}
                  </div>
                  <div className="text-center flex-row fw-bold">
                    Total result, USDT
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          ""
        )
      }



      <section className="banner-section-5 py-lg-5 py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col text-center mx-lg-5 mx-3">
              <h1 className="text-white  banner-section5-text fw-bold fs-1">
                Technology of smart contracts and non-fungible tokens
              </h1>
              <p className="text-white text-center py-lg-3 py-0 banner-section5-smalltext">
                Decentralized marketing is powered by the revolutionary
                technology of smart contracts and NFTs. The Meta King smart
                contract code is completely open. You can be sure of its safety
                and long-term performance
              </p>
            </div>
          </div>
        </div>

        <div className="container banner-section-6">
          <div className="row px-lg-5 py-2 banner-section6-card flex-lg-row-reverse">
            <div className="col py-4 text-white text-center lh-lg">
              <div
                className="nav flex-column nav-pills me-3"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <button
                  className="nav-link active py-3"
                  id="v-pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-home"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-home"
                  aria-selected="true"
                >
                  Autonomy
                </button>
                <button
                  className="nav-link py-3"
                  id="v-pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-profile"
                  aria-selected="false"
                >
                  Unchanging conditions
                </button>
                <button
                  className="nav-link py-3"
                  id="v-pills-messages-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-messages"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-messages"
                  aria-selected="false"
                >
                  Transparency
                </button>
                <button
                  className="nav-link py-3"
                  id="v-pills-settings-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-settings"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-settings"
                  aria-selected="false"
                >
                  Full automation
                </button>
                <button
                  className="nav-link py-3"
                  id="v-pills-decentraliation-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-decentraliation"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-settings"
                  aria-selected="false"
                >
                  Decentralization
                </button>
                <button
                  className="nav-link py-3"
                  id="v-pills-online-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-online"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-online"
                  aria-selected="false"
                >
                  100% online
                </button>
              </div>
            </div>
            <div className="col col-lg-8 py-4">
              <div className="d-flex align-items-start">
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-home"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                    tabindex="0"
                  >
                    <div className="card bg-dark text-center">
                      <div className="card-body">
                        {/* <i className="fa-solid fa-arrows-rotate text-center text-white py-3 banner-section6-icon me-3 p-3"></i> */}
                        <FaArrowsRotate
                          className="text-center text-white banner-section6-icon me-3 p-3"
                          style={{ fontSize: "50px" }}
                        />
                        <h5 className="card-title text-white py-3">
                          Autonomy
                        </h5>
                        <p className="card-text text-white px-lg-5 px-0">
                          {bannerContent && bannerContent[2] && bannerContent[2].content}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="v-pills-profile"
                    role="tabpanel"
                    aria-labelledby="v-pills-profile-tab"
                    tabindex="0"
                  >
                    <div className="card bg-dark text-center">
                      <div className="card-body">
                        <FaArrowsRotate
                          className="text-center text-white banner-section6-icon me-3 p-3"
                          style={{ fontSize: "50px" }}
                        />
                        <h5 className="card-title text-white py-3">
                          Unchanging conditions
                        </h5>
                        <p className="card-text text-white px-lg-5 px-0">
                          {bannerContent && bannerContent[3] && bannerContent[3].content}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="v-pills-messages"
                    role="tabpanel"
                    aria-labelledby="v-pills-disabled-tab"
                    tabindex="0"
                  >
                    <div className="card bg-dark text-center">
                      <div className="card-body">
                        <FaArrowsRotate
                          className="text-center text-white banner-section6-icon me-3 p-3"
                          style={{ fontSize: "50px" }}
                        />
                        <h5 className="card-title text-white py-3">
                        Transparency
                        </h5>
                        <p className="card-text text-white px-lg-5 px-0">
                          {bannerContent && bannerContent[4] && bannerContent[4].content}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="v-pills-settings"
                    role="tabpanel"
                    aria-labelledby="v-pills-messages-tab"
                    tabindex="0"
                  >
                    <div className="card bg-dark text-center">
                      <div className="card-body">
                        <FaArrowsRotate
                          className="text-center text-white banner-section6-icon me-3 p-3"
                          style={{ fontSize: "50px" }}
                        />
                        <h5 className="card-title text-white py-3">
                          Full automation
                        </h5>
                        <p className="card-text text-white px-lg-5 px-0">
                          {/* No one, not even the creators of the code, can make
                          changes to the work of Meta King smart contracts. */}
                          {bannerContent && bannerContent[5] && bannerContent[5].content}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="v-pills-decentraliation"
                    role="tabpanel"
                    aria-labelledby="v-pills-decentraliation-tab"
                    tabindex="0"
                  >
                    <div className="card bg-dark text-center">
                      <div className="card-body">
                        <FaArrowsRotate
                          className="text-center text-white banner-section6-icon me-3 p-3"
                          style={{ fontSize: "50px" }}
                        />
                        <h5 className="card-title text-white py-3">
                          Decentralization
                        </h5>
                        <p className="card-text text-white px-lg-5 px-0">
                          {/* No one, not even the creators of the code, can make
                          changes to the work of Meta King smart contracts. */}
                          {bannerContent && bannerContent[6] && bannerContent[6].content}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="v-pills-online"
                    role="tabpanel"
                    aria-labelledby="v-pills-settings-tab"
                    tabindex="0"
                  >
                    <div className="card bg-dark text-center">
                      <div className="card-body">
                        <FaArrowsRotate
                          className="text-center text-white banner-section6-icon me-3 p-3"
                          style={{ fontSize: "50px" }}
                        />
                        <h5 className="card-title text-white py-3">
                         100% online
                        </h5>
                        <p className="card-text text-white px-lg-5 px-0">
                          {/* No one, not even the creators of the code, can make
                          changes to the work of Meta King smart contracts. */}
                          {bannerContent && bannerContent[7] && bannerContent[7].content}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container banner-section-7 ">
          <div className="row">
            <div className="col">
              <h1 className="text-white text-center banner-text-3 fw-bold fs-1 pb-3">
                Convenient Office
              </h1>
              <p className="text-white  banner-section7-text ">
                Interactive online visualization of active slots showing your
                unique NFT collection and your financial progress.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <img src={cabinetPreview} className="px-5"></img>
            </div>
          </div>
        </div>

        <div className="container banner-section-8 py-lg-0 py-3">
          <div className="row">
            <div className="col">
              <h1 className="text-white text-center banner-text-3 fw-bold fs-1 pb-3">
                Frequently Asked Questions
              </h1>
            </div>
          </div>

          <div className="row pt-lg-5">
            <div className="col">
              <div className="accordion accordion-flush" id="accordionFlushExample">
                {loading ? (
                  <p>Loading FAQ data...</p>
                ) : (
                  faqList.map((faq, index) => (
                    <div className="accordion-item" key={index}>
                      <h2 className="accordion-header py-lg-3">
                        <button
                          className="accordion-button collapsed text-white banner-section8-text1"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#flush-collapse${index + 1}`}
                          aria-expanded="false"
                          aria-controls={`flush-collapse${index + 1}`}
                        >
                          {faq.title}
                        </button>
                      </h2>
                      <div
                        id={`flush-collapse${index + 1}`}
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body text-white banner-section8-text2">
                          {
                            <p dangerouslySetInnerHTML={{ __html: faq.description }}></p>
                          }

                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="container banner-section-9">
          <div className="row pb-3">
            <div className="col">
              <h1 className="text-white text-center banner-text-3 fw-bold fs-1 pb-3">
                Official Channels
              </h1>
            </div>
          </div>
          {Array.isArray(links) ? (
            links.map((data, i) => (

              <div className="row row-cols-2 row-cols-lg-5 text-center g-4">
                <div className="col" key={i}>
                  <a className="btn__bordered_gradient text-decoration-none" href={data.Telegram}
                    target="_blank">
                    {/* <i className="fa-solid fa-paper-plane text-white"></i> */}
                    <FaPaperPlane />
                  </a>
                  <a className="banner-section9-text text-decoration-none" href={data.Telegram}
                    target="_blank">
                    Telegram channel
                  </a>
                </div>

                <div className="col">
                  <a className="btn__bordered_gradient text-decoration-none" href={data.Telegramchat}
                    target="_blank">
                    <FaPaperPlane />
                  </a>
                  <a className="banner-section9-text text-decoration-none" href={data.Telegramchat}
                    target="_blank">
                    Telegram chat
                  </a>
                </div>

                <div className="col">
                  <a className="btn__bordered_gradient text-decoration-none" href={data.Twitter}
                    target="_blank">
                    <FaTwitter />
                  </a>
                  <a className="banner-section9-text text-decoration-none" href={data.Twitter}
                    target="_blank">
                    Twitter
                  </a>
                </div>

                <div className="col">
                  <a className="btn__bordered_gradient text-decoration-none" href={data.Instagram}
                    target="_blank">
                    <FaInstagram />
                  </a>
                  <a className="banner-section9-text text-decoration-none" href={data.Instagram}
                    target="_blank">
                    Instagram
                  </a>
                </div>

                <div className="col">
                  <a className="btn__bordered_gradient text-decoration-none" href={data.Youtube}
                    target="_blank">
                    <FaYoutube />
                  </a>
                  <a className="banner-section9-text text-decoration-none" href={data.Youtube}
                    target="_blank">
                    Youtube
                  </a>
                </div>
              </div>
            ))
          ) : (
            <tr>
              <td colSpan={4}>Loading...</td>
            </tr>
          )}

          {/* <div className="row row-cols-2 row-cols-lg-5 text-center g-4">
            <div className="col">
              <a className="btn__bordered_gradient text-decoration-none">
                <FaPaperPlane />
              </a>
              <a className="banner-section9-text text-decoration-none">
                Telegram channel
              </a>
            </div>

            <div className="col">
              <a className="btn__bordered_gradient text-decoration-none">
                <FaPaperPlane />
              </a>
              <a className="banner-section9-text text-decoration-none">
                Telegram chat
              </a>
            </div>

            <div className="col">
              <a className="btn__bordered_gradient text-decoration-none">
                <FaTwitter />
              </a>
              <a className="banner-section9-text text-decoration-none">
                Twitter
              </a>
            </div>

            <div className="col">
              <a className="btn__bordered_gradient text-decoration-none">
                <FaInstagram />
              </a>
              <a className="banner-section9-text text-decoration-none">
                Instagram
              </a>
            </div>

            <div className="col">
              <a className="btn__bordered_gradient text-decoration-none">
                <FaYoutube />
              </a>
              <a className="banner-section9-text text-decoration-none">
                Youtube
              </a>
            </div>
          </div> */}

        </div>

        <div className="container banner-section10-background">
          <div className="row">
            <div className="col-lg-3">
              <img className="banner-section10-img-1 mb-3" src={logoimage} alt="logo" />
              {/* <img
                src={loginlogo}
                className="banner-section10-img-1 mb-3"
              ></img> */}
              <div className="banner-section10-text-1">
                worldFirstDecentralized
              </div>
            </div>

            <div className="col-lg-1">
              <span className="break-normal banner-section10-text-2">xXx</span>
              <span className="banner-section10-text-2">USDT</span>
              <a
                className="text-white text-decoration-none px-5 px-lg-0"
                href="#"
              >
                0x2CAa...ae52
              </a>
            </div>

            <div className="col-lg-3"></div>

            <div className="col-lg-5 d-flex mt-4 banner-section10-hide">
              <img src={bannerIcon} className="banner-section10-img-2"></img>
              <span className="banner10-block"></span>
              <a className="banner-section10-text-4 text-decoration-none text-white">
                Meta King USDT
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="banner-section11">
        <div className="container">
          <div className="row flex-lg-row-reverse">
            {/* <div className="col-lg-9 ">
              <a
                className="btn btn-outline-light btn-floating m-1 banner-section11-icon"
                href="#!"
                role="button"
              >
                <FaPaperPlane />
              </a>
              <a
                className="btn btn-outline-light btn-floating m-1 banner-section11-icon"
                href="#!"
                role="button"
              >
                <FaPaperPlane />
              </a>
              <a
                className="btn btn-outline-light btn-floating m-1 banner-section11-icon"
                href="#!"
                role="button"
              >
                {" "}
                <FaTwitter />
              </a>
              <a
                className="btn btn-outline-light btn-floating m-1 banner-section11-icon"
                href="#!"
                role="button"
              >
                {" "}
                <FaInstagram />
              </a>
              <a
                className="btn btn-outline-light btn-floating m-1 banner-section11-icon"
                href="#!"
                role="button"
              >
                <FaYoutube />
              </a>
            </div> */}
            {Array.isArray(links) ? (
            links.map((data, i) => (
              <div className="col-lg-9" key={i}>
                <a
                  className="btn btn-outline-light btn-floating m-1 banner-section11-icon"
                  href={data.Telegram}
                  target="_blank"
                  role="button"
                >
                  <FaPaperPlane />
                </a>
                <a
                  className="btn btn-outline-light btn-floating m-1 banner-section11-icon"
                  href={data.Telegramchat}
                  target="_blank"
                  role="button"
                >
                  <FaPaperPlane />
                </a>
                <a
                  className="btn btn-outline-light btn-floating m-1 banner-section11-icon"
                  href={data.Twitter}
                  target="_blank"
                  role="button"
                >
                  {" "}
                  <FaTwitter />
                </a>
                <a
                  className="btn btn-outline-light btn-floating m-1 banner-section11-icon"
                  href={data.Instagram}
                  target="_blank"
                  role="button"
                >
                  {" "}
                  <FaInstagram />
                </a>
                <a
                  className="btn btn-outline-light btn-floating m-1 banner-section11-icon"
                  href={data.Youtube}
                  target="_blank"
                  role="button"
                >
                  <FaYoutube />
                </a>
              </div>

            ))
          ) : (
            <tr>
              <td colSpan={4}>Loading...</td>
            </tr>
          )}
            <div className="col-lg-3  ">
              <span className="opacity-50 text-white banner-section11-text-1">
                © 2023 All Rights Reserved
                <div className="banner-section11-text-2">Disclaimer</div>
              </span>
            </div>
          </div>
          
        </div>
      </section>
    </div>
  );
}

export default App;
