import loginlogo from "./assets/images/loginlogo-img.png";
import React, { useEffect, useState } from "react";
import Web3 from "web3";

import { AiFillEye } from "react-icons/ai";
import { RiShareBoxFill } from "react-icons/ri";
import { IoWalletSharp } from "react-icons/io5";
import { AiFillQuestionCircle } from "react-icons/ai";
import { BsArrowUp } from "react-icons/bs";
import { BsClipboard } from "react-icons/bs";
import { BsLink45Deg } from "react-icons/bs";
import { FaPaperPlane } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa6";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "./config/apiConfig";
import {
  getRecentUsers,
  getTotalAmtAndAcc,
  getUserDataAPIUsingId,
  userLoginAPI,
} from "./Dashboard-pages/api/authapis";
import { useNavigate } from "react-router-dom";
import axios from "axios";


function Login() {
  
  const [currentAccount, setCurrentAccount] = useState("");
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();
  const [recentUsers, setRecentUsers] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAccount, setTotalAccount] = useState(0);
  const [logoimage, setLogoimage] = useState('')
  const [links, setLinks] = useState([])


  const logoimageset = async () => {
    try {
      const response = await axios.get(config.BACKEND_URL + 'admin/getimage'); // Update with your API endpoint
      // console.log('response.data---', response.data);
      if (response.data && response.data.length > 0) {
        setLogoimage(response.data[0].logoimage); // Assuming you want to display the first image
        // setOldImageId(response.data[0]._id)
      }
    } catch (error) {
      console.error('Error fetching old image:', error);
    }
  }

  const setlinks = async () => {
    try {
      const response = await axios.get(config.BACKEND_URL + 'admin/getlink'); // Update with your API endpoint
      // console.log('response.data---', response.data);
      if (response.data && response.data.length > 0) {
        setLinks(response.data); // Assuming you want to display the first image
        // setOldImageId(response.data[0]._id)
      }
    } catch (error) {
      console.error('Error fetching old image:', error);
    }
  }
  
  const userLogin = async () => {
    let walletConnect = localStorage.getItem("address");
    if (walletConnect != null) {
      const account = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      var useraddress = account[0];
      let formData = {
        userAddress: useraddress.toLowerCase(),
      };
      let userLog = await userLoginAPI(formData);
      if (userLog.status) {
        toast.success(`${userLog.message}`);
        localStorage.setItem("userToken", `${userLog.result}`);
        localStorage.setItem("address", `${userLog.user.address}`);
        localStorage.setItem("userID", `${userLog.user.userId}`);
        navigate("/dashboard");
      } else {
        if (userLog.message) {
          await toast.error(`${userLog.message}`);
          return false;
        }
      }
    } else {
      toast.error("Please connect your wallet");
    }
  };

  const ConnectToMetaMask = async () => {
    try {
      if (window.ethereum) {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: config.chainId }],
        });
        const web3 = new Web3(window.ethereum);
        window.ethereum
          .request({ method: "eth_requestAccounts" })
          .then((accounts) => {
            // Set the initial account
            const address = accounts[0];
            if (localStorage.getItem("address") === address) {
              setCurrentAccount(
                address.substring(0, 5) +
                  "..." +
                  address.substring(address.length - 4)
              );
            } else {
              setCurrentAccount(
                address.substring(0, 5) +
                  "..." +
                  address.substring(address.length - 4)
              );
              localStorage.setItem("address", address);
            }
          })
          .catch((error) => {
            console.error(error);
            toast.error("Something went wrong");
          });
      } else {
        toast.error("Metamask not found. Please install Meta mask");
      }
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: config.chainId,
                chainName: "BNB Smart Chain Testnet",
                rpcUrls: config.rpcUrls /* ... */,
              },
            ],
          });
          // Check if MetaMask is available
          if (typeof window.ethereum !== "undefined") {
            const web3 = new Web3(window.ethereum);
            window.ethereum
              .request({ method: "eth_requestAccounts" })
              .then((accounts) => {
                // Set the initial account
                const address = accounts[0];
                if (localStorage.getItem("address") === address) {
                  setCurrentAccount(
                    address.substring(0, 5) +
                      "..." +
                      address.substring(address.length - 4)
                  );
                } else {
                  setCurrentAccount(
                    address.substring(0, 5) +
                      "..." +
                      address.substring(address.length - 4)
                  );
                  localStorage.setItem("address", address);
                }
              })
              .catch((error) => {
                console.error(error);
                toast.error("Something went wrong");
              });
          } else {
            toast.error("Metamask not found");
          }
        } catch (addError) {
          // handle "add" error
          console.log(addError);
        }
      }
    }
  };
  const handleChange = (e) => {
    setUserId(e.target.value);
    // console.log(e.target.value);
  };
  const handleSubmit = async () => {
    console.log("userId", userId);
    let userPresent = await getUserDataAPIUsingId(userId);
    // console.log("userPresent",userPresent);
    if (userPresent.status) {
      navigate(`/dashboard?user=${userId}`);
    } else {
      toast.error("Couldn't find user..!");
    }
  };
  useEffect(() => {
    let Address = localStorage.getItem("address");
    if (Address) {
      setCurrentAccount(
        Address.substring(0, 5) + "..." + Address.substring(Address.length - 4)
      );
    }
    getRecentUser();
    getTotAccAndAmt();
    logoimageset()
    setlinks()
  }, []);

  const getRecentUser = async () => {
    try {
      const response = await getRecentUsers();
      // console.log("response===home",response);
      if (response.status) {
        setRecentUsers(response.data);
      }
    } catch (error) {
      console.log(error, "error");
      // toast.error("Something went wrong");
    }
  };

  const getTotAccAndAmt = async () => {
    try {
      const response = await getTotalAmtAndAcc();
      // console.log("response===home",response);
      if (response.status) {
        // console.log(response.data[0].total,"response totl data");
        setTotalAmount(response.data[0].total);
        setTotalAccount(response.data[0].count);
      }
    } catch (error) {
      console.log(error, "error");
      // toast.error("Something went wrong");
    }
  };

  const formatTimeDifference = (timestamp) => {
    const currentTime = new Date();
    const timeDifference = Math.floor(
      (currentTime - new Date(timestamp)) / 1000
    );

    if (timeDifference < 60) {
      return `${timeDifference} seconds ago`;
    } else if (timeDifference < 3600) {
      return `${Math.floor(timeDifference / 60)} minutes ago`;
    } else if (timeDifference < 86400) {
      return `${Math.floor(timeDifference / 3600)} hours ago`;
    } else {
      return `${Math.floor(timeDifference / 86400)} days ago`;
    }
  };

  // const checkconnect=(()=>{
  //   if(!window.ethereum.isConnected){
  //     console.log("disconnect")
  //   }
  // })

  // window.ethereum.on('disconnect',handleDisconnect)
  if (window.ethereum) {
    window.ethereum.on("accountsChanged", (newAccounts) => {
      const newAddress = newAccounts[0];
      if (newAddress === "undefined" || newAddress === undefined) {
        localStorage.removeItem("address");
        localStorage.removeItem("userID");
        localStorage.removeItem("userToken");
      } else {
        setCurrentAccount(
          newAddress.substring(0, 5) +
            "..." +
            newAddress.substring(newAddress.length - 4)
        );
        localStorage.setItem("address", newAddress);
        localStorage.removeItem("userID");
        localStorage.removeItem("userToken");
      }
    });
  }

  return (
    <div className="meta-king-login-section">
      <ToastContainer />
      <section id="topnavsection">
        <nav className="navbar navbar-expand-lg ">
          <div className="container">
          <a className="navbar-brand" href="/">
              {/* <img src={loginlogo} alt="logo" /> */}
              <img className="banner-img1" src={logoimage} alt="logo" />
            </a>

            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li>
                {currentAccount ? (
                  <button className="button1" type="submit">
                    {currentAccount}
                  </button>
                ) : (
                  <button
                    className="button1"
                    type="submit"
                    onClick={ConnectToMetaMask}
                  >
                    Connect Wallet
                  </button>
                )}
              </li>
            </ul>
          </div>
        </nav>
      </section>

      <section id="secondpart">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 secondpartparent">
              <div className="secondpartchild">
                <h4 className="pt-4">Meta King USDT main page</h4>
                <p className="pt-2">Connect wallet to Register or Login</p>
                <div className="secondpartbtn">
                  <button
                    className="btn secondpartbtn1 btn-dark my-3 me-4"
                    onClick={userLogin}
                  >
                    {currentAccount != "" ? "Join Metaking" : "Connect Now"}
                  </button>
                  <button className="btn secondpartbtn2 btn-warning my-2">
                    Help Me
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="thirdpart" className="my-4">
        <div className="container">
          <div className="row py-3">
            <div className="col-lg-12">
              <div className="thirdpartchild">
                <h4 className="text-white fs-2 fw-bold">Account preview</h4>
                <p>
                  Look up any Meta King USDT member account in preview mode.
                  Enter ID or USDT address to preview or click Demo to view a
                  random account.
                </p>
              </div>
            </div>
          </div>

          <div className="row d-flex align-items-center gy-3">
            <div className="col-lg-8">
              <div className="thirdpartchild0">
                <h5 className="pb-2">Enter ID or USDT wallet</h5>
                <input
                  type="text"
                  placeholder="example:87654"
                  className="inputchild0 text-white"
                  name="userId"
                  value={userId}
                  onChange={handleChange}
                  autocomplete="off"
                />
                <button className="tbtn" onClick={handleSubmit}>
                  Preview
                </button>
              </div>
            </div>
            {/* <div className="col-lg-4 thirdpartchild02">
              <div className="row py-2 d-flex  align-items-center justify-content-space-between">
                <div className="col-lg-8 col- md-8 col-8 ps-4">
                  <p className="text-white">Don’t know any ID?</p>
                  <button className="btn thirdpartchild02BTN fw-bold text-white">
                    check Demo
                  </button>
                </div>
                <div className="col-lg-4 col-md-4 col-4 hiddenmobile">
                  <svg
                    className="absolute guilty top-1/2 right-0 transform -translate-y-1/2 fill-current "
                    width="113"
                    height="121"
                    fill="#406AFF"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M35.987 32.963c-1.348-.492-2.812.247-3.278 1.664l-2.3 7.024s9.884-3.326 15.036-4.374l-9.458-4.314Zm-32.3
                         18.791c.024 0 1.04-.74 1.582-1.048l16.6-8.933c5.146-2.589 4.012-4.621 5.96-10.289a35.974 35.974 0 0 1 2.93-6.346C40.234 20.393 67.166 21.503 69.738 0c0 0 10.047 6.962 11.878 
                         19.716C187.781 11.153 237 73.01 237 73.01c-19.772-8.317-41.103-12.26-62.439-13.307 40.561 32.961 53.332 82.619 53.332 82.619s-32.86-33.7-82.254-52.492c12.929 19.286 20.421 45.161 
                         17.358 79.17 0 0-25.408-102.396-145.965-77.63 0 0-4.411-13.615-15.8-27.54-.718-.863-1.125-2.156-1.207-3.635a10.002 10.002 0 0 1 3.662-8.44Z"
                    ></path>
                  </svg>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <section id="fourthpart" className="my-5">
        <div className="container rounded">
          <div className="row">
            <div className="col-lg-12">
              <div className="fourthpartchild">
                <h5 className="text-white">
                  Platform recent activity
                  {/* <i className="bi bi-question-circle-fill"></i> */}
                  <AiFillQuestionCircle className="fs-6" />
                </h5>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col-lg-8 fourthpartwhole0 bg-dark gy-1 pt-2 px-4">
              {recentUsers.length >= 0 &&
                recentUsers.map((user) => {
                  return (
                    <>
                      <div className="row d-flex align-items-center fourthbanner9 py-4">
                        <div className="col-lg-8  col-md-8 col-8 d-flex align-items-center">
                          <div className="fourthbanner20 d-flex align-items-center">
                            <span className="fourthbannericontags">
                              <IoWalletSharp className="fs-5" />
                            </span>
                          </div>
                          <div className="fourthbanner12 d-flex align-items-center">
                            <span className=" mx-2">
                              <a
                                href={`/dashboard?user=${user.userId}`}
                                className="px-2 py-1 border-0 fourthbannerbtn1"
                              >
                                ID {user.userId}
                              </a>
                            </span>
                            <span className="btn21">
                              <span className="text-white">
                                + 5 USDT{" "}
                                <span className="opacity-50 text-white">
                                  {" "}
                                  in{" "}
                                </span>
                                <bold className="fourthgreen">x4</bold>
                              </span>{" "}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-4 d-flex align-items-center justify-content-end">
                          <div className="fourthbanner13 d-flex align-items-center">
                            <a
                              target="blank"
                              href={
                                config.xcoreContarctLink + `${user.address}`
                              }
                            >
                              <span>
                                <RiShareBoxFill className="fs-5 me-2" />
                              </span>
                              <span className="opacity-50 text-white">
                                {formatTimeDifference(user.date)}
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              {recentUsers.length > 0 ? (
                <div className="row pt-2">
                  <div className="col-lg-12">
                    <button className="w-100 py-3 fourthbannerbtn2">
                      <AiFillEye className="fs-5" /> See More
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="col-lg-4 gy-3">
              <div className="row ">
                <div className="col-lg-12 ">
                  <div className="fourthpartchild2 bg-dark py-1 px-4">
                    <h5 className=" bannercontent0 py-3">
                      Members Total
                      {/* <i className="bi bi-question-circle-fill text-white mx-2"></i> */}
                      <AiFillQuestionCircle className="text-white mx-2" />
                    </h5>
                    <h3 className="text-white bannerdate fs-5">
                      {totalAccount}
                    </h3>
                    {/* <p className="banneryear1">
                      <BsArrowUp className="fs-5" />
                      2049
                    </p> */}
                  </div>
                </div>
              </div>

              <div className="row mt-4 gy-2">
                <div className="col-lg-12">
                  <div className="fourthpartchild2 bg-dark py-1 px-4">
                    <h5 className="bannercontent0 py-3">
                      Members Received{" "}
                      <AiFillQuestionCircle className="text-white mx-2" />
                    </h5>
                    <h3 className="text-white bannerdate fs-5">
                      {totalAmount + " USDT"}
                    </h3>
                    {/* <p className="banneryear1">
                      <BsArrowUp className="fs-5" />
                      59 707.1
                    </p> */}
                  </div>
                </div>
              </div>

              {/* <div className="row mt-4 p-3">
                  <div className="col-lg-12 fourthrounding bg-dark px-4">
                    <div className="row ">
                      <div className="col-lg-12">
                        <p className="opacity-50 text-white  pt-3">
                          Meta King USDT Contracts
                        </p>
                      </div>
                    </div>

                    <div className="row d-flex align-items-center fourthbanner6 fourthbanner30 py-1">
                      <div className="col-lg-6  py-2 ">
                        <span className="fw-6 fourthbanner5 ">x3/x4</span>
                      </div>
                      <div className="col-lg-6 d-flex align-items-center py-2 ">
                        <span className="me-2 text-white">0x5ac...B97</span>
                        <span className="me-2 text-white">
                          <BsClipboard />
                        </span>
                        <span className="me-2 text-white">
                          <BsLink45Deg />
                        </span>
                      </div>
                    </div>

                    <div className="row d-flex align-items-center fourthbanner6 py-1">
                      <div className="col-lg-6">
                        <span className="fw-6 fourthbanner5 py-2 ">
                          x<bold>X</bold>x
                        </span>
                      </div>
                      <div className="col-lg-6 d-flex align-items-center  py-2">
                        <span className="me-2 text-white">0x5ac...B97</span>
                        <span className="me-2 text-white">
                          <BsClipboard />
                        </span>
                        <span className="me-2 text-white">
                          <BsLink45Deg />
                        </span>
                      </div>
                    </div>

                    <div className="row d-flex align-items-center fourthbanner6 py-1">
                      <div className="col-lg-6">
                        <span className="fw-6 fourthbanner5 py-2">
                          x<bold>Gold</bold>
                        </span>
                      </div>
                      <div className="col-lg-6 d-flex align-items-center py-2">
                        <span className="me-2 text-white">0x5ac...B97</span>
                        <span className="me-2 text-white">
                          <BsClipboard />
                        </span>
                        <span className="me-2 text-white">
                          <BsLink45Deg />
                        </span>
                      </div>
                    </div>
                    <div className="row py-3 fourthbanner6">
                      <p className="opacity-50 text-white">Transactions made</p>
                      <h4 className="text-white">4 680 371</h4>
                      <p className="fourthiconchild10">+ 4 463</p>
                    </div>
                    <div className="row py-2">
                      <p className="fourthbanner5">Turnover, USDT</p>
                      <h4 className="text-white">275 613 782.1</h4>
                      <p className="fourthiconchild10">+ 119 414.2</p>
                    </div>
                  </div>
                </div> */}
            </div>
          </div>
        </div>
      </section>

      <section id="fifthsection" className="my-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="fifthsectionchild">
                <h4 className="text-white fs-2">
                  Need help on how to use the platform ?
                </h4>
                <p className="opacity-50 fs-6 py-3 text-white">
                  Get qualified support from Meta King experts via online chat
                </p>
                <button className="btn  fifthbutton  border-0 outline-none text-white">
                  contact support
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="footer" className="pt-5">
        <div className="container pt-4">
          <div className="row pb-2">
            <div className="col-lg-6">
              <p className="opacity-50 text-white">
                © 2023 All Rights Reserved
                <span className="text-white px-2">Document</span>
              </p>
            </div>
            {Array.isArray(links) ? (
              links.map((data, i) => (
                <div className="col-lg-6 text-end">
                  <button className="footericon0 mx-3">

                    <a href={data.Telegram}
                      target="_blank"><FaPaperPlane className="text-white" /></a>
                  </button>
                  <button className="footericon0">
                    <a href={data.Twitter}
                      target="_blank"><FaTwitter className="text-white" /></a>
                  </button>
                </div>
              ))
            ) : (
              <tr>
                <td colSpan={4}>Loading...</td>
              </tr>
            )}
            {/* <div className="col-lg-6 text-end">
              <button className="footericon0 mx-3">
                <FaPaperPlane className="text-white" />
              </button>
              <button className="footericon0">
                <FaTwitter className="text-white" />
              </button>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
}
export default Login;
