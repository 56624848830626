import { makeApiRequest } from "../../config/axiosService";

export const registerUser = async (data) => {
  try {
    // console.log('params for register--', params);
    let params = {
      url: "register",
      method: "POST",
      data: data
    }
    const response = await makeApiRequest(params);

    return {
      status: response.status,
      message: response.message,
      data: response.data
    }

  } catch (error) {
    console.log('registerUser error', error);
    return {
      status: false,
      message: "Error on server"
    }
  }
}


export const getParentID = async (parentId) => {
  try {
    // console.log('parentAddress--', parentId);

    let params = {
      url: `getParentAddress?id=${parentId}`,
      method: "GET",
    }
    let response = await makeApiRequest(params);
    // console.log('response---', response);
    // console.log("response",response);
    return {
      status: response.status,
      message: response.message,
      result: response.data
    }
  } catch (error) {

    console.log('getParent Address error', error);
    return {
      status: false,
      message: "Error on server",
      result: []
    }
  }
}

export const userLoginAPI = async (bodyData) => {
  try {

    let params = {
      url: "login",
      method: "POST",
      data: bodyData
    }

    let response = await makeApiRequest(params)
    // console.log('response---', response);
    //  console.log("response",response);
    return {
      status: response.status,
      message: response.message,
      result: response.data,
      user: response.userData
    }

  } catch (error) {
    console.log('userLogin error', error);
    return {
      status: false,
      message: "Error on server",
      result: []
    }
  }
}

export const viewUserDashboard = async (paramsData) => {
  try {

    let params = {
      url: `userDashboard/${paramsData}`,
      method: "get",
    }

    let response = await makeApiRequest(params)
    // console.log('response---', response);
    //  console.log("response",response);
    return {
      status: response.status,
      message: response.message,
      user: response.userData
    }

  } catch (error) {
    console.log('userLogin error', error);
    return {
      status: false,
      message: "Error on server",
      result: []
    }
  }
}





// export const userRefLevel = async (userDetailsGet) => {
//   let userDetailsGet1;

//   // Check if userDetailsGet is an array of objects and convert if needed
//   if (Array.isArray(userDetailsGet)) {
//     userDetailsGet1 = userDetailsGet.map(item => {
//       return { userId: item.userId.toString(), userLevel: item.userLevel.toString() };
//     });
//   }
//   try {
//     if (userDetailsGet1 !== undefined) {
//       // Construct the API request parameters
//       let params = {
//         url: "getUsersRefferalLevel", // Correct the URL
//         method: "post",
//         data: userDetailsGet1
//       };

//       // Make the API request using your makeApiRequest function
//       let response = await makeApiRequest(params);

//       return {
//         status: response.status,
//         message: response.message,
//         primaryResult: response.primaryRefData,
//         secondaryResult: response.secondaryRefData
//       };
//     } else {
//       return {
//         status: false,
//         message: "undifind"
//       };
//     }
//   } catch (error) {
//     console.log('userRefLevel error', error);
//     return {
//       status: false,
//       message: "Error on server",
//       result: []
//     };
//   }
// };
export const userRefLevel = async (userDetailsGet) => {
  let userDetailsGet1;

  // Check if userDetailsGet is an array of objects and convert if needed
  if (Array.isArray(userDetailsGet)) {
    userDetailsGet1 = userDetailsGet.map(item => {
      return { userId: item.userId.toString(), userLevel: item.userLevel.toString() };
    });
  }
  try {
    if (userDetailsGet1 !== undefined) {
      // Construct the API request parameters
      let params = {
        url: "getUsersRefferalLevel", // Correct the URL
        method: "post",
        data: userDetailsGet1
      };

      // Make the API request using your makeApiRequest function
      let response = await makeApiRequest(params);

      return {
        status: response.status,
        message: response.message,
        primaryResult: response.primaryRefData,
        secondaryResult: response.secondaryRefData
      };
    } else {
      return {
        status: false,
        message: "undifind"
      };
    }
  } catch (error) {
    console.log('userRefLevel error', error);
    return {
      status: false,
      message: "Error on server",
      result: []
    };
  }
};


export const getUserDataAPI = async (userAddress) => {
  try {

    let params = {
      url: `getUserData/${userAddress}`,
      method: "GET"
    }

    let response = await makeApiRequest(params);
    // console.log('response---', response);
    //  console.log("response",response);
    return {
      status: response.status,
      message: response.message,
      data: response.data
    }


  } catch (error) {
    console.log('userLogin error', error);
    return {
      status: false,
      message: "Error on server",
      result: []
    }
  }
}

export const getUserDataAPIUsingId = async (Id) => {
  try {

    let params = {
      url: `getUserData/${Id}`,
      method: "GET"
    }

    let response = await makeApiRequest(params);
    // console.log('response---', response);
    //  console.log("response",response);
    return {
      status: response.status,
      message: response.message,
      data: response.data
    }


  } catch (error) {
    console.log('userLogin error', error);
    return {
      status: false,
      message: "Error on server",
      result: []
    }
  }
}

export const getLevelIncomeUsers = async (userID) => {
  try {

    let payload = {
      userId: userID
    }

    let params = {
      url: `getLevelIncomeUsers`,
      method: "POST",
      data: payload
    }

    let response = await makeApiRequest(params);
    // console.log('response---', response);
    //  console.log("response",response);
    return {
      status: response.status,
      message: response.message,
      data: response.data
    }


  } catch (error) {
    console.log('userLogin error', error);
    return {
      status: false,
      message: "Error on server",
      result: []
    }
  }
}

export const getRecentUsers = async (num) => {
  try {
    console.log("num==", num);
    let params = {
      url: `recentUsers?pageNum=${num}`,
      method: "GET"
    }

    let response = await makeApiRequest(params);
    return {
      status: response.status,
      message: response.message,
      data: response.data
    }
  } catch (error) {
    console.log('getRecentUsers error', error);
    return {
      status: false,
      message: "Error on server",
      result: []
    }
  }
}

export const getTotalAmtAndAcc = async () => {
  try {

    let params = {
      url: `totalAccountsAndAmout`,
      method: "GET"
    }

    let response = await makeApiRequest(params);

    return {
      status: response.status,
      message: response.message,
      data: response.data
    }


  } catch (error) {
    console.log('getTotalAmtAndAcc error', error);
    return {
      status: false,
      message: "Error on server",
      result: []
    }
  }
}

export const updateUserAmount = async (bodyData) => {
  try {

    let params = {
      url: `updateUserAmount`,
      method: "POST",
      data: bodyData
    }

    let response = await makeApiRequest(params);

    return {
      status: response.status,
      message: response.message
    }


  } catch (error) {
    console.log('updateUserAmount error', error);
    return {
      status: false,
      message: "Error on server",
      result: []
    }
  }
}
export const addAllLevelUser = async (userData, usrlevel, amounts) => {
  try {

    let params = {
      url: `addAllLevelUser?usrlevel=${usrlevel}&amounts=${amounts}`,
      method: "POST",
      data: userData.data
    }
    let response =await makeApiRequest(params);
console.log("response=======",params);
return{
  data: response.status
}

  } catch (error) {
    console.log("user not save in db!")
    return {
      status: false,
      message: "Error on server",
      result: []
    }
  }
}
export const finduserAmount1 = async (userid) => {
  try {

    console.log(userid)
    let params = {
      url: `finduserAmount1?userid=${userid}`,
      method: "get",
    }
    const response = await makeApiRequest(params);
    console.log("params=============******",  response.data);
    return {
      data: response.data
    }

  } catch (error) {
    console.log("user not save in db!")
    return {
      status: false,
      message: "Error on server",
      result: []
    }
  }
}
