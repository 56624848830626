import "../assets/css/dashboard-style.css";

import Topnav from "../Dashboard-pages/Separate/Top-nav";
import Sidenav from "../Dashboard-pages/Separate/Side-nav";
import $ from "jquery";
import { useEffect, useState } from "react";
import { getLevelIncomeUsers } from "./api/authapis";
import { Navigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import levelplanABI from "../ABI/levelplan.json";
import Web3 from "web3";
import config from "../config/apiConfig";

$(function () {
  $(".genealogy-tree ul").hide();
  $(".genealogy-tree>ul").show();
  $(".genealogy-tree ul.active").show();
  $(".genealogy-tree li").on("click", function (e) {
    var children = $(this).find("> ul");
    if (children.is(":visible")) children.hide("fast").removeClass("active");
    else children.show("fast").addClass("active");
    e.stopPropagation();
  });
});

function Levelincome() {

  const USERID = localStorage.getItem("userID");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userid = queryParams.get('user');

  const [userRefData, setUserRefData] = useState([]);

  const getUserRefData = async (userID) => {
    let refUsers = await getLevelIncomeUsers(userID);
    if (refUsers.status) {
      setUserRefData(refUsers.data);
    }else{
      toast.error("User not found");
      Navigate(`/login`);
    }
  };

  const getrefdatacontract = async(userID) => {
   
    if(window.ethereum.chainId == config.chainId) {
      let web3 = new Web3(window.ethereum);
      let levelcontract = await new web3.eth.Contract(
        levelplanABI,
        config.levelplancontract
      );
      
      let lastid = await levelcontract.methods.lastUserId().call();
      var myArray = [];
      for(var i = userID; i < lastid;i++) {
        let usergetaddr = await levelcontract.methods.idToAddress(i).call();
        myArray.push(usergetaddr)
      }
      var lastarr = []
      if(myArray.length > 8) {
        for(var i = 0; i < 8;i++) {
          lastarr.push(myArray[i])
        }
      }
      else {
        for(var i = 0; i < myArray.length;i++) {
          lastarr.push(myArray[i])
        }
      }
      setUserRefData(lastarr);
    }
  }

  useEffect(() => {
    if(userid > 0){
      getrefdatacontract(userid);
    }else{
      getrefdatacontract(USERID);
    }
  }, [USERID,userid]);

  return (
    <div className="App">
      <Topnav />
      <ToastContainer />
      <section className="dashboard-split-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 dashboard-split-left-section d-lg-block d-none">
              <Sidenav />
            </div>
            <div className="col-lg-10 dashboard-split-right-section">
              <div className="row p-2 g-4">
                <div className="col-lg-12">
                <p class="mb-3 dashboard-text-2">Level Income</p>
                  <div className="level-income-new-list genealogy-body genealogy-scroll">
                    <ul>
                      {userRefData && userRefData != "0x0000000000000000000000000000000000000000" && 
                        userRefData.length >= 0 &&
                        userRefData.map((users) => {
                          return (
                            <>
                              <li>
                                <a href="javascript:void(0);">
                                  <div className="member-view-box">
                                    <div className="member-image">
                                      <div className="d-flex flex-row">
                                        <div>
                                          <p>Address:</p>
                                        </div>
                                        <div className="ms-auto text-end">
                                          <p className="">
                                            {users.substring(0, 5) +"..." +users.substring(users.length - 4) }
                                            <button className="bg-transparent border-0 ms-1">
                                              <i className="fa-solid fa-copy"></i>
                                            </button>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </li>
                            </>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Levelincome;
