import "../assets/css/dashboard-style.css"

import Topnav from "../Dashboard-pages/Separate/Top-nav";
import Sidenav from "../Dashboard-pages/Separate/Side-nav";

import decentralization from "../assets/images/images-dash/decentralization.png";
import regstration from "../assets/images/images-dash/registration.png";
import bnb from "../assets/images/images-dash/bnb.png";

function Metakinginstruction() {
    return (
        <div className="App">
            <Topnav />
            <section className="dashboard-split-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 dashboard-split-left-section d-lg-block d-none">
                            <Sidenav />
                        </div>
                        <div className="col-lg-10 dashboard-split-right-section">
                            <div className="row p-2 g-4 px-lg-5">
                                <div className="col-lg-12">
                                    <span className=" dashboard-text-2">
                                        Instruction
                                    </span>
                                    <button className="ms-3 dashboard-button-1">ID 1</button>
                                </div>
                            </div>

                             <div className="dashboard-instructions-section">
                            <div className="row banner-section1 py-lg-5 px-lg-5 flex-lg-row-reverse">
                                <div className="col-lg-4 col-12">
                                    <div className="stricky-notscroll">
                                        <button className="btn btn-primary d-lg-none" data-bs-toggle="collapse"
                                            href="#collapseExample" role="button" aria-expanded="false"
                                            aria-controls="collapseExample">
                                            connect <a className="text-end"><i
                                                className="fa-solid fa-circle-chevron-down collapse-iconalign"></i></a>
                                        </button>
                                        <div className="collapse" id="collapseExample">
                                            <div className="">
                                                <div id="simple-list-example"
                                                    className="d-flex flex-column gap-2 simple-list-example-scrollspy text-left">
                                                    <ul>
                                                        <li><a className="banner-section1-textcolor2 dashboard-text-4  "
                                                            href="#simple-list-item-1">
                                                            Metaking marketing</a></li>
                                                        <li><a className="banner-section1-textcolor2 dashboard-text-4 "
                                                            href="#simple-list-item-2">
                                                            Metaking programs
                                                        </a></li>
                                                        <li><a className="banner-section1-textcolor2 dashboard-text-4"
                                                            href="#simple-list-item-3">
                                                            How levels work
                                                        </a></li>
                                                        <li><a className="banner-section1-textcolor2 dashboard-text-4"
                                                            href="#simple-list-item-4">
                                                            How the automatic cycles work
                                                        </a></li>
                                                        <li><a className="banner-section1-textcolor2 dashboard-text-4"
                                                            href="#simple-list-item-5">
                                                            Upgrade
                                                        </a></li>
                                                        <li><a className="banner-section1-textcolor2 dashboard-text-4"
                                                            href="#simple-list-item-6">
                                                            Lost profit and extra profit
                                                        </a></li>
                                                        <li><a className="banner-section1-textcolor2 dashboard-text-4 "
                                                            href="#simple-list-item-7">
                                                            Spillovers and returns
                                                        </a></li>
                                                        <li><a className="banner-section1-textcolor2 dashboard-text-4"
                                                            href="#simple-list-item-8">
                                                            Registration
                                                        </a></li>
                                                        <li><a className="banner-section1-textcolor2 dashboard-text-4"
                                                            href="#simple-list-item-9">
                                                            Definition
                                                        </a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-lg-8 ">
                                    <div className="card banner-section1-card">
                                        <h5 className="card-title dashboard-text-9">Decentralization</h5>
                                        <div className="card-body">
                                            <img src={decentralization} className="card-img-top"
                                                alt="..." />
                                            <div className="dashboard-text-4 banner-section1-textcolor1">What is <b
                                                className="text-white">decentralization?</b> What are its benefits and
                                                advantages? Decentralized marketing is created on an automated contract that
                                                guarantees you maximum security and stability.</div>
                                            <div className="dashboard-text-4 banner-section1-textcolor1">A <b
                                                className="text-white">smart contract</b> is an algorithm with automatic
                                                execution. It exists inside the Smart Chain blockchain*, which is one of the
                                                top
                                                cryptocurrencies. Smart contracts, like cryptocurrencies themselves, are
                                                decentralized. They work strictly according to a certain program, and once
                                                they
                                                are launched, it is impossible to change their mode of operation. The code
                                                that
                                                determines the logic of a smart contract is written in a blockchain, and all
                                                calculations are performed by millions of computers around the world. This
                                                ensures that a smart contract cannot be hacked or stopped.</div>
                                            <div className="card banner-section1-card2 ">
                                                <div className="dashboard-text-4 banner-section1-textcolor1">
                                                    <b className="text-white">Blockchain</b> is an immutable register of
                                                    transactions and information, protected from any further manipulation by
                                                    cryptographic methods. It is simultaneously maintained by thousands of
                                                    independent computers around the world.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card banner-section1-card1 gap-3">
                                        <h5 className="card-title dashboard-text-9">What is a USDT token</h5>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><b
                                            className="text-white">USDT</b>
                                            is a cryptocurrency with a fixed exchange
                                            rate that is equal to $1. It has a fully verified code and is approved by
                                            the New York State Department of Financial Services.</div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1">5 benefits of USDT for
                                            Metaking:</div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><b className="text-white">1.
                                            Fixed
                                            Cost.</b> Your Metaking performance is no
                                            longer affected by rate fluctuations and market corrections.</div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><b className="text-white">2.
                                            All
                                            the opportunities that cryptocurrency
                                            provides. </b> USDT token can be exchanged for any cryptocurrency on DEX
                                            exchangers with negligible fees in a couple of clicks.</div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><b className="text-white">3.
                                            Simple
                                            calculations.</b> Registration fees,
                                            upgrades, and cycles are now specified in US Dollar equivalents.</div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><b className="text-white">4.
                                            Precise planning.</b> You can accurately track
                                            costs and estimate results without wasting time converting from one unit to
                                            another.</div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><b className="text-white">5.
                                            Protection of participants' interests.</b> USDT is
                                            fully backed by the US Dollar at a ratio of 1:1 and can always be exchanged
                                            for Dollars. It was created in a Paxos and Binance partnership.</div>
                                    </div>

                                    <div className="card banner-section1-card1 gap-3 " id="simple-list-item-1">
                                        <h5 className="card-title dashboard-text-9">
                                        Metaking marketing</h5>
                                        {/* <iframe src="https://www.youtube.com/embed/YAYAKIp5JeE" title="YouTube video player"
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            className="iframe-video" allowfullscreen></iframe> */}

                                        <div className="dashboard-text-4 banner-section1-textcolor1"><b
                                            className="text-white">Metaking
                                            marketing</b> is implemented on the Smart
                                            Chain blockchain smart contract technology. The marketing employs the
                                            USDT token in the BEP-20 format with a stable exchange rate of 1 USD. To
                                            send USDT or any other token functioning in the Smart Chain blockchain,
                                            you will need a certain amount of BNB to pay the fee.</div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><img
                                            src={bnb} className="bnb-logo" /><b
                                                className="text-white">BNB</b> -
                                            This is the internal BEP-20 format coin
                                            of the Smart Chain blockchain, which is required to pay the transaction
                                            fee.</div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><img
                                            src={bnb} className="bnb-logo" /><b
                                                className="text-white">USDT</b>
                                            - This is the BEP-20 format Smart Chain
                                            blockchain coin with a stable rate of 1 USD.</div>
                                    </div>
                                    <div className="card banner-section1-card1 gap-3 " data-target="simple-sticky1">
                                        <h5 className=" dashboard-text-9">
                                        Metaking marketing</h5>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><b
                                            className="text-white">Metaking
                                            marketing</b> is a matrix system based on
                                            decentralized smart contract technology. Profits come from inviting new
                                            partners to your team, who once registered stay with you forever since
                                            it is recorded in blockchain. The income is distributed automatically
                                            and instantly to the personal wallets of the participants.Participants
                                            have access to
                                            <b className="text-white"> 4 marketing programs,</b> different in terms of
                                            profitability and conditions of interaction between partners. The most
                                            efficient method is to use all of these programs in parallel.
                                        </div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><img
                                            src={bnb} className="bnb-logo" /><b className="text-white">The
                                                validity of the levels is not limited. </b>By
                                            activating any level of the program once, it is assigned to you forever
                                            and does not require any additional rewards.</div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><img
                                            src={bnb} className="bnb-logo" /><b
                                                className="text-white">THigher
                                                levels = more income. </b>Marketing
                                            income is generated from the value of the level in which your partner
                                            took a spot. Therefore, working at higher levels increases your results.
                                            Marketing is built in such a way that income from one cycle is enough to
                                            activate the next level.</div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><img
                                            src={bnb} className="bnb-logo" /><b
                                                className="text-white">TAutomatic cycles at all levels. </b>The levels
                                            of each program contain a fixed number of spots. As soon as all the
                                            spots in the level are filled, a new cycle automatically begins.</div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><img
                                            src={bnb} className="bnb-logo" /><b className="text-white">The
                                                number of cycles is not limited. </b>A level
                                            activated once allows you to invite an unlimited number of new partners.
                                        </div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><img
                                            src={bnb} className="bnb-logo" /><b
                                                className="text-white">Referral
                                                link. </b>All personal partners whom you
                                            invite are assigned to you forever, and this cannot be changed, since it
                                            is recorded in a smart contract.</div>
                                    </div>

                                    <div className="card banner-section1-card1 gap-3" id="simple-list-item-2">
                                        <h5 className=" dashboard-text-9">
                                        Metaking programs</h5>
                                        <div className="card-body">
                                            <div id="carouselExampleIndicators" className="carousel slide">
                                                <div className="carousel-indicators">
                                                    <button type="button" data-bs-target="#carouselExampleIndicators"
                                                        data-bs-slide-to="0" className="active" aria-current="true"
                                                        aria-label="Slide 1"></button>
                                                    <button type="button" data-bs-target="#carouselExampleIndicators"
                                                        data-bs-slide-to="1" aria-label="Slide 2"></button>
                                                    <button type="button" data-bs-target="#carouselExampleIndicators"
                                                        data-bs-slide-to="2" aria-label="Slide 3"></button>
                                                </div>
                                                <div className="carousel-inner">
                                                    <div className="carousel-item active">
                                                        <img src={decentralization}
                                                            className="d-block w-100" alt="..." />
                                                    </div>
                                                    <div className="carousel-item">
                                                        <img src={regstration} className="d-block w-100"
                                                            alt="..." />
                                                    </div>
                                                    <div className="carousel-item">
                                                        <img src={decentralization}
                                                            className="d-block w-100" alt="..." />
                                                    </div>
                                                </div>
                                                <button className="carousel-control-prev" type="button"
                                                    data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Previous</span>
                                                </button>
                                                <button className="carousel-control-next" type="button"
                                                    data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Next</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card banner-section1-card1 gap-3 " id="simple-list-item-8">
                                        <h5 className="card-title dashboard-text-9">
                                            Registration</h5>
                                        <img src={decentralization} className="card-img-top" alt="..." />
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><b className="text-white">Smart
                                            Contract. </b>Metaking platform is based on
                                            smart contract technology. Metaking smart contracts are programmed in
                                            such a way, that they never store participants' funds, their balance is
                                            always zero. The purpose of the smart contract is to automatically
                                            redirect funds from incoming transactions to the wallets of other
                                            participants, according to marketing program rules.</div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><b
                                            className="text-white">Referral
                                            linking. </b>Also, your referral linkage
                                            remains unchanged, you can't change your upline partner, as well as your
                                            downline partners are assigned to you in your structure forever.</div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><b
                                            className="text-white">Personal
                                            Wallet</b>To become a member, you need
                                            to create your personal wallet.</div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1">Only you have
                                            access to the funds. All rewards according to marketing are instantly
                                            credited to your personal wallet. All transactions are stored in the
                                            public domain in a blockchain. You can easily check each transaction and
                                            see where the funds have been transferred.</div>

                                    </div>

                                    <div className="card banner-section1-card1 gap-3 ">
                                        <h5 className="card-title dashboard-text-9">
                                            Registration</h5>
                                        <img src={decentralization} className="card-img-top" alt="..." />
                                        <div className="dashboard-text-4 banner-section1-textcolor1">
                                            <b className="text-white">Registration in Metaking USDT</b> is the activation
                                            of first levels in Metaking x3 and x4 programs, which cost 5 USDT each.
                                            In total, registration costs 10 USDT. The first levels in x3 and x4
                                            programs are always activated together and cannot be accessed
                                            separately. All the following levels can be purchased one by one, in
                                            ascending order.
                                        </div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1">Registration
                                            transaction is credited to the smart contract. The smart contract
                                            records your wallet number into the structure and redirects the funds to
                                            the personal wallet of the person that invited you (your upline
                                            partner). You occupy a free spot in their first level of x3 program and
                                            the first level of x4 program. Level 1 of x3 and Level 1 of x4 are
                                            respectively opened for you, and now you can invite partners through
                                            your personal referral link.</div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"> After activation
                                            of the first levels of x3 and x4 programs, the xXx and xGold program
                                            activation becomes available. After you activate it, the activation of
                                            xGold program becomes available.</div>
                                    </div>

                                    <div className="card banner-section1-card1 gap-3 ">
                                        <h5 className="card-title dashboard-text-9">
                                            How Metaking x3 works</h5>
                                        <img src={regstration} className="card-img-top" alt="..." />
                                        <div className="dashboard-text-4 banner-section1-textcolor1">All partners in
                                            your <b>Metaking X3</b>program levels are the people whom you’ve
                                            personally invited. When partners register in the program using your
                                            referral link, they take spots below you.
                                        </div>
                                        <div>
                                            <div className="dashboard-text-4 banner-section1-textcolor1">Distribution of
                                                rewards when filling each spot in a level of the program is as
                                                follows:</div>
                                            <div className="d-flex gap-3 dashboard-text-4 banner-section1-textcolor1"><b
                                                className="introduction-activity-1 ">1</b>The first partner you
                                                invite is placed on the first spot below you. Reward of 100% goes to
                                                your personal wallet.
                                            </div>
                                            <div className="dashboard-text-4"><i className="fa-solid fa-wallet"></i>
                                                100%</div>
                                        </div>
                                        <div>
                                            <div className="d-flex gap-3 dashboard-text-4 banner-section1-textcolor1"><b
                                                className="introduction-activity-1 ">2</b>The second partner is
                                                placed on the second spot below you. You also get 100% reward to
                                                your personal wallet.
                                            </div>
                                            <div className="dashboard-text-4"><i className="fa-solid fa-wallet"></i>
                                                100%</div>
                                        </div>

                                        <div className="d-flex gap-3 dashboard-text-4 banner-section1-textcolor1"><b
                                            className="introduction-activity-2 ">3</b>The third partner is placed on
                                            the third spot below you and completes the cycle. 100% of the income
                                            goes to your upline 100% partner's wallet, and a new cycle immediately
                                            begins for you, and you can fill up the spots again by inviting new
                                            partners.
                                        </div>
                                        <div className="card banner-section1-card2 ">
                                            <div className="dashboard-text-4 banner-section1-textcolor1">
                                                <b className="text-white">Likewise with your partners.</b> Each time
                                                your partner completes the cycle of his level, you receive a payment
                                                to the wallet in 100% of the level cost, and the partner opens the
                                                new cycle again. At the same time, this partner takes a new place
                                                under you. Thus, the same partner can occupy several places in a row
                                                in your levels.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card banner-section1-card1 gap-3 ">
                                        <h5 className="card-title dashboard-text-9">
                                            How Metaking x4 works</h5>
                                        <img src={regstration} className="card-img-top" alt="..." />
                                        <div className="dashboard-text-4 banner-section1-textcolor1">In<b
                                            className="text-white">Metaking x4</b> program you can invite personal partners,
                                            as well as
                                            receive spillovers from above and below.</div>
                                        <div>
                                            <div className="d-flex gap-3 dashboard-text-4 banner-section1-textcolor1">
                                                <div className="d-flex gap-1"><b className="introduction-activity-3 ">1</b><b
                                                    className="introduction-activity-3 ">2</b></div>The partners who
                                                occupy two spots below you in the first line are also in the second
                                                line of your upline's level. Reward of 100% goes to upline's wallet.
                                                By the same principle, you receive income 100% not from the first,
                                                but from the second line.
                                            </div>
                                            <div className="dashboard-text-4"><i className="fa-solid fa-wallet"></i>
                                                100%</div>
                                        </div>

                                        <div>
                                            <div className="d-flex  dashboard-text-4 banner-section1-textcolor1"><b
                                                className="introduction-activity-4 ">1</b><b
                                                    className="introduction-activity-4 ">2</b><b
                                                        className="introduction-activity-4 ">3</b>Rewards from partners who
                                                occupy these spots instantly go to your wallet in the amount of
                                                100%.
                                            </div>
                                            <div className="dashboard-text-4"><i className="fa-solid fa-wallet"></i>
                                                100%</div>
                                        </div>

                                        <div>
                                            <div className="d-flex gap-3 dashboard-text-4 banner-section1-textcolor1"><b
                                                className="introduction-activity-5 ">6</b>TThe partner completes the
                                                cycle of the level, the reward of 100% 100% goes to your upline, and
                                                the new cycle begins for you.
                                            </div>
                                        </div>
                                        <div className="card banner-section1-card2 ">
                                            <div className="dashboard-text-4 banner-section1-textcolor1">When a partner
                                                on the 2nd line joins your upline, they take a place in your 1st
                                                line — that is, you receive a
                                                <b className="text-white">spillover from above.</b> Similarly,<b
                                                    className="text-white">
                                                    a spillover can come from below.
                                                </b> When a partner comes to your downline on the 1st line, then
                                                they will take a place on your 2nd line. Thus, places in the x4
                                                levels can be occupied by people invited only by you, or there can
                                                be none invited by you, or mixed.
                                            </div>
                                        </div>
                                        <div>
                                            <div className=" dashboard-text-4 banner-section1-textcolor1 gap-2"><img
                                                src={bnb} className="bnb-logo" />Do not forget
                                                to activate the next level after the 1st cycle so as not to miss
                                                payments on the previous level.</div>
                                        </div>
                                    </div>

                                    <div className="card banner-section1-card1 gap-3 ">
                                        <h5 className="card-title dashboard-text-9">
                                            How Metaking xXx works</h5>
                                        <img src={bnb} className="card-img-top" alt="..." />
                                        <div><img /></div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><b className="text-white">First
                                            line (2
                                            places): </b>rewards go to your upline partners.</div>
                                        <div><img /></div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><b
                                            className="text-white">Second
                                            line (4 places):</b>you get<b className="text-white"> 30% </b>of
                                            the level cost from each of the four partners, and 70% goes to the
                                            upline, since for him they are partners of the 4th line.</div>
                                        <div><img /></div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><b className="text-white">Third
                                            line (8 places): </b> you get <b className="text-white">70% </b> from
                                            each, 30% goes to the upline.</div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><img /> <b
                                            className="text-white">xXx cycle: </b> The final reward from the 2nd
                                            line (30%) and the final reward from the 3rd line (70%) are summed up
                                            and distributed to the upline partners, and a new cycle opens for you.
                                        </div>
                                    </div>

                                    <div className="card banner-section1-card1 gap-3 ">
                                        <h5 className="card-title dashboard-text-9">
                                            How Metaking xGold works</h5>
                                        <img src={regstration} className="card-img-top" alt="..." />
                                        <div className="dashboard-text-4 banner-section1-textcolor1">The advantage of <b
                                            className="text-white"> xGold program</b>is a larger number of partners
                                            and spillover opportunities, which generates an income of 1020% from one
                                            cycle of the level.</div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"> <b
                                            className="text-white">First
                                            line (2 places):</b> rewards go to your <b className="text-white">upline</b>
                                            partner.</div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><b
                                            className="text-white">Second
                                            line (4 places): </b> you get <b className="text-white">20%</b> from each spot
                                        </div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><b className="text-white">Third
                                            line (8
                                            places):</b> you get <b className="text-white">30%</b> from each spot
                                        </div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><b
                                            className="text-white">Fourth
                                            line (16 places):</b> you get <b className="text-white">50%</b> from each
                                            participant (30% and 20% are
                                            distributed to upline partners).</div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1 d-flex"><img /><b
                                            className="text-white">
                                            xGoldcycle:
                                        </b>The last 2 spots from the 4th line (50% + 50%) are summed up and
                                            100% is distributed to upline partners, and a new cycle of the level
                                            begins automatically.</div>
                                    </div>

                                    <div className="card banner-section1-card1 gap-3 " id="simple-list-item-3">
                                        <h5 className="card-title dashboard-text-9">
                                            How levels work</h5>
                                        <div className="dashboard-text-4 banner-section1-textcolor1 d-flex">All programs
                                            have levels that work on the same principle but differ in the cost of
                                            activation. Completing a full cycle of the level brings enough profit to
                                            activate the next level, and gives you the opportunity to earn 2 times more.
                                            <img />
                                        </div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1">Activating several
                                            levels and inviting partners to those levels at once speeds up your profit
                                            many times. The levels do not have a validity period, so you should not be
                                            afraid that they will expire. Each active level constantly brings you
                                            income. How many levels can be activated at once? As many as you want, even
                                            all at once.</div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1">One should bear in mind
                                            that you can open levels only in sequential order — for example, you can’t
                                            go to the fourth level without opening the third one.
                                        </div>
                                        <div className="card banner-section1-card2 ">
                                            <div className="dashboard-text-4 banner-section1-textcolor1">
                                                <b className="text-white">Be careful, </b> W if you have not yet activated a
                                                certain level, and your partner activates it earlier, then overtaking
                                                will occur, and you will miss the reward.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card banner-section1-card1 gap-3 " id="simple-list-item-4">
                                        <h5 className="card-title dashboard-text-9">
                                            How to automatic cycles work</h5>
                                        <div className="dashboard-text-4 banner-section1-textcolor1 d-flex">Automatic cycles
                                            open the same level for you, and you continue to receive income from it.
                                            This happens automatically as soon as the last free spot under you is taken
                                            by a partner. The current level completes its cycle, and the next cycle
                                            begins. <img /></div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1">When a cycle is
                                            completed, you occupy a free spot in the level of your upline partner. And
                                            you have your level reopened with free spots for new partners. For each
                                            cycle, a reward of 100% goes to your upline partner's wallet. Cycles work in
                                            the same way for your invited partners, and each time their level completes
                                            a cycle, you receive a reward for it.
                                        </div>
                                        <div className="card banner-section1-card2 ">
                                            <div className="dashboard-text-4 banner-section1-textcolor1">
                                                After the first cycle of a level, you will receive rewards for the new
                                                partners at the same level only if the next level is activated in the
                                                program. Therefore, it is important to make upgrades in advance so as
                                                not to miss out on rewards.
                                            </div>
                                        </div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1 d-flex"><img />
                                            <div><b className="dashboard-text-9 text-white">How cycles in xXx work.</b>For
                                                automatic activation of the level after a cycle in xXx program, you need
                                                100% value of the level. The first amount of 30% from last partner on
                                                the 2nd line is temporarily blocked on a smart contract, and as soon as
                                                the second amount of 70% from last partner on the 3rd line arrives, the
                                                cycle is automatically closed, and 100% for it instantly goes higher in
                                                the structure.</div>
                                        </div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1 d-flex"><img />
                                            <div><b className="dashboard-text-9 text-white">How cycles in xGold work.</b>For
                                                automatic activation of the level after a cycle in xGold program, you
                                                need 100% cost of its activation. Rewards from the last two partners on
                                                the 4th line are summed up (50% + 50%) for this activation.</div>
                                        </div>
                                    </div>

                                    <div className="card banner-section1-card1 gap-3 " id="simple-list-item-5">
                                        <p id="simple-list-item-5">
                                            <h5 className="card-title dashboard-text-9">
                                                Upgrade</h5>
                                            <div className="dashboard-text-4 banner-section1-textcolor1">UPGRADE is the
                                                activation of the next more expensive level. Income from 1 cycle of each
                                                level is enough to upgrade to the next level. The reward for the upgrade
                                                goes to your upline partner, provided that they already have this level
                                                activated. You get to decide whether to activate the next level or not. But
                                                if you do not have the next level open, then the rewards from second cycle
                                                of this level will be redirected to the upline partners.</div>
                                            <div> <img /> <img /> <img /> </div>
                                            <div className="dashboard-text-4 banner-section1-textcolor1">If you miss a reward
                                                from new partners due to lack of upgrade, then referral linking allows you
                                                to get your partners back. When you upgrade to the next level, your referral
                                                partner will take a place below you in their next cycle, and will follow you
                                                with each next cycle.
                                            </div>
                                            <div className="card banner-section1-card2 ">
                                                <div className="dashboard-text-4 banner-section1-textcolor1">
                                                    You can overtake your upline partner by activating levels they have not
                                                    yet activated. In such a case, you will occupy a spot in their closest
                                                    upline partner’s level, provided that this partner has such a level
                                                    activated. The reward goes to him instead of your direct upline.
                                                </div>
                                            </div>
                                        </p>
                                    </div>

                                    <div className="card banner-section1-card1 gap-3 ">
                                        <h5 className="card-title dashboard-text-9">
                                            How Metaking xGold works</h5>
                                        <img src={regstration} className="card-img-top" alt="..." />
                                        <div className="dashboard-text-4 banner-section1-textcolor1">Level freeze occurs if
                                            the partners have taken all the spots in the level completing the first
                                            cycle, but the next level is not activated (lack of upgrade).</div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1">When “frozen”, the
                                            level remains inactive. Partners continue to occupy spots in your level but
                                            you miss out on profits from this level until the next level is activated.
                                        </div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1">Exceptions include the
                                            last levels in Metaking programs (12th or 15th in xGold ).</div>
                                    </div>

                                    <div className="card banner-section1-card1 gap-3 " id="simple-list-item-6">
                                        <h5 className="card-title dashboard-text-9">
                                            Lost profit and extra profit</h5>
                                        <div className="dashboard-text-4 banner-section1-textcolor1">
                                            <div>Lost profits are cases when you miss rewards for partners, and they are
                                            </div>
                                            <div>redirected to an upline partner.</div>
                                            <div>Lost profits can occur for two reasons:
                                            </div>
                                        </div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><img
                                            src={bnb} className="bnb-logo" /><b
                                                className="text-white">Overtaking: </b> - This is the internal BEP-20 format
                                            coin of the Smart Chain blockchain, which is required to pay the transaction
                                            fee.</div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><img
                                            src={bnb} className="bnb-logo" /><b
                                                className="text-white">Freezing:
                                            </b>Your level is completely filled, and the 1st cycle has been completed,
                                            but the next, more expensive level is still not activated.</div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1">After you activate the
                                            level, your partner will come back to you again in his next cycle.</div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><img
                                            src={bnb} className="bnb-logo" /><b className="text-white">Extra
                                                profit </b> is a reward that comes to you from
                                            below, because one or more of the downline partners have lost their profits.
                                            Thus, you can gain extra profits from the depth of your structure when your
                                            downline is overtaken, and you are the first in your structure who has this
                                            level activated. Therefore, activation of higher levels allows you to
                                            receive such rewards for overtaking from downline partners.</div>

                                    </div>

                                    <div className="card banner-section1-card1 gap-3 ">
                                        <h5 className="card-title dashboard-text-9">
                                            Spillovers</h5>
                                        <div className="dashboard-text-4 banner-section1-textcolor1 d-flex">
                                            <div>Spillovers is a process when spots in your levels are occupied not only
                                                by your personally invited partners, but also by partners invited by the
                                                other members of your team. Metaking marketing programs allow you to
                                                receive spillovers from both your upline and downline partners in x4,
                                                xXx and xGold.</div><img />
                                        </div>
                                        <div>
                                            <div className="d-flex gap-3 dashboard-text-4 banner-section1-textcolor1"><b
                                                className="introduction-activity-6 "></b>
                                                <div><b className="text-white">Personal partner </b>is a person who
                                                    registered with your personal affiliate link. They take the closest
                                                    free spot in the level immediately after your position.</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="d-flex gap-3 dashboard-text-4 banner-section1-textcolor1"><b
                                                className="introduction-activity-7 "></b>
                                                <div><b className="text-white">Spillover from above </b> is a personally
                                                    invited partner of your upline partner. They take the next free spot
                                                    in the level of your partner, and accordingly get in your level,
                                                    too, but on the higher line.</div>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="d-flex gap-3 dashboard-text-4 banner-section1-textcolor1"><b
                                                className="introduction-activity-8 "></b>
                                                <div><b className="text-white">Spillover from below</b> is a personally
                                                    invited partner of your downline.</div>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="d-flex gap-3 dashboard-text-4 banner-section1-textcolor1"><b
                                                className="introduction-activity-9"></b>
                                                <div><b className="text-white">Overtake</b> is a situation when a partner
                                                    overtakes their upline, and temporarily takes a spot directly below
                                                    you. If your downline partner has this level activated, in the next
                                                    cycle this partner will take a place under their direct upline
                                                    partner.</div>
                                            </div>
                                        </div>
                                        <div className="gap-3 dashboard-text-4 banner-section1-textcolor1">You can overtake
                                            your upline partner, acquiring levels which they have not yet activated. In
                                            this case, your reward goes to their closest upline partner, who has such a
                                            level activated, and the income goes to them. Referral linking allows you to
                                            return everyone to their places. This means that when your upline activates
                                            the required level, in the next cycle you will take the spot under them
                                            again. Thus, the consolidation of personally invited partners is preserved
                                            forever.</div>

                                    </div>

                                    <div className="card banner-section1-card1 gap-3">
                                        <h5 className=" dashboard-text-9">
                                            Profit</h5>
                                        <div className="card-body">
                                            <div id="carouselExampleIndicator" className="carousel slide">
                                                <div className="carousel-indicators">
                                                    <button type="button" data-bs-target="#carouselExampleIndicators"
                                                        data-bs-slide-to="0" className="active" aria-current="true"
                                                        aria-label="Slide 1"></button>
                                                    <button type="button" data-bs-target="#carouselExampleIndicators"
                                                        data-bs-slide-to="1" aria-label="Slide 2"></button>
                                                    <button type="button" data-bs-target="#carouselExampleIndicators"
                                                        data-bs-slide-to="2" aria-label="Slide 3"></button>
                                                </div>
                                                <div className="carousel-inner">
                                                    <div className="carousel-item active">
                                                        <img src={decentralization}
                                                            className="d-block w-100" alt="..." />
                                                    </div>
                                                    <div className="carousel-item">
                                                        <img src={regstration} className="d-block w-100"
                                                            alt="..." />
                                                    </div>
                                                    <div className="carousel-item">
                                                        <img src={decentralization}
                                                            className="d-block w-100" alt="..." />
                                                    </div>
                                                </div>
                                                <button className="carousel-control-prev" type="button"
                                                    data-bs-target="#carouselExampleIndicator" data-bs-slide="prev">
                                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Previous</span>
                                                </button>
                                                <button className="carousel-control-next" type="button"
                                                    data-bs-target="#carouselExampleIndicator" data-bs-slide="next">
                                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Next</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card banner-section1-card1 gap-3 " id="simple-list-item-7">
                                        <p id="simple-list-item-7">
                                            <h5 className="card-title dashboard-text-9">
                                                How many levels should be activated at the start</h5>
                                            <img src={regstration} className="card-img-top" alt="..." />
                                            <div className="dashboard-text-4 banner-section1-textcolor1">Of course, you can
                                                start with 1 level. But it is recommended to activate at least the first 3
                                                levels right at the start. This will allow you to get good results from the
                                                very beginning. Such strategy can maximize your results.</div>
                                            <div className="dashboard-text-4 banner-section1-textcolor1">Many successful
                                                participants use this strategy of activating several levels from the
                                                beginning. They set an example for those below them, and so do they follow.
                                                As a result, profits of the whole structure grow faster. Many start with 5
                                                levels at once, while some start with 7 or even more levels.</div>
                                        </p>
                                    </div>

                                    <div className="card banner-section1-card1 gap-3 " id="simple-list-item-9">
                                        <h5 className="card-title dashboard-text-9">
                                            Definitions</h5>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><i
                                            className="fa-regular fa-circle-up text-white"></i><b className="text-white">
                                                UPLINE PARTNER </b> - a partner under whose referral link you
                                            registered.</div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><i
                                            className="fa-solid fa-user-large text-white"></i><b className="text-white">
                                                DOWNLINE PARTNER
                                            </b>Y - a partner who registered using your referral link.</div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><i
                                            className="fa-solid fa-arrow-up text-success"></i><b className="text-white">
                                                UPGRADE </b> - activation of the next more expensive level.</div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><i
                                            className="fa-solid fa-arrows-spin text-success"></i><b className="text-white">
                                                CYCLE </b> - filling all places at the level and automatic reopening of
                                            the current level.</div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><i
                                            className="fa-solid fa-circle-exclamation text-danger"></i><b
                                                className="text-white">
                                                LOST PROFITS </b> - a payment that was redirected to
                                            an upline partner due to overtake or lack of upgrade.</div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><i
                                            className="fa-solid fa-gift text-info"></i><b className="text-white"> EXTRA
                                                PROFITS </b> - the payment that comes to you due to the lost profit of
                                            the downline partner.</div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><i
                                            className="fa-solid fa-rocket text-warning"></i><b className="text-white">
                                                SPILLOVER FROM ABOVE </b> - the partner of your upline partner who took
                                            a place in your level.</div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1"><i
                                            className="fa-solid fa-rocket text-warning"></i><b className="text-white">
                                                SPILLOVER FROM BELOW </b> - the partner of your downline partner who
                                            took a place in your level.</div>
                                        <div className="dashboard-text-4 banner-section1-textcolor1 "><i
                                            className="fa-regular fa-circle-up text-danger"></i><b className="text-white">
                                                OVERTAKE </b> - activation by the downline partner of the more expensive
                                            level you don't have yet.</div>
                                    </div>

                                </div>



                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Metakinginstruction;