import { FaGripLines } from 'react-icons/fa';
import { BsFillGrid1X2Fill } from 'react-icons/bs';
import { AiOutlineClose } from 'react-icons/ai';
import Metalogo from "../../assets/images/images-dash/meta-king-logo.png"

import $ from 'jquery'

var jq = $.noConflict();
jq(document).ready(function () {
    if (window.location.pathname === "/dashboard") {
        jq(".sidenavbar-active-dashboard").addClass("sidenavbar-active");
        jq(".sidenavbar-active-partners").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-links").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-stats").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-instructions").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-calculator").removeClass("sidenavbar-active");
    }
    if (window.location.pathname === "/partners") {
        jq(".sidenavbar-active-dashboard").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-partners").addClass("sidenavbar-active");
        jq(".sidenavbar-active-links").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-stats").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-instructions").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-calculator").removeClass("sidenavbar-active");
    }
    if (window.location.pathname === "/links") {
        jq(".sidenavbar-active-dashboard").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-partners").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-links").addClass("sidenavbar-active");
        jq(".sidenavbar-active-stats").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-instructions").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-calculator").removeClass("sidenavbar-active");
    }
    if (window.location.pathname === "/stats") {
        jq(".sidenavbar-active-dashboard").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-partners").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-links").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-stats").addClass("sidenavbar-active");
        jq(".sidenavbar-active-instructions").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-calculator").removeClass("sidenavbar-active");
    }
    if (window.location.pathname === "/metakinginstructions") {
        jq(".sidenavbar-active-dashboard").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-partners").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-links").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-stats").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-instructions").addClass("sidenavbar-active");
        jq(".sidenavbar-active-calculator").removeClass("sidenavbar-active");
    }
    if (window.location.pathname === "/metakingcalculator") {
        jq(".sidenavbar-active-dashboard").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-partners").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-links").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-stats").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-instructions").removeClass("sidenavbar-active");
        jq(".sidenavbar-active-calculator").addClass("sidenavbar-active");
    }


});


function Sidenav({userid}) {
    return (
        <div >
            <ul className="navbar-nav">
                <li className="sidenavbar-nav-link sidenavbar-active sidenavbar-active-dashboard">
                    <a className="" href="/dashboard">
                        <div className="d-flex flex-row align-items-center">
                            <div className="sidenav-icon-1">
                                <BsFillGrid1X2Fill />
                            </div>
                            <div>
                                <span className="sidenav-text-1">Dashboard</span>
                            </div>
                        </div>
                    </a>
                </li>

                <li className="sidenavbar-nav-link-2 ">
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button accordion-button-2" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                    aria-expanded="false" aria-controls="collapseOne">
                                    <div className="d-flex flex-row align-items-center">
                                        <div className="sidenav-icon-1">
                                            <i className="fa-sharp fa-solid fa-people-group"></i>
                                        </div>
                                        <div>
                                            <span className="sidenav-text-1">Team</span>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show"
                                aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <ul className="navbar-nav">
                                        {/* <li className="sidenavbar-nav-link-2 sidenavbar-active-partners">
                                            <a className="" href="/partners">
                                                <div className="d-flex flex-row align-items-center">
                                                    <div className="sidenav-icon-1">
                                                        <i className="fa-solid fa-user-group"></i>
                                                    </div>
                                                    <div>
                                                        <span className="sidenav-text-1">Partners</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </li> */}
                                        <li className="sidenavbar-nav-link-2 sidenavbar-active-links">
                                            <a className=""  href={`/links?userid=${userid}`}>
                                                <div className="d-flex flex-row align-items-center">
                                                    <div className="sidenav-icon-1">
                                                        <i className="fa-solid fa-link"></i>
                                                    </div>
                                                    <div>
                                                        <span className="sidenav-text-1">Links</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="sidenavbar-nav-link-2 sidenavbar-active-stats">
                                            <a className="" href="/stats">
                                                <div className="d-flex flex-row align-items-center">
                                                    <div className="sidenav-icon-1">
                                                        <i className="fa-brands fa-nfc-directional"></i>
                                                    </div>
                                                    <div>
                                                        <span className="sidenav-text-1">Stats</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li className="sidenavbar-nav-link-2 ">
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item bg-transparent">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button collapsed accordion-button-2" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                    aria-expanded="false" aria-controls="collapseTwo">
                                    <div className="d-flex flex-row align-items-center">
                                        <div className="sidenav-icon-1">
                                            <i className="fa-solid fa-book-open"></i>
                                        </div>
                                        <div>
                                            <span className="sidenav-text-1">Information</span>
                                        </div>
                                    </div>
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse show"
                                aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div className="accordion-body">

                                    <ul className="navbar-nav">
                                        <li className="sidenavbar-nav-link-2 sidenavbar-active-instructions">
                                            <a className="" href="/metakinginstructions">
                                                <div className="d-flex flex-row align-items-center">
                                                    <div className="sidenav-icon-1">
                                                        <i className="fa-solid fa-shield-halved"></i>
                                                    </div>
                                                    <div>
                                                        <span className="sidenav-text-1">Instruction</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                        {/* <li className="sidenavbar-nav-link-2 sidenavbar-active-calculator">
                                            <a className="" href="/metakingcalculator">
                                                <div className="d-flex flex-row align-items-center">
                                                    <div className="sidenav-icon-1">
                                                        <i className="fa-solid fa-calculator"></i>
                                                    </div>
                                                    <div>
                                                        <span className="sidenav-text-1">Calculator</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    );
}

export default Sidenav;